import React, { Component } from 'react';


import refreshWhite from  "./../../img/white/refresh_white_24dp.svg";
import firstWhite from  "./../../img/white/first_page_white_24dp.svg";
import beforeWhite from  "./../../img/white/navigate_before_white_24dp.svg";
import nextWhite from  "./../../img/white/navigate_next_white_24dp.svg";
import lastWhite from  "./../../img/white/last_page_white_24dp.svg";

import refreshBlack from  "./../../img/black/refresh_black_24dp.svg";
import firstBlack from  "./../../img/black/first_page_black_24dp.svg";
import beforeBlack from  "./../../img/black/navigate_before_black_24dp.svg";
import nextBlack from  "./../../img/black/navigate_next_black_24dp.svg";
import lastBlack from  "./../../img/black/last_page_black_24dp.svg";

class PagingToolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    jumpToFirstPage = function () {
        this.props.paging.currentPage = 0;
        this.props.tableReload(0);
    }

    jumpToPreviousPage = function () {
        if (this.props.paging.currentPage > 0) {
            this.props.paging.currentPage = this.props.paging.currentPage - 1;
            var skip = this.props.paging.currentPage * this.props.paging.pageRowCount;
            this.props.tableReload(skip);
        }
    }

    jumpToNextPage = function () {
        if (this.props.paging.totalPageCount > this.props.paging.currentPage) {
            this.props.paging.currentPage = this.props.paging.currentPage + 1;
            var skip = this.props.paging.currentPage * this.props.paging.pageRowCount;
            this.props.tableReload(skip);
        }
    }

    jumpToLastPage = function () {
        this.props.paging.currentPage = this.props.paging.totalPageCount;
        this.props.tableReload(this.props.paging.totalPageCount * this.props.paging.pageRowCount);
    }

    jumpToPosition = function (e) {
        var pageCount = (e.target.value - 1 < this.props.paging.totalPageCount) ? e.target.value - 1 : this.props.paging.totalPageCount;
        this.props.paging.currentPage = (pageCount > this.props.paging.totalPageCount) ? this.props.paging.totalPageCount : pageCount;
        this.props.tableReload(pageCount * this.props.paging.pageRowCount);
    }

    setPageSize(e) {
        this.props.paging.currentPage = 0;
        this.props.paging.pageRowCount = e.target.value.replace(/[^\d]/, '');
        this.props.tableReload();
    }

    reload = function () {
        this.props.tableReload(this.props.paging.skip);
    }

    toNumber = function (number) {
        if (typeof number == 'undefined') {
            return 0;
        }
        if (number == null) {
            return 0;
        }
        return number * 1;

    }

    render() {

        var pagingToolbarDark = {
            display: 'flex',
            backgroundColor: '#313943',
            minHeight: '60px',
            alignItems: 'center',
            color: "#FFFFFF44",

        }
        var pagingToolbarLight = {
            display: 'flex',
            backgroundColor: '#ffffff',
            minHeight: '60px',
            alignItems: 'center',
            color: "#00000044",

        }

        var pagingToolbar = (this.props.blackTheme == "true") ? pagingToolbarDark : pagingToolbarLight;

        var pagingToolbarTextDark = {
            backgroundColor: '#313943',
            alignItems: 'center',
            color: '#FFFFFF44',
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
            paddingBottom: '3px'
        }

        var pagingToolbarTextLight = {
            backgroundColor: '#FFFFFF',
            alignItems: 'center',
            color: '#000000aa',
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
            paddingBottom: '3px'
        }

        var pagingToolbarText = (this.props.blackTheme == "true") ? pagingToolbarTextDark : pagingToolbarTextLight;
        var pagingToolbarButton = (this.props.blackTheme == "true") ? 'pagingToolbarButtonDark' : 'pagingToolbarButtonLight';
        var pagingToolbarSpinner = (this.props.blackTheme == "true") ? 'pagingToolbarSpinnerDark' : 'pagingToolbarSpinnerLight';

        var refresh = (this.props.blackTheme == "true") ? refreshWhite : refreshBlack;
        var first = (this.props.blackTheme == "true") ? firstWhite : firstBlack;
        var before = (this.props.blackTheme == "true") ? beforeWhite : beforeBlack;
        var next = (this.props.blackTheme == "true") ? nextWhite : nextBlack;
        var last = (this.props.blackTheme == "true") ? lastWhite : lastBlack;

        var summary = "";

        if (typeof this.props.calendar_summary != 'undefined') {
            summary = <div style={{display: 'flex', flex: '1', marginRight: 20}}>
                <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összes óraszám</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Egyéb költségek</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Levonások</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összesen</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Fizetendő</td>
                            </tr>
            
                            <tr> 
                                <td style={{textAlign: 'center'}}>{new Number(this.props.numberOfHoursSum).toLocaleString()}</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.otherBenefits).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.salaryDeduction).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.calendar_hoursMultipliedByHourlyWage).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Number(this.props.calendar_summary).toLocaleString()}&nbsp;Ft</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }

        return <div style={pagingToolbar} >
         
            {summary}
            <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
                <table>
                    <tbody>
                        <tr>
                            <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összegek össz.</td>
                            <td>&nbsp;</td>
                            <td style={{textAlign: 'center', fontWeight: 'bold'}}>Fizetendők össz.</td>
                            <td>&nbsp;</td>
                            
        
                        </tr>
                        <tr> 
                            <td style={{textAlign: 'center'}}>{new Number(this.props.chequeSum).toLocaleString()}&nbsp;Ft</td>
                            <td>&nbsp;</td>
                            <td style={{textAlign: 'center'}}>{new Number(this.props.transferableSum).toLocaleString()}&nbsp;Ft</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>;
    }
}

export default PagingToolbar;
