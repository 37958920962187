import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
//import PagingToolbar from './../../components/PagingToolbar';
import InfoTableToolbar from './../HouseTreasury/InfoTableToolbar';

import {Button, Col, Row} from 'react-bootstrap';

import Dialog from './../../components/Dialog.js';
import EmployeTabs from './../HouseTreasury/EmployeTabs';
import FormatedNumberCell from './../../components/FormatedNumberCell';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class HouseTreasury extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'houseTreasury_id',
            'houseTreasury_employe_id',

            'houseTreasury_outDate',
            'houseTreasury_outName',
            'houseTreasury_outPlace',
            'houseTreasury_outWorkNumber_id',
            'houseTreasury_outWorkNumberName',
            'houseTreasury_outAmount',

            'houseTreasury_inDate',
            'houseTreasury_inName',
            'houseTreasury_inPlace',
            'houseTreasury_inWorkNumber_id',
            'houseTreasury_inWorkNumberName',
            'houseTreasury_inAmount'

        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "HouseTreasury", "houseTreasury");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 1000000,
                showingRowFrom: 0,
                showingRowTo: 1000000,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.doubleClick = 0;
        this.currentEmployeId = 0;


        this.inAmount = 0.0;
        this.outAmount = 0.0;
        this.diffAmount = 0.0;

        this.houseTreasury_date_from = '';
        this.houseTreasury_date_to = '';
        this.houseTreasury_workNumber = '';
        this.houseTreasury_name = '';
    }
    
    isComponenetUpdated(prevProps, newProps) {

        var changed = false;

        if (prevProps.date_from != newProps.date_from) {
            changed = true;
        }
        if (prevProps.date_to != newProps.date_to) {
            changed = true;
        }
        if (prevProps.workNumber != newProps.workNumber) {
            changed = true;
        }

        return changed;

    }

    componentDidUpdate(prevProps) {

        this.houseTreasury_date_from = this.props.date_from;
        this.houseTreasury_date_to = this.props.date_to;
        this.houseTreasury_workNumber = [this.props.workNumber];

        var updateNeed = this.isComponenetUpdated(prevProps, this.props);
        if (updateNeed == true) {
            this.forceUpdate();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        return true; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <InfoTableToolbar 
        
            blackTheme={this.props.theme} 
            inAmount={this.inAmount}
            outAmount={this.outAmount}
            diffAmount={this.diffAmount}
            />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    setForeignKeyFilter = function (filterValues, attr, value) {
        for (var f = 0; f < filterValues.length; f++) {
            var currentFilter = filterValues[f];
            if (filterValues[f].name == attr) {
                filterValues[f].value = value;
            }
        }
        return filterValues;
    }

    insert = function (e) {
        this.state.houseTreasury_employe_id = this.currentEmployeId;
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }


        var self = this;

        var params = this.concatAdditionalParams();
        var currentDate = new Date().toISOString().slice(0, 10);
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    houseTreasury_employe_id: this.currentEmployeId
                    , houseTreasury_inDate: currentDate
                    , houseTreasury_inName: ''
                    , houseTreasury_inPlace: ''
                    , houseTreasury_inWorkNumber_id: ''
                    , houseTreasury_inAmount: 0.0
                    , houseTreasury_outDate: currentDate
                    , houseTreasury_outName: ''
                    , houseTreasury_outPlace: ''
                    , houseTreasury_outWorkNumber_id: ''
                    , houseTreasury_outAmount: 0.0
                    , houseTreasury_incoming_invoice_id: ''
                    , houseTreasury_outgoing_invoice_id: ''

                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.state.selectedRow = response.data.lastInsertId;
                            self.setState({openedForm: self.FORM_UPDATE});
                            self.reload();


                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        //additionalParams.push({houseTreasury_employe_id:this.currentEmployeId});

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.setForeignKeyFilter(filterValue, 'houseTreasury_employe_id', this.currentEmployeId);
        filterValue = this.addForeignKeyFilter(filterValue);






        filterValue['houseTreasury_name'] = this.houseTreasury_name;


        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, houseTreasury_date_from: this.houseTreasury_date_from, houseTreasury_date_to: this.houseTreasury_date_to, houseTreasury_workNumber: this.houseTreasury_workNumber, houseTreasury_name: this.houseTreasury_name})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.extra != 'undefined') {

                            var extra = response.extra;

                            this.inAmount = extra['inAmount'];
                            this.outAmount = extra['outAmount'];
                            this.diffAmount = extra['diffAmount'];

                        } else {

                            this.inAmount = 0.0;
                            this.outAmount = 0.0;
                            this.diffAmount = 0.0;



                        }


                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({houseTreasury_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    onRowClick = function (rowProps, event) {
        var self = this;

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.houseTreasury_id == 'undefined') {
            return false;
        }

        if (rowProps.data.houseTreasury_id == null) {
            return false;
        }

        this.setState({selectedRow: rowProps.data.houseTreasury_id, openedForm: this.FORM_UPDATE});
    }

    setCurrentEmploye = function (id) {
        this.currentEmployeId = id;
        this.forceUpdate();
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    setHeaderFilterValues = function (filterState) {

        this.houseTreasury_date_from = filterState.houseTreasury_date_from;
        this.houseTreasury_date_to = filterState.houseTreasury_date_to;
        this.houseTreasury_workNumber = filterState.houseTreasury_workNumber;
        this.houseTreasury_name = filterState.houseTreasury_name;

        this.reload();

    }

    render() {

        const groups = [
            {name: 'outgoing', header: <div style={{textAlign: 'center'}} ><span style={{color: '#FF8677'}}> KIADÁS</span></div>},
            {name: 'incoming', header: <div style={{textAlign: 'center'}} ><span style={{color: '#449944'}}> BEVÉTEL</span></div>}
        ]



        var columns = [
            {group: 'incoming', name: 'houseTreasury_id', sortable: true, header: lang.getTitle('houseTreasury_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_id', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {group: 'incoming', name: 'houseTreasury_employe_id', sortable: true, header: lang.getTitle('houseTreasury_employe_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_employe_id', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {group: 'incoming', name: 'houseTreasury_inDate', sortable: true, header: lang.getTitle('houseTreasury_inDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inDate', 100), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {group: 'incoming', name: 'houseTreasury_inName', sortable: true, header: lang.getTitle('houseTreasury_inName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inName', 140), defaultVisible: true}
            , {group: 'incoming', name: 'houseTreasury_inPlace', sortable: true, header: lang.getTitle('houseTreasury_inPlace'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inPlace', 140), defaultVisible: true}
            , {group: 'incoming', name: 'houseTreasury_inWorkNumber_id', sortable: true, header: lang.getTitle('houseTreasury_inWorkNumber_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inWorkNumber_id', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {group: 'incoming', name: 'houseTreasury_inWorkNumberName', sortable: true, header: lang.getTitle('houseTreasury_inWorkNumberName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inWorkNumberName', 100), defaultVisible: true}
            , {group: 'incoming', name: 'houseTreasury_inAmount', sortable: true, header: lang.getTitle('houseTreasury_inAmount'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_inAmount', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }

            }
            , {group: 'outgoing', name: 'houseTreasury_outDate', sortable: true, header: lang.getTitle('houseTreasury_outDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outDate', 100), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {group: 'outgoing', name: 'houseTreasury_outName', sortable: true, header: lang.getTitle('houseTreasury_outName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outName', 140), defaultVisible: true}
            , {group: 'outgoing', name: 'houseTreasury_outPlace', sortable: true, header: lang.getTitle('houseTreasury_outPlace'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outPlace', 140), defaultVisible: true}
            , {group: 'outgoing', name: 'houseTreasury_outWorkNumber_id', sortable: true, header: lang.getTitle('houseTreasury_outWorkNumber_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outWorkNumber_id', 140), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {group: 'outgoing', name: 'houseTreasury_outWorkNumberName', sortable: true, header: lang.getTitle('houseTreasury_outWorkNumberName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outWorkNumberName', 100), defaultVisible: true}
            , {group: 'outgoing', name: 'houseTreasury_outAmount', sortable: true, header: lang.getTitle('houseTreasury_outAmount'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('houseTreasury_outAmount', 140), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }


        ];

        const filters = [
            {name: 'houseTreasury_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'houseTreasury_employe_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'houseTreasury_inDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'houseTreasury_inName', operator: 'contains', type: 'string', value: ''}
            , {name: 'houseTreasury_inPlace', operator: 'contains', type: 'string', value: ''}
            , {name: 'houseTreasury_inWorkNumber_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'houseTreasury_inWorkNumberName', operator: 'eq', type: 'string', value: ''}
            , {name: 'houseTreasury_inAmount', operator: 'eq', type: 'number', value: ''}
            , {name: 'houseTreasury_outDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'houseTreasury_outName', operator: 'contains', type: 'string', value: ''}
            , {name: 'houseTreasury_outPlace', operator: 'contains', type: 'string', value: ''}
            , {name: 'houseTreasury_outWorkNumber_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'houseTreasury_outWorkNumberName', operator: 'eq', type: 'string', value: ''}
            , {name: 'houseTreasury_outAmount', operator: 'eq', type: 'number', value: ''}


        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }




        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div style={{minWidth: '75vw', maxWidth: '75vw'}} className={'buttonBar'} id="HouseTreasuryTable">
                    <div style={{display: "flex"}}>
                        <EmployeTabs multiSelection={this.multiSelection} setCurrentEmploye={this.setCurrentEmploye.bind(this)} />
                    </div>
                    <div >
                        <div style={{display: 'contents'}}>
                            <ReactDataGrid 
                                idProperty="houseTreasury_id" 
                                i18n={ajax.getI18n()}
                                columns={this.columnStateHandler.myColumnsReorder(columns)}
                                dataSource={this.dataLoader.bind(this)}
                                defaultFilterValue={filters}
                                enableSelection={true}
                
                                onRowClick={this.onRowClick.bind(this)}
                                onSelectionChange={this.onSelectionChange.bind(this)}
                                theme={theme}
                                style={{height: '70vh', marginTop: 10}}    
                                rowHeight={25}
                                defaultLimit={1000000}
                                pagination={true}
                                renderPaginationToolbar={this.renderPaginationToolbar}
                                skip={this.state.skip}
                                limit={this.state.paging.pageRowCount}
                
                                onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                                onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                                onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                                groups={groups}
                                enableFiltering={false}
                                showColumnMenuTool={false}
                                />
                            {openedForm}            
                        </div>
                    </div>
                </div>
                );
    }
}

export default HouseTreasury;
