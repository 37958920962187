import React, { Component } from 'react';

import {Button, Row, Col, ToggleButton} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class Modulok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            mouseOverId: -1
        }



    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    getRole = function (modulId, roleType, value) {
        var nextAction = "";
        switch (roleType) {
            case 'roleFull':
                nextAction = (value == 0) ?
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_ADD :
                        this.props.parent.ACTION_TYPE_SINGLE_ROLE_REMOVE;
                ;
                break;

        }

        return <ToggleButton
            style={{maxHeight: 20}}    
            id={"toggle-check_" + modulId + "_" + roleType + "_" + value}
            type="checkbox"
            variant="outline-success"
            checked={(value == 0) ? false : true}
            value={value}
            size="sm"
            onClick={() => this.props.parent.jogosultsagBeallitasa(this.props.parent.jogosultsagBeallitasa(nextAction, this.props.parent.state.activeJogosultsagTipus, modulId))}
        
            >&nbsp;</ToggleButton>

    }

    getModul = function (modul) {
        var id = modul.id;
        var jogosultsagTipus_id = modul.jogosultsagTipus_id;
        var module_name = modul.module_name;
        var roleFull = modul.roleFull;



        var moduleStyle = {cursor: 'pointer', minHeight: 30, justifyContent: 'center'}

        if (this.state.mouseOverId == id) {
            moduleStyle['boxShadow'] = '1px 1px 3px #00000066';
        }

        return <Row id={id}
         style={moduleStyle}    
         onMouseOver={() => this.setState({mouseOverId: id})}
         onMouseLeave={() => this.setState({mouseOverId: -1})}
         >
        <Col  style={{textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}} >
        <div>{lang.getTitle(module_name)}</div>
        </Col>
        <Col style={{textAlign: 'left'}}><div style={{paddingLeft: 50}}>{this.getRole(id, 'roleFull', roleFull)}</div></Col>
    
    </Row>



    }

    sortByAttribute = function (array, attribute) {
      array.sort(function (a, b) {
            var textA = lang.getTitle(a[attribute]);
            var textB = lang.getTitle(b[attribute]);
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }

    getModulok = function (data) {
        this.sortByAttribute(data,'module_name');
        var modulok = [];
        for (var i = 0; i < data.length; i++) {
            var modulData = data[i];
            var modul = this.getModul(modulData);
            modulok.push(modul);
        }
        return <div>{modulok}</div>
    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var modulok = this.getModulok(this.props.data);
        return (
                <div>  
                    <Row key={'modulok_header'} id={'modulok_header'}>
                        <Col  style={{textAlign: 'left', marginTop: 'auto', marginBottom: 'auto'}} >
                        <div>MODUL NEVE</div>
                        </Col>
                        <Col style={{textAlign: 'center', marginTop: 'auto', marginBottom: 'auto'}} >
                        <div style={{display: 'flex', justifyContent: 'left'}}>
                
                            <div>Engedélyezés</div>
                
                        </div>
                        </Col>
                    </Row>
                
                
                
                
                    {modulok}
                </div>
                );
    }
}

export default Modulok;
