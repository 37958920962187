
import React, { Component } from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import Tree from './../../components/Tree.js';
import TextInsertField from './../../components/TextInsertField';
import GoogleDriveFiles from "./../../img/google-drive-social-media-svgrepo-com.svg";


class GoogleDriveFilesContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            activeNodeId: null,
            newTitle: '',
            openedTreeNodes: []
        }

        this.nodes = [];
        this.load();
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    hasChildren = function (id) {
        var nodes = this.nodes;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            if (node.parentId == id) {
                return true;
            }
        }
        return false;
    }

    openAllNodes = function () {
        var nodes = this.nodes;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            var hasChildren = this.hasChildren(node.id);
            if (hasChildren) {
                var isNodeOpened = this.state.openedTreeNodes.indexOf(node.id) > -1;
                if (isNodeOpened == false) {
                    this.state.openedTreeNodes.push(node.id);
                }
            }
        }

    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFiles/googleDriveFilesFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            self.nodes = response.data;
                            self.openAllNodes();
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    insertNode = function () {
        var self = this;


        self.state.openedTreeNodes.push(self.state.activeNodeId);
        self.openAllNodes();


        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFiles/googleDriveFilesFormInsert.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: self.state.activeNodeId, title: self.state.newTitle})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.nodes = response.data;
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    deleteNodeStructure = function (id) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFiles/googleDriveFilesFormDelete.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({googleDriveFiles_id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (response.success) {
                            self.state.activeNodeId = null;
                            this.nodes = response.data;
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getTitleField = function () {

        return <div className={'treeFont'} style={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <div style={{margin: 5}}>
                <TextInsertField 
                    id={'newTitle'} 
                    value={this.state.newTitle}  
                    setBack={this.setBack.bind(this)}
                    editable={true}
                    theme={this.props.theme}
                    />
            </div>
            <div>
                <Button className={'btn-sm'} variant="info" onClick={this.insertNode.bind(this)}>{lang.getTitle('Insert')}</Button>
            </div>
        
        </div>

    }

    treeClickHandler = function (e, action) {
        if (action == 'SELECT') {
            this.setState({activeNodeId: e.id});
        }

        if (action == 'DELETE') {
            this.deleteNodeStructure(e.id);
        }
    }

    render() {

        var titleField = this.getTitleField();
        var header = (this.state.activeNodeId != null) ? titleField : <div  className={'treeFont'} style={{minHeight: 37}}>
            Új elem akkor adható hozzá, ha kiválaszt egy mappát.<br/> A mappa kiválasztásához kattintson a mappa nevére.
        </div>;
        return (
                <div   id="GoogleDriveFilesContent">
                    <div className="tableTitle"><b>{lang.getTitle("GoogleDriveFiles")}</b></div> 
                    <Row>
                        <Col >
                        <div className="treeContainer">{header}</div>
                        <div className="treeContainer" style={{display: 'flex', minHeight: '80vh', minWidth: "100%", backgroundColor: 'white'}}>
                
                            <Tree 
                                onNodeSelect={(e, action) => {
                                        this.treeClickHandler(e, action);
                                    }} 
                                nodes={this.nodes} 
                                openedTreeNodes = {this.state.openedTreeNodes}    
                                parentId={null} 
                                treeActiveNodeId ={this.state.activeNodeId}    
                                theme={this.props.theme}
                
                
                                />        
                        </div>
                        </Col>
                        <Col style={{display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF88'}}>
                        
                        </Col>
                    </Row>
                </div>
                );
    }
}

export default GoogleDriveFilesContent;
