import React, { Component } from 'react';
import EasyTableResults from './EasyTableResults';
import {Button, Col} from 'react-bootstrap';
import * as ajax from './../../ajax';

class EmployeFull extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        var numberRenderer = (row, value) => {
            var numberStyle = {whiteSpace: 'nowrap', color: '#1880c9'};
            if (value == 0) {
                numberStyle.color = '#00000044';
            }
            var valStr = (isNaN(value)) ? '' : (new Number(value)).toLocaleString();
            if (valStr != '0') {
                return <div style={numberStyle}>{valStr}</div>;
            } else {
                return <div style={numberStyle}>{valStr}</div>;
            }
        };



        this.tableDescriptor = {
            tableHeader: {
                title: '',
                filterComboData: []
            },
            idColumn: 'customerId',
            titleVisible: true,
            columnDescriptors: [
                  {order: 0, name: 'name', title: 'Név', renderer: null, flex: 1, visible: true}
                , {order: 1, name: 'gross', title: 'Összesen', renderer: numberRenderer, flex: 1, visible: true}
                , {order: 2, name: 'count', title: 'Darab', renderer: numberRenderer, flex: 1, visible: true}
            ],
            data: [

            ]
        }
        this.filters = {};
        this.tableDescriptor.data = [];
        this.exportMode = 0;
        this.dataLoader();

        this.sum = 0.0;
    }

    componentDidUpdate(prevProps) {

        if (this.props.date_from != prevProps.date_from) {
            this.dataLoader();
        }
        if (this.props.date_to != prevProps.date_to) {
            this.dataLoader();
        }
        if (this.props.workNumber != prevProps.workNumber) {
            this.dataLoader();
        }
        return false;
    }

    dataLoader = function ( ) {
        this.filters["exportMode"] = this.exportMode;
        var state = this.state;
        try {
            const url = ajax.getUrlBase() + "modules/Results/index.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    date_from: this.props.date_from,
                    date_to: this.props.date_to,
                    workNumber: this.props.workNumber,
                    pageName:'EmployeFull'
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.sum=Math.round(response.extra.sum);
                        this.sum=(new Number(this.sum)).toLocaleString();
                        this.tableDescriptor.data = response.data;
                        this.forceUpdate();
                        this.setState({});
                        
                        

                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    reload = function () {}
    setHeaderFilterValues = function (filters) {
        this.filters = filters;
        this.dataLoader();

    }

    render() {
        return (<div>
            <div>
                <div style={{display: 'flex'}}>
                    <div style={{
                                    minWidth: '65vw',
                                    maxWidth: '65vw',
                                    overflowY: 'auto',
                                    minHeight: '65vh',
                                    maxHeight: '65vh',

                                     }} id="SubcontractorTable">
                        <EasyTableResults 
                            tableDescriptor={this.tableDescriptor} 
                            />
                    </div>
        
                </div>
        
            </div>
            <div style={{display: 'flex', marginRight: 20}}>
                <div style={{position: 'relative', marginLeft: 'auto'}}>
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Összesen</b></td>
                            </tr>
                            <tr>
                                <td><b>{this.sum}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
                );
    }
}

export default EmployeFull;
