import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';

import DateInsertField from './../../components/DateInsertField';
import TextInsertField from './../../components/TextInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import InvoiceInsertForm from './../InvoiceIncoming/InvoiceInsertForm';


class HouseTreasuryInsert extends Component {

    constructor(props) {
        super(props);
        var currentDate = new Date().toISOString().slice(0, 10);
        this.state = {
            houseTreasury_inDate: currentDate
            , houseTreasury_inName: ''
            , houseTreasury_inPlace: ''
            , houseTreasury_inWorkNumber_id: ''
            , houseTreasury_inAmount: 0.0
            , houseTreasury_outDate: currentDate
            , houseTreasury_outName: ''
            , houseTreasury_outPlace: ''
            , houseTreasury_outWorkNumber_id: ''
            , houseTreasury_outAmount: 0.0
            , houseTreasury_invoice_id: ''
           


        }


        this.references = [];
        this.references['houseTreasury_inDate'] = React.createRef();
        this.references['houseTreasury_inName'] = React.createRef();
        this.references['houseTreasury_inPlace'] = React.createRef();
        this.references['houseTreasury_inWorkNumber_id'] = React.createRef();
        this.references['houseTreasury_inAmount'] = React.createRef();
        this.references['houseTreasury_outDate'] = React.createRef();
        this.references['houseTreasury_outName'] = React.createRef();
        this.references['houseTreasury_outPlace'] = React.createRef();
        this.references['houseTreasury_outWorkNumber_id'] = React.createRef();
        this.references['houseTreasury_outAmount'] = React.createRef();
        this.references['houseTreasury_inSum'] = React.createRef();
        this.references['houseTreasury_outSum'] = React.createRef();
        this.references['houseTreasury_grossSum'] = React.createRef();


        this.validators = [];
        this.validators['houseTreasury_inDate'] = {fieldName: 'houseTreasury_inDate', dataType: 'DATE', value: this.state.houseTreasury_inDate, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inName'] = {fieldName: 'houseTreasury_inName', dataType: 'VARCHAR', value: this.state.houseTreasury_inName, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inPlace'] = {fieldName: 'houseTreasury_inPlace', dataType: 'VARCHAR', value: this.state.houseTreasury_inPlace, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inWorkNumber_id'] = {fieldName: 'houseTreasury_inWorkNumber_id', dataType: 'INT', value: this.state.houseTreasury_inWorkNumber_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inAmount'] = {fieldName: 'houseTreasury_inAmount', dataType: 'DOUBLE', value: this.state.houseTreasury_inAmount, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outDate'] = {fieldName: 'houseTreasury_outDate', dataType: 'DATE', value: this.state.houseTreasury_outDate, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outName'] = {fieldName: 'houseTreasury_outName', dataType: 'VARCHAR', value: this.state.houseTreasury_outName, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outPlace'] = {fieldName: 'houseTreasury_outPlace', dataType: 'VARCHAR', value: this.state.houseTreasury_outPlace, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outWorkNumber_id'] = {fieldName: 'houseTreasury_outWorkNumber_id', dataType: 'INT', value: this.state.houseTreasury_outWorkNumber_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outAmount'] = {fieldName: 'houseTreasury_outAmount', dataType: 'DOUBLE', value: this.state.houseTreasury_outAmount, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inSum'] = {fieldName: 'houseTreasury_inSum', dataType: 'DOUBLE', value: this.state.houseTreasury_inSum, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outSum'] = {fieldName: 'houseTreasury_outSum', dataType: 'DOUBLE', value: this.state.houseTreasury_outSum, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_grossSum'] = {fieldName: 'houseTreasury_grossSum', dataType: 'DOUBLE', value: this.state.houseTreasury_grossSum, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.withInvoice = false;

    }

    componentDidMount() {
        this.reset();

    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {

        var currentDate = new Date().toISOString().slice(0, 10);
        this.setState({
            houseTreasury_inDate: currentDate
            , houseTreasury_inName: ''
            , houseTreasury_inPlace: ''
            , houseTreasury_inWorkNumber_id: ''
            , houseTreasury_inAmount: 0.0
            , houseTreasury_outDate: currentDate
            , houseTreasury_outName: ''
            , houseTreasury_outPlace: ''
            , houseTreasury_outWorkNumber_id: ''
            , houseTreasury_outAmount: 0.0
            , houseTreasury_invoice_id: ''

        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    invoiceMode = function (e) {
        this.withInvoice = e.target.checked;
        this.forceUpdate();
    }

    getForm = function () {
        return <Container>
        
            <Row>
                <Col style={{border: 'solid 1px #00000044', margin: 5, padding: 5}}>
                <Row>
                    <Col style={{textAlign: 'center'}}>KIADÁS</Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('Számlával')}</Col>
                    <Col style={{textAlign: 'right', display: 'contents', marginLeft: 5}}>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label=""
                        onChange={this.invoiceMode.bind(this)}
                        />&nbsp;&nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outDate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <DateInsertField 
                        id={'houseTreasury_outDate'} 
                        value={this.state.houseTreasury_outDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outName')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_outName'} 
                        value={this.state.houseTreasury_outName}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outName']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outPlace')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_outPlace'} 
                        value={this.state.houseTreasury_outPlace}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outPlace']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outWorkNumber_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'houseTreasury_outWorkNumber_id'} 
                        value={this.state.houseTreasury_outWorkNumber_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/outWorkNumber_idFormComboInsertData.php'}
                        reference={this.references['houseTreasury_outWorkNumber_id']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outAmount')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'houseTreasury_outAmount'} 
                        value={this.state.houseTreasury_outAmount}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outAmount']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
               
        
                </Col>
                <Col style={{border: 'solid 1px #00000044', margin: 5, padding: 5}}>
                <Row>
                    <Col style={{textAlign: 'center'}}>BEVÉTEL</Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}></Col>
                    <Col style={{textAlign: 'right', display: 'contents'}}>
                    &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inDate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <DateInsertField 
                        id={'houseTreasury_inDate'} 
                        value={this.state.houseTreasury_inDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inName')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_inName'} 
                        value={this.state.houseTreasury_inName}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inName']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inPlace')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_inPlace'} 
                        value={this.state.houseTreasury_inPlace}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inPlace']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inWorkNumber_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'houseTreasury_inWorkNumber_id'} 
                        value={this.state.houseTreasury_inWorkNumber_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/inWorkNumber_idFormComboInsertData.php'}
                        reference={this.references['houseTreasury_inWorkNumber_id']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inAmount')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'houseTreasury_inAmount'} 
                        value={this.state.houseTreasury_inAmount}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inAmount']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
                </Col>
        
            </Row>
        
        </Container>
    }

    closeInvoiceForm = function () {
        this.withInvoice = false;
        this.forceUpdate();
    }

    closeForm = function () {

        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();
        console.log(params)
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var insertForm = "";
        if (this.withInvoice == true) {
            var currentDate = new Date().toISOString().slice(0, 10);

            insertForm = <InvoiceInsertForm  
                theme={this.props.theme} 
                key={'invoice_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                additionalParams={[]} 
                invoice_invoiceDirection_id={1}
                invoice_paidInCash={1}
                invoice_dateOfCompletion={currentDate}
                invoice_invoiceDate={currentDate}
                invoice_paymentDate={currentDate}
                invoice_paymentDeadline={currentDate}
                closeForm={this.closeInvoiceForm.bind(this)} 
            
                />
        }
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="HouseTreasuryInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('HouseTreasuryInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
                {insertForm}
            </div>
        </PopUpWindow>);
    }
}

export default HouseTreasuryInsert;

