import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ComboBoxInsertFieldCustomerAddress from './ComboBoxInsertFieldCustomerAddress';
import ComboBoxInsertFieldWorkNumber from './ComboBoxInsertFieldWorkNumber';
import ComboBoxRemarkInsertField from './ComboBoxRemarkInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import DateInsertField from './../../components/DateInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';


class ChequeInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cheque_id: ''
            , cheque_customer_id: ''
            , cheque_chequeRemark_id: ''
            , cheque_workNumber_id: ''
            , cheque_customerAddress_id: ''
            , cheque_grossValue: ''
            , cheque_dateOfArrival: ''
            , cheque_remark: ''
            , cheque_paymentDate: ''
            , cheque_transferable: ''
            , cheque_chequeIssuer_id: ''

        }


        this.references = [];
        this.references['cheque_id'] = React.createRef();
        this.references['cheque_customer_id'] = React.createRef();
        this.references['cheque_chequeRemark_id'] = React.createRef();
        this.references['cheque_workNumber_id'] = React.createRef();
        this.references['cheque_customerAddress_id'] = React.createRef();
        this.references['cheque_grossValue'] = React.createRef();
        this.references['cheque_dateOfArrival'] = React.createRef();
        this.references['cheque_remark'] = React.createRef();
        this.references['cheque_paymentDate'] = React.createRef();
        this.references['cheque_transferable'] = React.createRef();
        this.references['cheque_chequeIssuer_id'] = React.createRef();


        this.validators = [];
        this.validators['cheque_id'] = {fieldName: 'cheque_id', dataType: 'INT', value: this.state.cheque_id, mandatory: false, min: null, max: null};
        this.validators['cheque_customer_id'] = {fieldName: 'cheque_customer_id', dataType: 'INT', value: this.state.cheque_customer_id, mandatory: false, min: null, max: null};
        this.validators['cheque_chequeRemark_id'] = {fieldName: 'cheque_chequeRemark_id', dataType: 'INT', value: this.state.cheque_chequeRemark_id, mandatory: false, min: null, max: null};
        this.validators['cheque_workNumber_id'] = {fieldName: 'cheque_workNumber_id', dataType: 'INT', value: this.state.cheque_workNumber_id, mandatory: false, min: null, max: null};
        this.validators['cheque_customerAddress_id'] = {fieldName: 'cheque_customerAddress_id', dataType: 'INT', value: this.state.cheque_customerAddress_id, mandatory: false, min: null, max: null};
        this.validators['cheque_grossValue'] = {fieldName: 'cheque_grossValue', dataType: 'DOUBLE', value: this.state.cheque_grossValue, mandatory: false, min: null, max: null};
        this.validators['cheque_dateOfArrival'] = {fieldName: 'cheque_dateOfArrival', dataType: 'DATE', value: this.state.cheque_dateOfArrival, mandatory: false, min: null, max: null};
        this.validators['cheque_remark'] = {fieldName: 'cheque_remark', dataType: 'VARCHAR', value: this.state.cheque_remark, mandatory: false, min: null, max: null};
        this.validators['cheque_paymentDate'] = {fieldName: 'cheque_paymentDate', dataType: 'DATE', value: this.state.cheque_paymentDate, mandatory: false, min: null, max: null};
        this.validators['cheque_transferable'] = {fieldName: 'cheque_transferable', dataType: 'DOUBLE', value: this.state.cheque_transferable, mandatory: false, min: null, max: null};
        this.validators['cheque_chequeIssuer_id'] = {fieldName: 'cheque_chequeIssuer_id', dataType: 'INT', value: this.state.cheque_chequeIssuer_id, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({cheque_id: ''
            , cheque_customer_id: ''
            , cheque_workNumber_id: ''
            , cheque_customerAddress_id: ''
            , cheque_grossValue: ''
            , cheque_dateOfArrival: ''
            , cheque_remark: ''
            , cheque_paymentDate: ''
            , cheque_transferable: ''
            , cheque_chequeIssuer_id: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }
    setRemarkText = function(txt){

        this.setState({cheque_remark:txt});
    }
     getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_customer_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'cheque_customer_id'} 
                    value={this.state.cheque_customer_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Cheque/customer_idFormComboInsertData.php'}
                    reference={this.references['cheque_customer_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: 500}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_customerAddress_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertFieldCustomerAddress 
                    id={'cheque_customerAddress_id'} 
                    value={this.state.cheque_customerAddress_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Cheque/customerAddress_idFormComboInsertData.php'}
                    reference={this.references['cheque_customerAddress_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: 500}}
                    customer_id={this.state.cheque_customer_id} 
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_workNumber_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertFieldWorkNumber 
                    id={'cheque_workNumber_id'} 
                    value={this.state.cheque_workNumber_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Cheque/workNumber_idFormComboInsertData.php'}
                    reference={this.references['cheque_workNumber_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: 500}}
                    customerAddress_id={this.state.cheque_customerAddress_id}
                    />
                </Col>
            </Row>
            
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_grossValue')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'cheque_grossValue'} 
                    value={this.state.cheque_grossValue}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['cheque_grossValue']}
                    editable={true}
                    theme={this.props.theme}
        
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_dateOfArrival')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'cheque_dateOfArrival'} 
                    value={this.state.cheque_dateOfArrival}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['cheque_dateOfArrival']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: 500}}
                    />
                </Col>
            </Row>
                       <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_remark')}</Col>
                <ComboBoxRemarkInsertField 
                    id={'cheque_chequeRemark_id'} 
                    value={this.state.cheque_chequeRemark_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Cheque/chequeRemark_idFormComboInsertData.php'}
                    reference={this.references['cheque_chequeRemark_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{width:'100%'}}
                    setRemark={this.setRemarkText.bind(this)}
                    />
        
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'cheque_remark'} 
                    value={this.state.cheque_remark}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['cheque_remark']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_paymentDate')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'cheque_paymentDate'} 
                    value={this.state.cheque_paymentDate}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['cheque_paymentDate']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_transferable')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'cheque_transferable'} 
                    value={this.state.cheque_transferable}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['cheque_transferable']}
                    editable={true}
                    theme={this.props.theme}
        
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('cheque_chequeIssuer_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'cheque_chequeIssuer_id'} 
                    value={this.state.cheque_chequeIssuer_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Cheque/chequeIssuer_idFormComboInsertData.php'}
                    reference={this.references['cheque_chequeIssuer_id']}
                    editable={true}
                    theme={this.props.theme}
                    style={{minWidth: 500}}
                    />
        
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Cheque/chequeFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="ChequeInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('ChequeInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default ChequeInsert;

