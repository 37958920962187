import React, { Component } from 'react';



class InfoTableToolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        var isRed = {textAlign: 'center', color: 'black',fontWeight:'bold'};
        if (this.props.diffAmount < 0) {
            isRed = {textAlign: 'center', color: 'red',fontWeight:'bold'};
        }
        return <div style={{display: 'flex', flex: '1', marginRight: 20}}>
    <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
        <table>
            <tbody>
                <tr>
                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>Bevétel</td>
                    <td>&nbsp;</td>
                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>Kiadás</td>
                    <td>&nbsp;</td>
                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>Eredmény</td>
                    <td>&nbsp;</td>

                </tr>
                <tr> 
                    <td style={{textAlign: 'center'}}>{new Number(this.props.inAmount).toLocaleString()}&nbsp;Ft</td>
                    <td>&nbsp;</td>
                    <td style={{textAlign: 'center'}}>{new Number(this.props.outAmount).toLocaleString()}&nbsp;Ft</td>
                    <td>&nbsp;</td>
                    <td style={isRed}>{new Number(this.props.diffAmount).toLocaleString()}&nbsp;Ft</td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
    }

}

export default InfoTableToolbar;
