import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';


class EmployeAddressInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employeAddress_employe_id: ''
            , employeAddress_city_id: ''
            , employeAddress_publicSpaceName: ''
            , employeAddress_publicSpaceType_id: ''
            , employeAddress_floor: ''
            , employeAddress_houseNumber: ''

        }


        this.references = [];
        this.references['employeAddress_employe_id'] = React.createRef();
        this.references['employeAddress_city_id'] = React.createRef();
        this.references['employeAddress_publicSpaceName'] = React.createRef();
        this.references['employeAddress_publicSpaceType_id'] = React.createRef();
        this.references['employeAddress_floor'] = React.createRef();
        this.references['employeAddress_houseNumber'] = React.createRef();


        this.validators = [];
        this.validators['employeAddress_employe_id'] = {fieldName: 'employeAddress_employe_id', dataType: 'INT', value: this.state.employeAddress_employe_id, mandatory: false, min: null, max: null};
        this.validators['employeAddress_city_id'] = {fieldName: 'employeAddress_city_id', dataType: 'INT', value: this.state.employeAddress_city_id, mandatory: false, min: null, max: null};
        this.validators['employeAddress_publicSpaceName'] = {fieldName: 'employeAddress_publicSpaceName', dataType: 'VARCHAR', value: this.state.employeAddress_publicSpaceName, mandatory: false, min: null, max: null};
        this.validators['employeAddress_publicSpaceType_id'] = {fieldName: 'employeAddress_publicSpaceType_id', dataType: 'INT', value: this.state.employeAddress_publicSpaceType_id, mandatory: false, min: null, max: null};
        this.validators['employeAddress_floor'] = {fieldName: 'employeAddress_floor', dataType: 'VARCHAR', value: this.state.employeAddress_floor, mandatory: false, min: null, max: null};
        this.validators['employeAddress_houseNumber'] = {fieldName: 'employeAddress_houseNumber', dataType: 'VARCHAR', value: this.state.employeAddress_houseNumber, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
    }


    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({employeAddress_employe_id: ''
            , employeAddress_city_id: ''
            , employeAddress_publicSpaceName: ''
            , employeAddress_publicSpaceType_id: ''
            , employeAddress_floor: ''
            , employeAddress_houseNumber: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>

    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_city_id')}</Col>
        <Col style={{textAlign: 'right'}}>
        <ComboBoxInsertField 
            id={'employeAddress_city_id'} 
            value={this.state.employeAddress_city_id}  
            setBack={this.setBack.bind(this)}
            dataSourceUrl={'modules/EmployeAddress/city_idFormComboInsertData.php'}
            reference={this.references['employeAddress_city_id']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_publicSpaceName')}</Col>
        <Col style={{textAlign: 'right'}}>
        <TextInsertField 
            id={'employeAddress_publicSpaceName'} 
            value={this.state.employeAddress_publicSpaceName}  
            setBack={this.setBack.bind(this)}
            reference={this.references['employeAddress_publicSpaceName']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_publicSpaceType_id')}</Col>
        <Col style={{textAlign: 'right'}}>
        <ComboBoxInsertField 
            id={'employeAddress_publicSpaceType_id'} 
            value={this.state.employeAddress_publicSpaceType_id}  
            setBack={this.setBack.bind(this)}
            dataSourceUrl={'modules/EmployeAddress/publicSpaceType_idFormComboInsertData.php'}
            reference={this.references['employeAddress_publicSpaceType_id']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_floor')}</Col>
        <Col style={{textAlign: 'right'}}>
        <TextInsertField 
            id={'employeAddress_floor'} 
            value={this.state.employeAddress_floor}  
            setBack={this.setBack.bind(this)}
            reference={this.references['employeAddress_floor']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>
    <Row>
        <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_houseNumber')}</Col>
        <Col style={{textAlign: 'right'}}>
        <TextInsertField 
            id={'employeAddress_houseNumber'} 
            value={this.state.employeAddress_houseNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['employeAddress_houseNumber']}
            editable={true}
            theme={this.props.theme}
            />
        </Col>
    </Row>

</Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/EmployeAddress/employeAddressFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{height: 600}}>
            <div className={'popupWrapper' + className} id="EmployeAddressInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('EmployeAddressInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      

                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default EmployeAddressInsert;

