import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, NavDropdown, Form, FormControl, Button, Row, Col} from 'react-bootstrap';
import * as lang from './../lang.js';
import * as ajax from './../ajax.js';
import mini_logo_light from './../css/mini_logo_light.svg';
import mini_logo_dark from './../css/mini_logo_dark.svg';

import Login from './../modules/Login/Login';
import Menu from './../mainScreen/Menu';

import CustomerContent from './../modules/Customer/CustomerContent';
import CustomerStateContent from './../modules/CustomerState/CustomerStateContent';
import EmailAddressPostfixContent from './../modules/EmailAddressPostfix/EmailAddressPostfixContent';
import EmployeeContent from './../modules/Employee/EmployeeContent';
import EmployeAddressContent from './../modules/EmployeAddress/EmployeAddressContent';
import EmployePhoneContent from './../modules/EmployePhone/EmployePhoneContent';
import HourlyWageContent from './../modules/HourlyWage/HourlyWageContent';
import PublicSpaceTypeContent from './../modules/PublicSpaceType/PublicSpaceTypeContent';
import VatContent from './../modules/Vat/VatContent';
import WorkNumberContent from './../modules/WorkNumber/WorkNumberContent';
import OtherCostsContent from './../modules/OtherCosts/OtherCostsContent';

import NewTableTest from './../modules/NewTableTest/NewTableTest';
import CalendarContent from './../modules/Calendar/CalendarContent';

import GoogleDriveFilesContent from './../modules/GoogleDriveFiles/GoogleDriveFilesContent';
import JogosultsagokContent from './../modules/Jogosultsagok/JogosultsagokContent';
import ChequeContent from './../modules/Cheque/ChequeContent';
import ChequeIssuerContent from './../modules/ChequeIssuer/ChequeIssuerContent';
import ChequeRemarkContent from './../modules/ChequeRemark/ChequeRemarkContent';

import InvoiceContentIncoming from './../modules/InvoiceIncoming/InvoiceContent';
import InvoiceContentOutgoing from './../modules/InvoiceOutgoing/InvoiceContent';
import AnalyticsContent from './../modules/Analytics/AnalyticsContent';
import Results from './../modules/Results/Results';
import HouseTreasuryContent from './../modules/HouseTreasury/HouseTreasuryContent';
import CityContent from './../modules/City/CityContent';

import MazasLogo from './../img/MZS-logo-250-min-1.png';
import LogoutLogo from './../img/logout.svg';
import menuCloseBlack from './../img/black/toggle_off_black_24dp.svg';
import menuOpenBlack from './../img/black/toggle_on_black_24dp.svg';

class Contents extends Component {
    constructor() {
        super();
        this.state = {
            content: '',
            loggedIn: false,
            timerID:null
        };

        this.BLACK_THEME = false;
        this.WHITE_THEME = true;

        if (this.state.timerID === null) {
            this.state.timerID = setInterval(() => this.stayAlive(), 60000);
        }


    }

    stayAlive() {
        try {
            const url = ajax.getUrlBase() + "secure/stayAlive.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    hideMenu = function () {
        this.setState({menuCollapsed: !this.state.menuCollapsed});
    }
    switch = function (e) {
        this.setState({
            content: e.target.id,
            menuCollapsed: false
        });
    }

    changeTheme = function () {
        if (this.props.theme == "true") {
            this.props.setTheme("false")
        } else {
            this.props.setTheme("true")
        }
    }

    logOut = function () {
        try {
            const url = ajax.getUrlBase() + "secure/logOut.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        window.location = './';
                    }).catch(function (jsonError) {
                        window.location = './';
                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    navbar = function () {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var logo = (this.props.theme == "true") ? mini_logo_dark : mini_logo_light;
        return (<Navbar 
    className={"contentHeader" + className} 
    expand="lg" 
    style={{backgroundColor: 'white', borderBottom: 'solid 1px #00000044', padding: 5, boxShadow: '2px 0px 3px #000000'}}>
    <Navbar.Brand href="#home"  style={{minWidth: '100%'}}>
        <div style={{display: 'flex'}}>
            <div style={{paddingLeft: 20, flex: 1}}><img src={MazasLogo} style={{maxHeight: 30, paddingRight: 20}} /><b>- ERP</b></div>
            <div style={{color: 'black', paddingLeft: 20}} ></div>
            <div style={{flex: 10}}></div>        
            <div 
                onClick={this.logOut.bind(this)}
                style={{flex: 1, textAlign: 'right'}}><img  src={LogoutLogo} style={{maxHeight: 30, paddingRight: 20}} /></div>        


        </div>
    </Navbar.Brand>
</Navbar>)
    }

    setContent = function (contentName) {
        this.setState({content: contentName});
    }

    getContentFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var contentName = urlParams.get('menu');
        return contentName;
    }

    setLoginState = function (data) {
        this.setState({loggedIn: data.success, moduleRolesArray: data.data});
    }

    render() {
        document.body.style.height = "95vh";
        //  document.body.style.overflow = "hidden";


        var contentFromParam = this.getContentFromParam();
        if (contentFromParam != null) {
            this.state.content = contentFromParam;
            this.state.menuCollapsed = true;
        } else {
            if (this.state.loggedIn == false) {
                return <Login  setLoginState={this.setLoginState.bind(this)} />
            }
        }

        var content = <div></div>;
        switch (this.state.content) {
            case 'Calendar':
                content = <CalendarContent theme={this.props.theme} />;
                break;
            case 'Customer' :
                content = <CustomerContent theme={this.props.theme} />;
                break;
            case 'CustomerState' :
                content = <CustomerStateContent theme={this.props.theme} />;
                break;
            case 'EmailAddressPostfix' :
                content = <EmailAddressPostfixContent theme={this.props.theme} />;
                break;
            case 'Employee' :
                content = <EmployeeContent theme={this.props.theme} />;
                break;
            case 'EmployeAddress' :
                content = <EmployeAddressContent theme={this.props.theme} />;
                break;
            case 'EmployePhone' :
                content = <EmployePhoneContent theme={this.props.theme} />;
                break;
            case 'HourlyWage' :
                content = <HourlyWageContent theme={this.props.theme} />;
                break;
            case 'PublicSpaceType' :
                content = <PublicSpaceTypeContent theme={this.props.theme} />;
                break;
            case 'Vat' :
                content = <VatContent theme={this.props.theme} />;
                break;
            case 'WorkNumber' :
                content = <WorkNumberContent theme={this.props.theme} />;
                break;
            case 'WorkNumberOffer' :
                content = <WorkNumberContent additionalParams={[{workNumber_state: 1}]} theme={this.props.theme} />;
                break;
            case 'WorkNumberRunning' :
                content = <WorkNumberContent additionalParams={[{workNumber_state: 2}]}  theme={this.props.theme} />;
                break;
            case 'WorkNumberPayable' :
                content = <WorkNumberContent additionalParams={[{workNumber_state: 3}]}  theme={this.props.theme} />;
                break;
            case 'GoogleDriveFiles' :
                content = <GoogleDriveFilesContent theme={this.props.theme} />;
                break;
            case 'UserRoles' :
                content = <JogosultsagokContent theme={this.props.theme} />;
                break;

            case 'cheque' :
                content = <ChequeContent theme={this.props.theme} />;
                break;
            case 'ChequeIssuerContent' :
                content = <ChequeIssuerContent theme={this.props.theme} />;
                break;

            case 'ChequeRemarkContent' :
                content = <ChequeRemarkContent theme={this.props.theme} />;
                break;

            case 'InvoiceIncoming' :
                content = <InvoiceContentIncoming theme={this.props.theme} additionalParams={[{invoice_invoiceDirection_id: 1, invoice_paidInCash: ''}]}/>;
                break;
            case 'PayWithCash' :
                content = <InvoiceContentIncoming theme={this.props.theme} additionalParams={[{invoice_invoiceDirection_id: 1, invoice_paidInCash: 1}]}/>;
                break;
            case 'PayWithTransfer' :
                content = <InvoiceContentIncoming theme={this.props.theme} additionalParams={[{invoice_invoiceDirection_id: 1, invoice_paidInCash: 0}]}/>;
                break;
            case 'InvoiceOutgoing' :
                content = <InvoiceContentOutgoing theme={this.props.theme}  additionalParams={[{invoice_invoiceDirection_id: 2}]}/>;
                break;
            case 'NewTableTest' :
                content = <NewTableTest />;
                break;
            case 'OtherCosts' :
                content = <OtherCostsContent />;
                break;
            case 'Analytics' :
                content = <AnalyticsContent />;
                break;
            case 'Results' :
                content = <Results />;
                break;
            case 'HouseTreasury' :
                content = <HouseTreasuryContent />;
                break;
            case 'City' :
                content = <CityContent />;
                break;

        }

        var menuWidth = (this.state.menuCollapsed) ? '5%' : '10%';
        var contentWidth = (this.state.menuCollapsed) ? '95%' : '90%';




        var menuOpenerIcon = ((this.state.menuCollapsed) ? menuCloseBlack : menuOpenBlack);

        var menuOpener = "";
        var menu = "";
        if (contentFromParam == null) {

            menuOpener = <div style={{textAlign: 'center', backgroundColor: 'white', height: 42, paddingTop: 10}} onClick={this.hideMenu.bind(this)}><img src={menuOpenerIcon} /></div>;
            menu = <Menu 
                theme={this.props.theme} 
                activeMenuId={this.state.content} 
                setContent={this.setContent.bind(this)} 
                collapsed={this.state.menuCollapsed}
                moduleRolesArray={this.state.moduleRolesArray}
                />;
        } else {
            menuWidth = "0%";
            contentWidth = "100%";
        }


        return (<div>
        {this.navbar()}
        <table style={{minHeight: '90vh', width: '99%'}}>
            <tbody>
                <tr>
                    <td style={{verticalAlign: 'top', width: menuWidth}} >
                        <div >
                            {menuOpener}
                            {menu}
                        </div>
                    </td>
                    <td style={{verticalAlign: 'top', width: contentWidth, textAlign: 'center'}}>
                        {content}        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>);
    }
}

export default Contents;
