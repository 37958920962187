
import React, { Component } from 'react';
import CustomerStateTable from './CustomerStateTable.js';
import CustomerStateInsertForm from './CustomerStateInsertForm.js';
import CustomerStateUpdateForm from './CustomerStateUpdateForm.js';
import CustomerStateViewForm from './CustomerStateViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class CustomerStateContent extends Component {

    constructor(props) {
        super(props);
        this.state={theme:this.props.theme}
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }
    
    setBack  = function(id,value){
        // Nothing to do here
    }
    render() {

        var table = <CustomerStateTable 
                     setBack={this.setBack.bind(this)} 
                     additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                     formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                     theme={this.state.theme}
                    />;
                    
                    
                                
        return (
                <div  id="CustomerStateContent">
                    <div className="tableTitle"><b>{lang.getTitle("CustomerState")}</b></div> 
                
                    {table}
                </div>
                );
    }
}

export default CustomerStateContent;
