import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import DateInsertField from './../../components/DateInsertField';
import NumberInsertField from './../../components/NumberInsertField';

class HourlyWageUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hourlyWage_id: ''
            , hourlyWage_employe_id: ''
            , hourlyWage_wage: ''
            , hourlyWage_wageFull: ''
            , hourlyWage_fromDate: ''
            , hourlyWage_ceratedBy: ''
            , hourlyWage_createdAt: ''

        }


        this.references = [];
        this.references['hourlyWage_id'] = React.createRef();
        this.references['hourlyWage_employe_id'] = React.createRef();
        this.references['hourlyWage_wage'] = React.createRef();
        this.references['hourlyWage_wageFull'] = React.createRef();
        this.references['hourlyWage_fromDate'] = React.createRef();
        this.references['hourlyWage_ceratedBy'] = React.createRef();
        this.references['hourlyWage_createdAt'] = React.createRef();


        this.validators = [];
        this.validators['hourlyWage_id'] = {fieldName: 'hourlyWage_id', dataType: 'INT', value: this.state.hourlyWage_id, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_employe_id'] = {fieldName: 'hourlyWage_employe_id', dataType: 'INT', value: this.state.hourlyWage_employe_id, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_wage'] = {fieldName: 'hourlyWage_wage', dataType: 'DOUBLE', value: this.state.hourlyWage_wage, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_wageFull'] = {fieldName: 'hourlyWage_wageFull', dataType: 'DOUBLE', value: this.state.hourlyWage_wageFull, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_fromDate'] = {fieldName: 'hourlyWage_fromDate', dataType: 'DATE', value: this.state.hourlyWage_fromDate, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_ceratedBy'] = {fieldName: 'hourlyWage_ceratedBy', dataType: 'INT', value: this.state.hourlyWage_ceratedBy, mandatory: false, min: null, max: null};
        this.validators['hourlyWage_createdAt'] = {fieldName: 'hourlyWage_createdAt', dataType: 'TIMESTAMP', value: this.state.hourlyWage_createdAt, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({hourlyWage_id: ''
            , hourlyWage_employe_id: ''
            , hourlyWage_wage: ''
            , hourlyWage_wageFull: ''
            , hourlyWage_fromDate: ''
            , hourlyWage_ceratedBy: ''
            , hourlyWage_createdAt: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('hourlyWage_wage')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'hourlyWage_wage'} 
                    value={this.state.hourlyWage_wage}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['hourlyWage_wage']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('hourlyWage_wageFull')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'hourlyWage_wageFull'} 
                    value={this.state.hourlyWage_wageFull}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['hourlyWage_wageFull']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('hourlyWage_fromDate')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'hourlyWage_fromDate'} 
                    value={this.state.hourlyWage_fromDate}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['hourlyWage_fromDate']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/HourlyWage/hourlyWageFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/HourlyWage/hourlyWageFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} >
            <div className={'popupWrapper' + className} id="HourlyWageUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('HourlyWageUpdate')}</Col>
                        <Col style={{textAlign: 'right', marginRight: 20}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'delete'} onClick={() => this.props.delete()} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('Delete')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default HourlyWageUpdate;

