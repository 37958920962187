import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ComboBoxWorkNumberInsertField from './ComboBoxWorkNumberInsertField';
import DateInsertField from './../../components/DateInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import ComboBoxCustomerBankAccountNumber from './ComboBoxCustomerBankAccountNumber';

class InvoiceUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoice_id: ''
            , invoice_invoiceDirection_id: ''
            , invoice_workNumber_id: ''
            , invoice_dateOfCompletion: ''
            , invoice_invoiceDate: ''
            , invoice_paymentDeadline: ''
            , invoice_paymentDate: ''
            , invoice_companyName: ''
            , invoice_cusomerId: ''
            , invoice_invoiceNumber: ''
            , invoice_VAT_id: ''
            , invoice_VATvalue: ''
            , invoice_priceNET: ''
            , invoice_priceVAT: ''
            , invoice_priceGROSS: ''
            , invoice_moneyTransfer: ''
            , invoice_holdingBack: ''
            , invoice_paidInCash: ''
            , invoice_other: ''
            , invoice_invoiceName: ''
            , invoice_remark: ''
            , invoice_customerBankAccountNumber_id: ''

        }


        this.references = [];
        this.references['invoice_id'] = React.createRef();
        this.references['invoice_invoiceDirection_id'] = React.createRef();
        this.references['invoice_workNumber_id'] = React.createRef();
        this.references['invoice_dateOfCompletion'] = React.createRef();
        this.references['invoice_invoiceDate'] = React.createRef();
        this.references['invoice_paymentDeadline'] = React.createRef();
        this.references['invoice_paymentDate'] = React.createRef();
        this.references['invoice_companyName'] = React.createRef();
        this.references['invoice_customerId'] = React.createRef();
        this.references['invoice_invoiceNumber'] = React.createRef();
        this.references['invoice_VAT_id'] = React.createRef();
        this.references['invoice_VATvalue'] = React.createRef();
        this.references['invoice_priceNET'] = React.createRef();
        this.references['invoice_priceVAT'] = React.createRef();
        this.references['invoice_priceGROSS'] = React.createRef();
        this.references['invoice_holdingBack'] = React.createRef();
        this.references['invoice_moneyTransfer'] = React.createRef();
        this.references['invoice_paidInCash'] = React.createRef();
        this.references['invoice_other'] = React.createRef();
        this.references['invoice_invoiceName'] = React.createRef();
        this.references['invoice_remark'] = React.createRef();
        this.references['invoice_customerBankAccountNumber_id'] = React.createRef();


        this.validators = [];
        this.validators['invoice_id'] = {fieldName: 'invoice_id', dataType: 'INT', value: this.state.invoice_id, mandatory: false, min: null, max: null};
        this.validators['invoice_invoiceDirection_id'] = {fieldName: 'invoice_invoiceDirection_id', dataType: 'INT', value: this.state.invoice_invoiceDirection_id, mandatory: false, min: null, max: null};
        this.validators['invoice_workNumber_id'] = {fieldName: 'invoice_workNumber_id', dataType: 'INT', value: this.state.invoice_workNumber_id, mandatory: true, min: null, max: null};
        this.validators['invoice_dateOfCompletion'] = {fieldName: 'invoice_dateOfCompletion', dataType: 'DATE', value: this.state.invoice_dateOfCompletion, mandatory: true, min: null, max: null};
        this.validators['invoice_invoiceDate'] = {fieldName: 'invoice_invoiceDate', dataType: 'DATE', value: this.state.invoice_invoiceDate, mandatory: true, min: null, max: null};
        this.validators['invoice_paymentDeadline'] = {fieldName: 'invoice_paymentDeadline', dataType: 'DATE', value: this.state.invoice_paymentDeadline, mandatory: true, min: null, max: null};
        this.validators['invoice_paymentDate'] = {fieldName: 'invoice_paymentDate', dataType: 'DATE', value: this.state.invoice_paymentDate, mandatory: false, min: null, max: null};
        this.validators['invoice_customerId'] = {fieldName: 'invoice_customerId', dataType: 'INT', value: this.state.invoice_customerId, mandatory: true, min: null, max: null};
        this.validators['invoice_companyName'] = {fieldName: 'invoice_companyName', dataType: 'VARCHAR', value: this.state.invoice_companyName, mandatory: false, min: null, max: null};
        this.validators['invoice_invoiceNumber'] = {fieldName: 'invoice_invoiceNumber', dataType: 'VARCHAR', value: this.state.invoice_invoiceNumber, mandatory: true, min: null, max: null};
        this.validators['invoice_VAT_id'] = {fieldName: 'invoice_VAT_id', dataType: 'INT', value: this.state.invoice_VAT_id, mandatory: true, min: null, max: null};
        this.validators['invoice_VATvalue'] = {fieldName: 'invoice_VATvalue', dataType: 'DOUBLE', value: this.state.invoice_VATvalue, mandatory: false, min: null, max: null};
        this.validators['invoice_priceNET'] = {fieldName: 'invoice_priceNET', dataType: 'DOUBLE', value: this.state.invoice_priceNET, mandatory: false, min: null, max: null};
        this.validators['invoice_priceVAT'] = {fieldName: 'invoice_priceVAT', dataType: 'DOUBLE', value: this.state.invoice_priceVAT, mandatory: false, min: null, max: null};
        this.validators['invoice_priceGROSS'] = {fieldName: 'invoice_priceGROSS', dataType: 'DOUBLE', value: this.state.invoice_priceGROSS, mandatory: false, min: null, max: null};
        this.validators['invoice_holdingBack'] = {fieldName: 'invoice_holdingBack', dataType: 'DOUBLE', value: this.state.invoice_holdingBack, mandatory: false, min: null, max: null};
        this.validators['invoice_moneyTransfer'] = {fieldName: 'invoice_moneyTransfer', dataType: 'DOUBLE', value: this.state.invoice_moneyTransfer, mandatory: false, min: null, max: null};
        this.validators['invoice_paidInCash'] = {fieldName: 'invoice_paidInCash', dataType: 'TINYINT', value: this.state.invoice_paidInCash, mandatory: false, min: null, max: null};
        this.validators['invoice_other'] = {fieldName: 'invoice_other', dataType: 'VARCHAR', value: this.state.invoice_other, mandatory: false, min: null, max: null};
        this.validators['invoice_invoiceName'] = {fieldName: 'invoice_invoiceName', dataType: 'VARCHAR', value: this.state.invoice_invoiceName, mandatory: false, min: null, max: null};
        this.validators['invoice_remark'] = {fieldName: 'invoice_remark', dataType: 'VARCHAR', value: this.state.invoice_remark, mandatory: false, min: null, max: null};
        this.validators['invoice_customerBankAccountNumber_id'] = {fieldName: 'invoice_customerBankAccountNumber_id', dataType: 'INT', value: this.state.invoice_customerBankAccountNumber_id, mandatory: false, min: null, max: null};

        this.error = false;
        this.msg = "";

        this.taxLoaded = true;
        this.taxIdAndValues = null;
        this.preloadVAT();
        this.load();


    }

    componentDidMount() {
        //this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    preloadVAT = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Invoice/vatPreloader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.taxLoaded = true;
                            this.taxIdAndValues = response.data;
                            this.load();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    reset = function () {

        this.setState({invoice_id: ''
            , invoice_invoiceDirection_id: ''
            , invoice_workNumber_id: ''
            , invoice_dateOfCompletion: ''
            , invoice_invoiceDate: ''
            , invoice_paymentDeadline: ''
            , invoice_paymentDate: ''
            , invoice_customerId: ''
            , invoice_companyName: ''
            , invoice_invoiceNumber: ''
            , invoice_VAT_id: ''
            , invoice_VATvalue: ''
            , invoice_priceNET: ''
            , invoice_priceVAT: ''
            , invoice_priceGROSS: ''
            , invoice_holdingBack: ''
            , invoice_moneyTransfer: ''
            , invoice_paidInCash: ''
            , invoice_remark: ''
            , invoice_customerBankAccountNumber_id: ''
        });
    }

    getVATValueById = function (id) {
        for (var i = 0; i < this.taxIdAndValues.length; i++) {
            var vat = this.taxIdAndValues[i];
            if (vat.id == id) {
                return vat.vatValue;
            }
        }
        return null;
    }

    setBack = function (id, value) {

        if (id == 'invoice_dateOfCompletion' && this.state.invoice_paidInCash == 1) {

            this.setState({
                invoice_dateOfCompletion: value
                , invoice_invoiceDate: value
                , invoice_paymentDeadline: value
                , invoice_paymentDate: value
            });
        }

        if (id == 'invoice_VAT_id') {
            this.state.invoice_VATvalue = this.getVATValueById(value);
            this.state.invoice_priceVAT = Math.round((this.state.invoice_priceNET / 100) * this.state.invoice_VATvalue);
            this.state.invoice_priceGROSS = Math.round((this.state.invoice_priceNET * 1.0) + ((this.state.invoice_priceNET / 100) * this.state.invoice_VATvalue));
        }

        if (id == "invoice_priceNET") {
            this.state.invoice_priceNET = value;
            this.state.invoice_priceVAT = Math.round((this.state.invoice_priceNET / 100) * this.state.invoice_VATvalue);
            this.state.invoice_priceGROSS = Math.round((this.state.invoice_priceNET * 1.0) + ((this.state.invoice_priceNET / 100) * this.state.invoice_VATvalue));
        }
        if (id == "invoice_priceGROSS") {

            this.state.invoice_priceGROSS = value;
            var VATbase = this.getVATValueById(this.state.invoice_VAT_id);
            var grossVAT = 100 + VATbase;

            var onePercent = value / grossVAT;
            var priceNET = onePercent * 100.0;
            this.state.invoice_priceNET = Math.round(priceNET);
            this.state.invoice_priceVAT = Math.round((this.state.invoice_priceNET / 100) * this.state.invoice_VATvalue);
        }
        this.setState({[id]: value});
    }

    getForm = function () {

        if (this.taxLoaded == false) {
            return "";
        }
        return <Container>
            <Row style={{
                            borderBottom: 'solid 1px #00000044',
                            borderTop: 'solid 1px #00000044',
                            borderLeft: 'solid 1px #00000044',
                            borderRight: 'solid 1px #00000044',
                            paddingBottom: 20,
                            marginTop: 20,

                             }}>
                <Col style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                    {lang.getTitle('invoice_paidInCash')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <CheckBoxInsertField 
                        id={'invoice_paidInCash'} 
                        value={this.state.invoice_paidInCash}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_paidInCash']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                    {lang.getTitle('invoice_dateOfCompletion')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <DateInsertField 
                        id={'invoice_dateOfCompletion'} 
                        value={this.state.invoice_dateOfCompletion}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_dateOfCompletion']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col style={{textAlign: 'center'}}>
                <Row>
                    <Col>
        
                    {lang.getTitle('invoice_invoiceDate')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <DateInsertField 
                        id={'invoice_invoiceDate'} 
                        value={this.state.invoice_invoiceDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_invoiceDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                    {lang.getTitle('invoice_paymentDeadline')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <DateInsertField 
                        id={'invoice_paymentDeadline'} 
                        value={this.state.invoice_paymentDeadline}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_paymentDeadline']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
        
        
        
                <Col style={{textAlign: 'center'}}>
                <Row>
                    <Col>
                    {lang.getTitle('invoice_paymentDate')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <DateInsertField 
                        id={'invoice_paymentDate'} 
                        value={this.state.invoice_paymentDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_paymentDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
        
            <Row>
                <Col style={{border: 'solid 1px #00000044', margin: 10, padding: 10}}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_customerId')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'invoice_customerId'} 
                        value={this.state.invoice_customerId}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Invoice/customerIdFormComboInsertData.php'}
                        reference={this.references['invoice_customerId']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_invoiceNumber')}</Col>
                    <Col style={{textAlign: 'right'}}>
        
                    <TextInsertField 
                        id={'invoice_invoiceNumber'} 
                        value={this.state.invoice_invoiceNumber}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_invoiceNumber']}
                        editable={true}
        
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_workNumber_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxWorkNumberInsertField 
                        id={'invoice_workNumber_id'} 
                        value={this.state.invoice_workNumber_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Invoice/workNumberListCombo.php'}
                        reference={this.references['invoice_workNumber_id']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
        
        
        
        
                </Col>
                <Col style={{border: 'solid 1px #00000044', margin: 10, padding: 10}}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_VAT_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'invoice_VAT_id'} 
                        value={this.state.invoice_VAT_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Invoice/VAT_idFormComboInsertData.php'}
                        reference={this.references['invoice_VAT_id']}
                        editable={true}
                        theme={this.props.theme}
                        style={{textAlign: 'right'}}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_priceNET')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'invoice_priceNET'} 
                        value={this.state.invoice_priceNET}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_priceNET']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_priceVAT')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'invoice_priceVAT'} 
                        value={this.state.invoice_priceVAT }  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_priceVAT']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_priceGROSS')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'invoice_priceGROSS'} 
                        value={this.state.invoice_priceGROSS}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_priceGROSS']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_holdingBack')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'invoice_holdingBack'} 
                        value={this.state.invoice_holdingBack}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_holdingBack']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_moneyTransfer')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'invoice_moneyTransfer'} 
                        value={this.state.invoice_moneyTransfer}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['invoice_moneyTransfer']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_customerBankAccountNumber_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxCustomerBankAccountNumber
                    id={'invoice_customerBankAccountNumber_id'} 
                    value={this.state.invoice_customerBankAccountNumber_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Invoice/customerBankAccountNumber_idFormComboInsertData.php'}
                    reference={this.references['invoice_customerBankAccountNumber_id']}
                    editable={true}
                    theme={this.props.theme}
                    customerId={this.state.invoice_customerId} 
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('invoice_remark')}</Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'right'}}>
                <TextAreaInsertField 
                    id={'invoice_remark'} 
                    value={this.state.invoice_remark}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['invoice_remark']}
                    editable={true}   
                    theme={this.props.theme}
                    style={{minWidth: '100%'}}
                    />
                </Col>
            </Row>
        
        
        
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {

        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                if (property != 'invoice_paidInCash') {
                    this.state[property] = additionalParam[property];
                }
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();



        try {
            const url = ajax.getUrlBase() + "modules/Invoice/invoiceFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            if (typeof self.props.setBackIncomingInvoiceId != "undefined") {

                                self.props.setBackIncomingInvoiceId(
                                        response.data.lastInsertId,
                                        response.data.workplace_customerAddressName,
                                        response.data.workNumberId,
                                        response.data.invoice_priceGROSS,
                                        );
                            }
                            self.transactionSuccess();
                            self.closeForm();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Invoice/invoiceFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        if (this.taxLoaded == false) {
            return "";
        }
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1000}}>
            <div className={'popupWrapper' + className} id="InvoiceUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('InvoiceUpdate')}&nbsp;(Bejövő)</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'delete'} onClick={() => {
                                        this.props.delete()
                                            }} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('Törlés')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        
        </PopUpWindow>);
    }
}

export default InvoiceUpdate;

