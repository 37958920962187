import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';
import TransactionNameFilterCombo from './TransactionNameFilterCombo';
import TextInsertField from './../../components/TextInsertField';
import DateInsertField from './../../components/DateInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import NumberInsertField from './../../components/NumberInsertField';

import InvoiceIncomingInsert from './../InvoiceIncoming/InvoiceInsertForm';
import InvoiceIncomingUpdate from './../InvoiceIncoming/InvoiceUpdateForm';
import InvoiceOutgoingInsert from './../InvoiceOutgoing//InvoiceInsertForm';
import InvoiceOutgoingUpdate from './../InvoiceOutgoing//InvoiceUpdateForm';



class HouseTreasuryUpdate extends Component {

    constructor(props) {
        super(props);


        this.INCOMING_FORM_INSERT = "INCOMING_INSERT";
        this.INCOMING_FORM_UPDATE = "INCOMING_UPDATE";
        this.INCOMING_FORM_HIDDEN = "INCOMING_HIDDEN";

        this.OUTGOING_FORM_INSERT = "OUTGOING_INSERT";
        this.OUTGOING_FORM_UPDATE = "OUTGOING_UPDATE";
        this.OUTGOING_FORM_HIDDEN = "OUTGOING_HIDDEN";

        this.state = {
            houseTreasury_id: ''
            , houseTreasury_inDate: ''
            , houseTreasury_inName: ''
            , houseTreasury_inPlace: ''
            , houseTreasury_inWorkNumber_id: ''
            , houseTreasury_inAmount: ''
            , houseTreasury_outDate: ''
            , houseTreasury_outName: ''
            , houseTreasury_outPlace: ''
            , houseTreasury_outWorkNumber_id: ''
            , houseTreasury_outAmount: ''
            , houseTreasury_inSum: ''
            , houseTreasury_outSum: ''
            , houseTreasury_incoming_invoice_id: ''
            , houseTreasury_outgoing_invoice_id: ''
            , houseTreasury_addToResultsOutgoing: false
            , houseTreasury_addToResultsIncoming: false
        }


        this.references = [];
        this.references['houseTreasury_outDate'] = React.createRef();
        this.references['houseTreasury_outName'] = React.createRef();
        this.references['houseTreasury_outPlace'] = React.createRef();
        this.references['houseTreasury_outWorkNumber_id'] = React.createRef();
        this.references['houseTreasury_outAmount'] = React.createRef();
        this.references['houseTreasury_id'] = React.createRef();
        this.references['houseTreasury_inDate'] = React.createRef();
        this.references['houseTreasury_inName'] = React.createRef();
        this.references['houseTreasury_inPlace'] = React.createRef();
        this.references['houseTreasury_inWorkNumber_id'] = React.createRef();
        this.references['houseTreasury_inAmount'] = React.createRef();

        this.references['houseTreasury_inSum'] = React.createRef();
        this.references['houseTreasury_outSum'] = React.createRef();
        this.references['houseTreasury_grossSum'] = React.createRef();
        this.references['houseTreasury_incoming_invoice_id'] = React.createRef();
        this.references['houseTreasury_outgoing_invoice_id'] = React.createRef();
        this.references['houseTreasury_addToResultsIncoming'] = React.createRef();
        this.references['houseTreasury_addToResultsOutgoing'] = React.createRef();
        this.validators = [];

        this.validators['houseTreasury_id'] = {fieldName: 'houseTreasury_id', dataType: 'INT', value: this.state.houseTreasury_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outDate'] = {fieldName: 'houseTreasury_outDate', dataType: 'DATE', value: this.state.houseTreasury_outDate, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outName'] = {fieldName: 'houseTreasury_outName', dataType: 'VARCHAR', value: this.state.houseTreasury_outName, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outPlace'] = {fieldName: 'houseTreasury_outPlace', dataType: 'VARCHAR', value: this.state.houseTreasury_outPlace, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outWorkNumber_id'] = {fieldName: 'houseTreasury_outWorkNumber_id', dataType: 'INT', value: this.state.houseTreasury_outWorkNumber_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outAmount'] = {fieldName: 'houseTreasury_outAmount', dataType: 'DOUBLE', value: this.state.houseTreasury_outAmount, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inSum'] = {fieldName: 'houseTreasury_inSum', dataType: 'DOUBLE', value: this.state.houseTreasury_inSum, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inDate'] = {fieldName: 'houseTreasury_inDate', dataType: 'DATE', value: this.state.houseTreasury_inDate, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inName'] = {fieldName: 'houseTreasury_inName', dataType: 'VARCHAR', value: this.state.houseTreasury_inName, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inPlace'] = {fieldName: 'houseTreasury_inPlace', dataType: 'VARCHAR', value: this.state.houseTreasury_inPlace, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inWorkNumber_id'] = {fieldName: 'houseTreasury_inWorkNumber_id', dataType: 'INT', value: this.state.houseTreasury_inWorkNumber_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_inAmount'] = {fieldName: 'houseTreasury_inAmount', dataType: 'DOUBLE', value: this.state.houseTreasury_inAmount, mandatory: false, min: null, max: null};

        this.validators['houseTreasury_outSum'] = {fieldName: 'houseTreasury_outSum', dataType: 'DOUBLE', value: this.state.houseTreasury_outSum, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_grossSum'] = {fieldName: 'houseTreasury_grossSum', dataType: 'DOUBLE', value: this.state.houseTreasury_grossSum, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_incoming_invoice_id'] = {fieldName: 'houseTreasury_incoming_invoice_id', dataType: 'DOUBLE', value: this.state.houseTreasury_incoming_invoice_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_outgoing_invoice_id'] = {fieldName: 'houseTreasury_outgoing_invoice_id', dataType: 'DOUBLE', value: this.state.houseTreasury_outgoing_invoice_id, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_addToResultsOutgoing'] = {fieldName: 'houseTreasury_addToResultsOutgoing', dataType: 'BOOLEAN', value: this.state.houseTreasury_addToResultsOutgoing, mandatory: false, min: null, max: null};
        this.validators['houseTreasury_addToResultsIncoming'] = {fieldName: 'houseTreasury_addToResultsIncoming', dataType: 'DOUBLE', value: this.state.houseTreasury_addToResultsIncoming, mandatory: false, min: null, max: null};
        this.error = false;
        this.msg = "";
        this.load();

        this.showIncomingInvoice = this.INCOMING_FORM_HIDDEN;
        this.showOutgoingInvoice = this.OUTGOING_FORM_HIDDEN;
    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];
                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({houseTreasury_id: ''
            , houseTreasury_inDate: ''
            , houseTreasury_inName: ''
            , houseTreasury_inPlace: ''
            , houseTreasury_inWorkNumber_id: ''
            , houseTreasury_inAmount: ''
            , houseTreasury_outDate: ''
            , houseTreasury_outName: ''
            , houseTreasury_outPlace: ''
            , houseTreasury_outWorkNumber_id: ''
            , houseTreasury_outAmount: ''
            , houseTreasury_inSum: ''
            , houseTreasury_outSum: ''
            , houseTreasury_grossSum: ''
            , houseTreasury_incoming_invoice_id: ''
            , houseTreasury_outgoing_invoice_id: ''
            , houseTreasury_addToResultsOutgoing: false
            , houseTreasury_addToResultsIncoming: false

        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    insertIncomingInvoice = function () {
        this.showIncomingInvoice = this.INCOMING_FORM_INSERT;
        this.forceUpdate();
    }

    updateIncomingInvoice = function () {
        this.showIncomingInvoice = this.INCOMING_FORM_UPDATE;
        this.forceUpdate();
    }

    closeIncomingInvoiceForm = function () {
        this.showIncomingInvoice = this.INCOMING_FORM_HIDDEN;
        this.forceUpdate();
    }

    insertOutgoingInvoice = function () {
        this.showOutgoingInvoice = this.OUTGOING_FORM_INSERT;
        this.forceUpdate();
    }

    updateOutgoingInvoice = function () {
        this.showOutgoingInvoice = this.OUTGOING_FORM_UPDATE;
        this.forceUpdate();
    }

    closeOutgoingInvoiceForm = function () {
        this.showOutgoingInvoice = this.OUTGOING_FORM_HIDDEN;
        this.forceUpdate();
    }

    getForm = function () {

        var invoiceButtonIncoming = "";
        if (this.state.houseTreasury_incoming_invoice_id == null || this.state.houseTreasury_incoming_invoice_id == '') {
            invoiceButtonIncoming = <button onClick={this.insertIncomingInvoice.bind(this)}>&nbsp;Felvitel</button>
        } else {
            invoiceButtonIncoming = <button onClick={this.updateIncomingInvoice.bind(this)}>&nbsp;Módosítás</button>
        }
        var invoiceButtonOutgoing = "";
        if (this.state.houseTreasury_outgoing_invoice_id == null || this.state.houseTreasury_outgoing_invoice_id == '') {
            invoiceButtonOutgoing = <button onClick={this.insertOutgoingInvoice.bind(this)}>&nbsp;Felvitel</button>
        } else {
            invoiceButtonOutgoing = <button onClick={this.updateOutgoingInvoice.bind(this)}>&nbsp;Módosítás</button>
        }

        return <Container>
        
            <Row>
                <Col style={{border: 'solid 1px #00000044', margin: 5, padding: 5}}>
                <Row>
                    <Col style={{textAlign: 'center'}}>KIADÁS</Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('Számla')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    {invoiceButtonIncoming}
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outDate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <DateInsertField 
                        id={'houseTreasury_outDate'} 
                        value={this.state.houseTreasury_outDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outName')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TransactionNameFilterCombo
                        id={'houseTreasury_outName'} 
                        value={this.state.houseTreasury_outName}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/houseTreasuryTransactionName.php'}
                        reference={this.references['houseTreasury_outName']}
                        editable={true}
                        theme={this.props.theme}
                        />
        
        
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outPlace')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_outPlace'} 
                        value={this.state.houseTreasury_outPlace}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outPlace']}
                        editable={this.state.houseTreasury_incoming_invoice_id == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outWorkNumber_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'houseTreasury_outWorkNumber_id'} 
                        value={this.state.houseTreasury_outWorkNumber_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/outWorkNumber_idFormComboInsertData.php'}
                        reference={this.references['houseTreasury_outWorkNumber_id']}
                        editable={this.state.houseTreasury_incoming_invoice_id == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_outAmount')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'houseTreasury_outAmount'} 
                        value={this.state.houseTreasury_outAmount}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_outAmount']}
                        editable={this.state.houseTreasury_incoming_invoice_id == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left', marginLeft: 5}}>
                    <CheckBoxInsertField 
                        id={'houseTreasury_addToResultsOutgoing'} 
                        value={this.state.houseTreasury_addToResultsOutgoing}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_addToResultsOutgoing']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
        
                <Col style={{border: 'solid 1px #00000044', margin: 5, padding: 5}}>
                <Row>
                    <Col style={{textAlign: 'center'}}>BEVÉTEL</Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('Számla')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    {invoiceButtonOutgoing}
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inDate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <DateInsertField 
                        id={'houseTreasury_inDate'} 
                        value={this.state.houseTreasury_inDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inName')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TransactionNameFilterCombo
                        id={'houseTreasury_inName'} 
                        value={this.state.houseTreasury_inName}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/houseTreasuryTransactionName.php'}
                        reference={this.references['houseTreasury_inName']}
                        editable={this.state.houseTreasury_inName == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inPlace')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextInsertField 
                        id={'houseTreasury_inPlace'} 
                        value={this.state.houseTreasury_inPlace}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inPlace']}
                        editable={this.state.houseTreasury_outgoing_invoice_id == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inWorkNumber_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'houseTreasury_inWorkNumber_id'} 
                        value={this.state.houseTreasury_inWorkNumber_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/HouseTreasury/inWorkNumber_idFormComboInsertData.php'}
                        reference={this.references['houseTreasury_inWorkNumber_id']}
                        editable={this.state.houseTreasury_outgoing_invoice_id == null}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('houseTreasury_inAmount')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'houseTreasury_inAmount'} 
                        value={this.state.houseTreasury_inAmount}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_inAmount']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left', marginLeft: 5}}>
                    <CheckBoxInsertField 
                        id={'houseTreasury_addToResultsIncoming'} 
                        value={this.state.houseTreasury_addToResultsIncoming}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['houseTreasury_addToResultsIncoming']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
                </Col>
        
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    setBackOutgoingInvoiceId(invoiceId, workplace_customerAddressName, workNumberId, invoice_priceGROSS) {
        this.setState({
            houseTreasury_outgoing_invoice_id: invoiceId,
            houseTreasury_inPlace: workplace_customerAddressName,
            houseTreasury_inWorkNumber_id: workNumberId,
            houseTreasury_inAmount: invoice_priceGROSS
        });
    }

    setBackIncomingInvoiceId(invoiceId, workplace_customerAddressName, workNumberId, invoice_priceGROSS) {
        this.setState({
            houseTreasury_incoming_invoice_id: invoiceId,
            houseTreasury_outPlace: workplace_customerAddressName,
            houseTreasury_outWorkNumber_id: workNumberId,
            houseTreasury_outAmount: invoice_priceGROSS
        });
    }
    invoiceOutgoingDelete = function () {
        alert("Házipénztár tételhez tartozó számlát nem törölhet. ");
    }
    invoiceIncomingDelete = function () {
        alert("Házipénztár tételhez tartozó számlát nem törölhet. ");
    }

    update = function (e) {
        this.state.houseTreasury_outName=this.references['houseTreasury_outName'].current.value;
        this.state.houseTreasury_inName=this.references['houseTreasury_inName'].current.value;
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;
        this.concatAdditionalParams();
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryFormUpdate.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            self.closeForm();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/HouseTreasury/houseTreasuryFormLoader.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {

        var invoiceForm = "";
        if (this.showIncomingInvoice == this.INCOMING_FORM_INSERT) {
            invoiceForm = <InvoiceIncomingInsert  setBackIncomingInvoiceId={this.setBackIncomingInvoiceId.bind(this)} theme={this.props.theme} key={'invoice_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[{invoice_invoiceDirection_id: 1}]} closeForm={this.closeIncomingInvoiceForm.bind(this)} />;
        }
        if (this.showOutgoingInvoice == this.OUTGOING_FORM_INSERT) {
            invoiceForm = <InvoiceOutgoingInsert  setBackOutgoingInvoiceId={this.setBackOutgoingInvoiceId.bind(this)} theme={this.props.theme} key={'invoice_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[{invoice_invoiceDirection_id: 2}]} closeForm={this.closeOutgoingInvoiceForm.bind(this)} />;
        }
        if (this.showIncomingInvoice == this.INCOMING_FORM_UPDATE) {
            invoiceForm = <InvoiceIncomingUpdate  
                theme={this.props.theme} 
                key={'invoice_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                additionalParams={[]} 
                closeForm={this.closeIncomingInvoiceForm.bind(this)} 
                id={this.state.houseTreasury_incoming_invoice_id}
                delete={this.invoiceIncomingDelete.bind(this)}
                setBackIncomingInvoiceId={this.setBackIncomingInvoiceId.bind(this)}
                />;
        }

        if (this.showOutgoingInvoice == this.OUTGOING_FORM_UPDATE) {
            invoiceForm = <InvoiceOutgoingUpdate  
                theme={this.props.theme} 
                key={'invoice_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                additionalParams={[]} 
                closeForm={this.closeOutgoingInvoiceForm.bind(this)} 
                id={this.state.houseTreasury_outgoing_invoice_id}
                delete={this.invoiceOutgoingDelete.bind(this)}
                setBackOutgoingInvoiceId={this.setBackOutgoingInvoiceId.bind(this)}
                />;
        }


        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="HouseTreasuryUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('HouseTreasuryUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'delete'} onClick={() => this.props.delete()} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('Delete')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
                {invoiceForm}
            </div>
        </PopUpWindow>);
    }
}

export default HouseTreasuryUpdate;

