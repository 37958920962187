
import React, { Component } from 'react';
import ChequeTable from './ChequeTable.js';
import ChequeInsertForm from './ChequeInsertForm.js';
import ChequeUpdateForm from './ChequeUpdateForm.js';
import ChequeViewForm from './ChequeViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class ChequeContent extends Component {

    constructor(props) {
        super(props);
        this.state={theme:this.props.theme}
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }
    
    setBack  = function(id,value){
        // Nothing to do here
    }
    render() {

        var table = <ChequeTable 
                     setBack={this.setBack.bind(this)} 
                     additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                     formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                     theme={this.state.theme}
                    />;
                    
        var className = (this.props.theme=="true")?"Dark":"Light";            
        var headerTextColor=(this.props.theme=="true")?"wheat":"grey";                        
        return (
               <div  id="ChequeContent">
                    <div className="tableTitle"><b>{lang.getTitle("Cheque")}</b></div> 
                
                    {table}
                </div>
                );
    }
}

export default ChequeContent;
