import React, { Component } from 'react';

import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './PagingToolbar';
import ChequeFilter from './ChequeFilter';


import {Button, Form, Row, Col} from 'react-bootstrap';

import InsertForm from './ChequeInsertForm.js';
import UpdateForm from './ChequeUpdateForm.js';
import ViewForm from './ChequeViewForm.js';
import Dialog from './../../components/Dialog.js';

import FormatedNumberCell from './../../components/FormatedNumberCell';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class ChequeTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'cheque_id',
            'chequeIssuer_issuerName',
            'cheque_customer_id',
            'customer_customerName',
            'cheque_workNumber_id',
            'customerBankAccountNumber_bankAccountNumber',
            'workNumber_sequenceNumber',
            'cheque_customerAddress',
            'cheque_grossValue',
            'cheque_dateOfArrival',
            'cheque_remark',
            'cheque_paymentDate',
            'cheque_transferable'
        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "Cheque", "cheque");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 1000000,
                showingRowFrom: 0,
                showingRowTo: 1000000,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.chequeSum = 0.0;
        this.transferableSum = 0.0;
        this.isPayedCheque = 0;

        this.cheque_customer = '';
        this.cheque_date_from = '';
        this.cheque_date_to = '';
        this.cheque_workNumber = '';


    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar 
            blackTheme={this.props.theme} 
            tableReload={this.reload.bind(this)} 
            paging={this.state.paging} 
            chequeSum={this.chequeSum}
            transferableSum={this.transferableSum}
            />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;

        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }

        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    removeEmptyWorkNumbers = function (originalArray) {
        if (typeof originalArray == 'undefined') {
            return [];
        }
        if (originalArray == null) {
            return [];
        }
        if (originalArray.length == 0) {
            return [];
        }

        var validArray = [];

        for (var i = 0; i < originalArray.length; i++) {
            var originalItem = originalArray[i];
            var validValue = (originalItem != null && originalItem != '' && originalItem != -1) ? true : false;
            if (validValue) {
                validArray.push(originalItem);
            }
        }
        return validArray;

    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        var self = this;
        //IsPayedChequeFilter

        this.props.additionalParams.push({IsPayedChequeFilter: this.isPayedCheque});

        filterValue = this.addForeignKeyFilter(filterValue);




        // Külső dátum filter

        if (this.cheque_date_from != '' && this.cheque_date_to != '') {
            this.setFilterValue(filterValue, 'inrange', 'cheque_paymentDate', {start: this.cheque_date_from, end: this.cheque_date_to});
        } else {
            this.setFilterValue(filterValue, 'eq', 'cheque_paymentDate', '');
        }

        // Ügyfél filter
        if (this.cheque_customer != '') {
            this.setFilterValue(filterValue, 'eq', 'cheque_customer_id', this.cheque_customer);
        } else {
            this.setFilterValue(filterValue, 'eq', 'cheque_customer_id', '');
        }


        // Munkaszám filter
        if (this.cheque_workNumber != '') {
            var validValues = this.removeEmptyWorkNumbers(this.cheque_workNumber);
            this.setFilterValue(filterValue, 'inlist', 'cheque_workNumber_id', validValues);
        } else {
            this.setFilterValue(filterValue, 'inlist', 'cheque_workNumber_id', '');
        }




        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Cheque/chequeTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.extra != 'undefined') {
                            self.chequeSum = response.extra['chequeSum'];
                            self.transferableSum = response.extra['transferableSum'];
                        }

                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Cheque/chequeFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({cheque_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.cheque_id == 'undefined') {
            return false;
        }

        if (rowProps.data.cheque_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.cheque_id, openedForm: this.FORM_UPDATE});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    setIsPayedChequeFilter = function (e) {
        this.isPayedCheque = e.target.value;
        this.reload();
    }

    setHeaderFilterValues = function (filterState) {

        this.cheque_date_from = filterState.cheque_date_from;
        this.cheque_date_to = filterState.cheque_date_to;
        this.cheque_workNumber = filterState.cheque_workNumber;
        this.cheque_customer = filterState.cheque_customer;


        this.reload();
    }

    setFilterValue = function (filters, operator, attr, value) {
        for (var i = 0; i < filters.length; i++) {
            if (filters[i].name == attr) {
                filters[i].operator = operator;
                filters[i].value = value;
            }
        }
    }

    getFilter = function () {
        if (this.showFilter == false) {
            return "";
        }
        return <Col style={{maxWidth: 260, minWidth: 260}}>
    <ChequeFilter  
        reload={this.reload.bind(this)}  
        setHeaderFilterValues={this.setHeaderFilterValues.bind(this)} 
        />
    </Col>
    }

    render() {

        var columns = [
            {name: 'cheque_id', sortable: true, header: lang.getTitle('cheque_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'chequeIssuer_issuerName', sortable: true, header: lang.getTitle('chequeIssuer_issuerName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('chequeIssuer_issuerName', 150), defaultVisible: true}
            , {name: 'cheque_customer_id', sortable: false, header: lang.getTitle('Ügyfél'), filterDelay: 1000, flex: 1, defaultVisible: false}
            , {name: 'customer_customerName', sortable: true, header: lang.getTitle('Ügyfél'), filterDelay: 1000, defaultWidth:this.columnStateHandler.getColumnWidth('customer_customerName', 150), defaultVisible: true}
            , {name: 'cheque_workNumber_id', sortable: true, header: lang.getTitle('workNumber_sequenceNumber'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_workNumber_id', 150), defaultVisible: false}
            , {name: 'customerBankAccountNumber_bankAccountNumber', sortable: true, header: lang.getTitle('customerBankAccountNumber_bankAccountNumber'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('customerBankAccountNumber_bankAccountNumber', 150), defaultVisible: true}
            , {name: 'workNumber_sequenceNumber', sortable: true, header: lang.getTitle('workNumber_sequenceNumber'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('workNumber_sequenceNumber', 150), defaultVisible: true}
            , {name: 'cheque_customerAddress', sortable: true, header: lang.getTitle('cheque_customerAddress'), filterDelay: 1000, defaultWidth:this.columnStateHandler.getColumnWidth('cheque_customerAddress', 150), defaultVisible: true}
            , {name: 'cheque_grossValue', sortable: true, header: lang.getTitle('cheque_grossValue'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_grossValue', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />;
                }
            }
            , {name: 'cheque_dateOfArrival', sortable: true, header: lang.getTitle('cheque_dateOfArrival'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_dateOfArrival', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {

                    return (typeof value != 'undefined' && value != null && value != '') ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'cheque_remark', sortable: true, header: lang.getTitle('cheque_remark'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_remark', 150), defaultVisible: true}
            , {name: 'cheque_paymentDate', sortable: true, header: lang.getTitle('cheque_paymentDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_paymentDate', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (typeof value != 'undefined' && value != null && value != '') ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'cheque_transferable', sortable: true, header: lang.getTitle('cheque_transferable'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('cheque_transferable', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />;
                }

            }


        ];

        const filters = [
            {name: 'cheque_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'cheque_customer_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'customer_customerName', operator: 'eq', type: 'string', value: ''}
            , {name: 'cheque_workNumber_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'customerBankAccountNumber_bankAccountNumber', operator: 'eq', type: 'string', value: ''}
            , {name: 'workNumber_sequenceNumber', operator: 'eq', type: 'string', value: ''}
            , {name: 'cheque_customerAddress', operator: 'eq', type: 'string', value: ''}
            , {name: 'cheque_grossValue', operator: 'eq', type: 'number', value: ''}
            , {name: 'cheque_dateOfArrival', operator: 'eq', type: 'date', value: ''}
            , {name: 'cheque_remark', operator: 'contains', type: 'string', value: ''}
            , {name: 'cheque_paymentDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'cheque_transferable', operator: 'eq', type: 'number', value: ''}
            , {name: 'chequeIssuer_issuerName', operator: 'eq', type: 'string', value: ''}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);


        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'cheque_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm    theme={this.props.theme} key={'cheque_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm delete={this.delete.bind(this)} theme={this.props.theme} key={'cheque_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
            type={this.DIALOG_ALERT} 
            dialogText={this.state.dialogText} 
            callback={() => this.setView({target: {id: this.NONE}})}
            theme={this.props.theme}
            />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'cheque_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'cheque_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'cheque_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'cheque_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;

        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [insertButton];
        }



        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div className={'buttonBar'} id="CalendarTable">
                    <div style={{display: "flex"}}>
                        <div style={{minWidth: 20, display: 'contents', maxHeight: 22}}>
                            <div>
                                {buttons}
                            </div>
                            <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
                                <Form.Select onChange={this.setIsPayedChequeFilter.bind(this)} size="sm">
                                    <option value="0">Összes</option>
                                    <option value="1">Fizetve</option>
                                    <option value="2">Fizetetlen</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div>
                            <div style={{float: 'left', width: 300}}>{this.getFilter()}</div>
                        </div>
                        <div style={{display: 'contents'}}>
                            <ReactDataGrid 
                                idProperty="cheque_id" 
                                i18n={ajax.getI18n()}
                                columns={this.columnStateHandler.myColumnsReorder(columns)}
                                dataSource={this.dataLoader.bind(this)}
                                defaultFilterValue={filters}
                                enableSelection={true}
                
                                onRowClick={this.onRowClick.bind(this)}
                                onSelectionChange={this.onSelectionChange.bind(this)}
                                theme={theme}
                                style={{height: '80vh', marginTop: 10}}    
                                rowHeight={25}
                                defaultLimit={1000000}
                                pagination={true}
                                renderPaginationToolbar={this.renderPaginationToolbar}
                                skip={this.state.skip}
                                limit={this.state.paging.pageRowCount}
                
                                onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                                onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                                onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                                enableFiltering={false}
                                showColumnMenuTool={false}
                                />
                            {openedForm}            
                        </div>
                    </div>
                </div>
                );
    }
}

export default ChequeTable;
