import React, { Component }
from 'react';
import * as lang from './../../lang.js';
import './../../css/easyTable.css';
import Tabs from './Tabs';
import Sum from './Sum';
import Subcontractor from './Subcontractor';
import Delivery from './Delivery';
import Employe from './Employe';
import EmployeFull from './EmployeFull';
import Cheque from './Cheque';
import HouseTreasury from './HouseTreasury';
import Filter from './Filter';
import Income from './Income';
import {Button, Col} from 'react-bootstrap';


import * as ajax from './../../ajax';


class Results extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0
        }

        this.TAB_SUM = 0; // Összesítő
        this.TAB_SUBCONTRACTOR = 1; //  Alvállalkozó
        this.TAB_DELIVERY = 2; // Beszállító
        this.TAB_EMPLOYE = 3; //  Dolgozók
        this.TAB_CHEQUE = 4; //  Csekkek
        this.TAB_HOUSE_TREASURY = 5; // Házipénztár
        this.TAB_INCOME = 6; // Kimenő számla
        this.TAB_EMPLOYE_FULL = 7; // Óraelszámolás

        // Filterhez
        this.date_from = '';
        this.date_to = '';
        this.workNumber = '';
    }

    setCurrentTab = function (id) {
        this.setState({currentTab: id});
        this.forceUpdate();
    }

    getFilter = function () {
        if (this.showFilter == false) {
            return "";
        }
        return
    }

    reload = function () {
        this.forceUpdate();
    }

    setHeaderFilterValues = function (filterState) {
        this.date_from = filterState.invoice_date_from;
        this.date_to = filterState.invoice_date_to;
        this.workNumber = filterState.invoice_workNumber;
        this.reload();
    }

    print = function ( ) {

        try {
            const url = ajax.getUrlBase() + "modules/Results/osszesito.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    date_from: this.date_from,
                    date_to: this.date_to,
                    workNumber: this.workNumber
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        console.log(response.extra);
                        var url = response.extra['url'];
                        window.open(ajax.getUrlBase() + url, '__blank');
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {

        var form = "";
        if (this.state.currentTab == this.TAB_SUM) {
            form = <Sum 
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_SUBCONTRACTOR) {
            form = <Subcontractor 
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_DELIVERY) {
            form = <Delivery 
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_EMPLOYE) {
            form = <Employe  
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_CHEQUE) {
            form = <Cheque 
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_HOUSE_TREASURY) {
            form = <HouseTreasury  
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_INCOME) {
            form = <Income  
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }
        if (this.state.currentTab == this.TAB_EMPLOYE_FULL) {
            form = <EmployeFull  
                date_from = {this.date_from}
                date_to = {this.date_to}
                workNumber = {this.workNumber}
                />;
        }

        return (<div>
            <div  id="Analytics">
                <div style={{paddingTop: 5, paddingLeft: 5, backgroundColor: 'white', minHeight: '86vh'}} >
                    <div style={{display: 'flex'}}>
                        <Button size="sm" onClick={this.print.bind(this)}>Nyomtatás</Button>
                        <Tabs setCurrentTab={this.setCurrentTab.bind(this)} />
                    </div>
                    <table style={{width: '80%'}}>
                        <tbody>
                            <tr>
                                <td  style={{width: '1%', paddingTop: 30, verticalAlign: 'top'}}>
                        <Filter  
                            reload={this.reload.bind(this)}  
                            setHeaderFilterValues={this.setHeaderFilterValues.bind(this)} 
                            />
                            
                        </td>
                        <td style={{width: '99%', paddingTop: 30, verticalAlign: 'top'}}>
                            {form}
                        </td>
        
                        </tr>
                        </tbody>          
                    </table>
                </div>
            </div>
        </div>
                );
    }
}

export default Results;

