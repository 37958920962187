import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';

import EmployeAddressContent from './../../modules/EmployeAddress/EmployeAddressContent.js';
import EmployePhoneContent from './../../modules/EmployePhone/EmployePhoneContent.js';
import HourlyWageContent from './../../modules/HourlyWage/HourlyWageContent.js';
import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import DateInsertField from './../../components/DateInsertField';
import MaskedInsertField from './../../components/MaskedInsertField';

import employe from './../../img/black/engineering_black_24dp.svg';
import employeAddress from './../../img/black/public_black_24dp.svg';
import employeEdit from './../../img/black/edit_note_black_24dp.svg';


class EmployeeUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employe_id: ''
            , employe_employeName: ''
            , employe_password: ''
            , employe_identityCardNumber: ''
            , employe_addressCradNumber: ''
            , employe_taxNumber: ''
            , employe_socialSecurityNumber: ''
            , employe_contactPersonName: ''
            , employe_contactPersonPhone: ''
            , employe_contactPersonAddress: ''
            , employe_emailAddressPrefix: ''
            , employe_emailAddressPostfix_id: ''
            , employe_birthPlaceCity_id: ''
            , employe_birthDate: ''
            , EmployeAddress: []
            , EmployePhone: []
            , HourlyWage: []

        }


        this.references = [];
        this.references['employe_id'] = React.createRef();
        this.references['employe_employeName'] = React.createRef();
        this.references['employe_password'] = React.createRef();
        this.references['employe_identityCardNumber'] = React.createRef();
        this.references['employe_addressCradNumber'] = React.createRef();
        this.references['employe_taxNumber'] = React.createRef();
        this.references['employe_socialSecurityNumber'] = React.createRef();
        this.references['employe_contactPersonName'] = React.createRef();
        this.references['employe_contactPersonPhone'] = React.createRef();
        this.references['employe_contactPersonAddress'] = React.createRef();
        this.references['employe_emailAddressPrefix'] = React.createRef();
        this.references['employe_emailAddressPostfix_id'] = React.createRef();
        this.references['employe_birthPlaceCity_id'] = React.createRef();
        this.references['employe_birthDate'] = React.createRef();
        this.references['employe_MTO'] = React.createRef();
        this.references['employe_MTO'] = React.createRef();
        this.references['employe_MTO'] = React.createRef();


        this.validators = [];
        this.validators['employe_id'] = {fieldName: 'employe_id', dataType: 'INT', value: this.state.employe_id, mandatory: false, min: null, max: null};
        this.validators['employe_employeName'] = {fieldName: 'employe_employeName', dataType: 'VARCHAR', value: this.state.employe_employeName, mandatory: true, min: null, max: null};
        this.validators['employe_password'] = {fieldName: 'employe_password', dataType: 'VARCHAR', value: this.state.employe_password, mandatory: false, min: null, max: null};
        this.validators['employe_identityCardNumber'] = {fieldName: 'employe_identityCardNumber', dataType: 'VARCHAR', value: this.state.employe_identityCardNumber, mandatory: false, min: null, max: null};
        this.validators['employe_addressCradNumber'] = {fieldName: 'employe_addressCradNumber', dataType: 'VARCHAR', value: this.state.employe_addressCradNumber, mandatory: false, min: null, max: null};
        this.validators['employe_taxNumber'] = {fieldName: 'employe_taxNumber', dataType: 'VARCHAR', value: this.state.employe_taxNumber, mandatory: false, min: null, max: null};
        this.validators['employe_socialSecurityNumber'] = {fieldName: 'employe_socialSecurityNumber', dataType: 'VARCHAR', value: this.state.employe_socialSecurityNumber, mandatory: false, min: null, max: null};
        this.validators['employe_contactPersonName'] = {fieldName: 'employe_contactPersonName', dataType: 'VARCHAR', value: this.state.employe_contactPersonName, mandatory: false, min: null, max: null};
        this.validators['employe_contactPersonPhone'] = {fieldName: 'employe_contactPersonPhone', dataType: 'VARCHAR', value: this.state.employe_contactPersonPhone, mandatory: false, min: null, max: null};
        this.validators['employe_contactPersonAddress'] = {fieldName: 'employe_contactPersonAddress', dataType: 'VARCHAR', value: this.state.employe_contactPersonAddress, mandatory: false, min: null, max: null};
        this.validators['employe_emailAddressPrefix'] = {fieldName: 'employe_emailAddressPrefix', dataType: 'VARCHAR', value: this.state.employe_emailAddressPrefix, mandatory: false, min: null, max: null};
        this.validators['employe_emailAddressPostfix_id'] = {fieldName: 'employe_emailAddressPostfix_id', dataType: 'INT', value: this.state.employe_emailAddressPostfix_id, mandatory: false, min: null, max: null};
        this.validators['employe_birthPlaceCity_id'] = {fieldName: 'employe_birthPlaceCity_id', dataType: 'INT', value: this.state.employe_birthPlaceCity_id, mandatory: false, min: null, max: null};
        this.validators['employe_birthDate'] = {fieldName: 'employe_birthDate', dataType: 'DATE', value: this.state.employe_birthDate, mandatory: false, min: null, max: null};
        this.validators['employe_MTO'] = {fieldName: 'employe_MTO', dataType: 'MTO', value: this.state.employe_MTO, mandatory: false, min: null, max: null};
        this.validators['employe_MTO'] = {fieldName: 'employe_MTO', dataType: 'MTO', value: this.state.employe_MTO, mandatory: false, min: null, max: null};
        this.validators['employe_MTO'] = {fieldName: 'employe_MTO', dataType: 'MTO', value: this.state.employe_MTO, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({employe_id: ''
            , employe_employeName: ''
            , employe_password: ''
            , employe_identityCardNumber: ''
            , employe_addressCradNumber: ''
            , employe_taxNumber: ''
            , employe_socialSecurityNumber: ''
            , employe_contactPersonName: ''
            , employe_contactPersonPhone: ''
            , employe_contactPersonAddress: ''
            , employe_emailAddressPrefix: ''
            , employe_emailAddressPostfix_id: ''
            , employe_birthPlaceCity_id: ''
            , employe_birthDate: ''
            , EmployeAddress: []
            , EmployePhone: []
            , HourlyWage: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {

        var employeId = this.props.additionalParams[0].employeAddress_employe_id;
        var employeAddress_employe_id = [{employeAddress_employe_id: employeId}];
        var employePhone_employe_id = [{employePhone_employe_id: employeId}];
        var hourlyWage_employe_id = [{hourlyWage_employe_id: employeId}];


        return <Container>
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><img src={employe} style={{maxHeight: 30, paddingRight: 10}} />ALKALMAZOTT ADATAI</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col style={{border: 'solid 1px #00000044', margin: 10, padding: 10}}>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_employeName')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <TextInsertField 
                                    id={'employe_employeName'} 
                                    value={this.state.employe_employeName}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_employeName']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_password')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <TextInsertField 
                                    id={'employe_password'} 
                                    value={this.state.employe_password}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_password']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_identityCardNumber')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <MaskedInsertField 
                                    regexPattern={new RegExp(/^[0-9]{6}[A-Z]{2}$/)}
                                    placeHolder="123456AA"
                                    id={'employe_identityCardNumber'} 
                                    value={this.state.employe_identityCardNumber}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_identityCardNumber']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_addressCradNumber')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <MaskedInsertField 
                                    regexPattern={new RegExp(/^[0-9]{6}[A-Z]{2}$/)}
                                    placeHolder="123456AA"
                                    id={'employe_addressCradNumber'} 
                                    value={this.state.employe_addressCradNumber}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_addressCradNumber']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_taxNumber')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <MaskedInsertField 
                                    id={'employe_taxNumber'} 
                                    value={this.state.employe_taxNumber}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_taxNumber']}
                                    editable={true}
                                    theme={this.props.theme}
                                    regexPattern={new RegExp(/^\d{10}$/)}
                                    placeHolder="0123456789"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_socialSecurityNumber')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <MaskedInsertField 
                                    regexPattern={new RegExp(/^[0-9]{9}$/)}
                                    placeHolder="123456789" 
                                    id={'employe_socialSecurityNumber'} 
                                    value={this.state.employe_socialSecurityNumber}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_socialSecurityNumber']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_birthPlaceCity_id')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <ComboBoxInsertField 
                                    id={'employe_birthPlaceCity_id'} 
                                    value={this.state.employe_birthPlaceCity_id}  
                                    setBack={this.setBack.bind(this)}
                                    dataSourceUrl={'modules/Employee/birthPlaceCity_idFormComboInsertData.php'}
                                    reference={this.references['employe_birthPlaceCity_id']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_birthDate')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <DateInsertField 
                                    id={'employe_birthDate'} 
                                    value={this.state.employe_birthDate}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_birthDate']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            </Col>
                            <Col style={{border: 'solid 1px #00000044', margin: 10, padding: 10}}>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_contactPersonName')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <TextInsertField 
                                    id={'employe_contactPersonName'} 
                                    value={this.state.employe_contactPersonName}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_contactPersonName']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_contactPersonPhone')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <MaskedInsertField 
                                    id={'employe_contactPersonPhone'} 
                                    value={this.state.employe_contactPersonPhone}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_contactPersonPhone']}
                                    editable={true}
                                    theme={this.props.theme}
                                    regexPattern={new RegExp(/^\+[0-9]{2}-[0-9]{2}-[0-9]{7}$/)}
                                    placeHolder="+36-30-1234567"
                                    />
        
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_contactPersonAddress')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <TextInsertField 
                                    id={'employe_contactPersonAddress'} 
                                    value={this.state.employe_contactPersonAddress}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_contactPersonAddress']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_emailAddressPrefix')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <TextInsertField 
                                    id={'employe_emailAddressPrefix'} 
                                    value={this.state.employe_emailAddressPrefix}  
                                    setBack={this.setBack.bind(this)}
                                    reference={this.references['employe_emailAddressPrefix']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{textAlign: 'left'}}>{lang.getTitle('employe_emailAddressPostfix_id')}</Col>
                                <Col style={{textAlign: 'right'}}>
                                <ComboBoxInsertField 
                                    id={'employe_emailAddressPostfix_id'} 
                                    value={this.state.employe_emailAddressPostfix_id}  
                                    setBack={this.setBack.bind(this)}
                                    dataSourceUrl={'modules/Employee/emailAddressPostfix_idFormComboInsertData.php'}
                                    reference={this.references['employe_emailAddressPostfix_id']}
                                    editable={true}
                                    theme={this.props.theme}
                                    />
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><img src={employeAddress} style={{maxHeight: 30, paddingRight: 10}}/> ALKALMAZOTT CÍME</Accordion.Header>
                    <Accordion.Body>     
        
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                            <div>
                                <EmployeAddressContent   
                                    value={this.state.EmployeAddress} 
                                    setBack={this.setBack.bind(this)} 
                                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : employeAddress_employe_id}
                                    editable={true}
                                    formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                                    theme={this.props.theme}
                                    />
                            </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><img src={employeEdit} style={{maxHeight: 30, paddingRight: 10}}/>ALKALMAZOTT TELEFONSZÁMA ÉS ÓRABÉRE</Accordion.Header>
                    <Accordion.Body>    
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                            <div>
                                <EmployePhoneContent   
                                    value={this.state.EmployePhone} 
                                    setBack={this.setBack.bind(this)} 
                                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : employePhone_employe_id}
                                    editable={true}
                                    formType={(typeof this.props.formType == 'undefined') ? null : hourlyWage_employe_id}
                                    theme={this.props.theme}
                                    />
                            </div>
                            </Col>
        
                            <Col style={{textAlign: 'right'}}>
                            <div>
                                <HourlyWageContent   
                                    value={this.state.HourlyWage} 
                                    setBack={this.setBack.bind(this)} 
                                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : hourlyWage_employe_id}
                                    editable={true}
                                    formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                                    theme={this.props.theme}
                                    />
                            </div>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    employeNameValidator = function () {
        if (this.state.employe_employeName == null) {
            alert('Alkalmazott nevének megadása kötelező');
            return false;
        }
        if (this.state.employe_employeName == '') {
            alert('Alkalmazott nevének megadása kötelező');
            return false;
        }

        return true;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        var hasEmployeName = this.employeNameValidator();
        validForm = validForm && hasEmployeName;
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Employee/employeFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Employee/employeFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth: 1200}}>
            <div className={'popupWrapper' + className} id="EmployeeUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('EmployeeUpdate')}</Col>
                        <Col style={{textAlign: 'right', marginRight: 20}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'delete'} onClick={() => this.props.delete()} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('Delete')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default EmployeeUpdate;

