import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import { Container, Row, Col, Button }
from 'react-bootstrap';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';

import WorkNumberFilterCombo from './WorkNumberFilterCombo';

import PaymentPeriodCombo from './../Calendar/PaymentPeriodCombo';
import DateInsertField from './../../components/DateInsertField';
import deleteIcon from './../../img/black/delete_black_24dp.svg';
import SumForSidePanel from "./SumForSidePanel";

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            periodYear: new Date().getFullYear(),
            calendar_paymentPeriod: '',
            invoice_date_from: '',
            invoice_date_to: '',
            invoice_workNumber: ''

        }



        this.refWorkingDate_from = React.createRef();
        this.refWorkingDate_to = React.createRef();
        this.refWorkNumber = React.createRef();
        this.paymentPeriodRef = React.createRef();
        this.paymentPeriodReset = {call: null};
    }

    storeFilters = function () {

        localStorage.setItem('result_paymentPeriod', this.state.calendar_paymentPeriod);
        localStorage.setItem('result_periodYear', this.state.periodYear);
        localStorage.setItem('result_date_from', this.state.invoice_date_from);
        localStorage.setItem('result_date_to', this.state.invoice_date_to);
    }

    componentWillUnmount = function () {
        this.storeFilters();
    }

    nullToEmptyString = function (val) {
        if (typeof val == 'undefined') {
            return '';
        }
        if (typeof val == null) {
            return '';
        }
        return val;
    }

    loadStoredFilters = function () {

        this.state.calendar_paymentPeriod = this.nullToEmptyString(localStorage.getItem('result_paymentPeriod'));
        this.state.periodYear = this.nullToEmptyString(localStorage.getItem('result_periodYear'));
        this.state.invoice_date_from = this.nullToEmptyString(localStorage.getItem('result_date_from'));
        this.state.invoice_date_to = this.nullToEmptyString(localStorage.getItem('result_date_to'));

        if (this.state.invoice_date_from == 'undefined') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.invoice_date_from == null) {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.invoice_date_from == '') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }

        if (this.state.invoice_date_to == 'undefined') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.invoice_date_to == null) {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.invoice_date_to == '') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }



        this.props.setHeaderFilterValues(this.state);
    }
    componentDidMount() {
        this.loadStoredFilters();
        this.forceUpdate();
    }

    hasNewCell = function (currentArray) {

// Ez azért kell, hogy ne ajánljon meg újabb szűrő filtereket.
        if (currentArray.length == 1) {
            return true;
        }
// Ez azért kell, hogy ne ajánljon meg újabb szűrő filtereket. VÉGE

        if (currentArray.length == 0) {
            return false;
        }
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] == -1 || currentArray[i] == '') {
                return true;
            }
        }

        return false;
    }

    setCustomerFilter = function (id, value) {
        this.state.invoice_customer = value;
        this.props.setHeaderFilterValues(this.state);
    }

    setWorkNumberFilter = function (id, value) {
        this.state.invoice_workNumber = value;
        this.props.setHeaderFilterValues(this.state);
    }

    setBack = function (id, value) {
        this.state[id] = value;
        this.props.setHeaderFilterValues(this.state);
        this.storeFilters();
    }
    reset = function () {
        this.paymentPeriodReset.call();
        this.state.calendar_paymentPeriod = '';
        this.state.invoice_date_from = '';
        this.state.invoice_date_to = '';
        this.state.calendar_workNumber = [];
        this.props.setHeaderFilterValues(this.state);
        this.setState({});
    }

    getYearCombo = function () {
        var items = [];
        for (var i = 2022; i < 2101; i++) {
            items.push(<option value={i} key={"paymentPreiodYears_" + i} >{i}</option>);
        }
        return <select value={this.state.periodYear} onChange={(e) => {
                        this.setState({periodYear: e.target.value})
                            }}>
            {items}
        </select>
    }

    setPaymentPeriod = function (id, value) {
        if (value.includes('_')) {
            this.state.calendar_paymentPeriod = value;
            var from_to_array = value.split('_');
            var from = from_to_array[0];
            var to = from_to_array[1];
            this.state.invoice_date_from = from;
            this.state.invoice_date_to = to;
            this.props.setHeaderFilterValues(this.state);
        } else {
            this.state.invoice_date_from = '';
            this.state.invoice_date_to = '';
            this.props.setHeaderFilterValues(this.state);
        }


    }

    render() {

        if (this.state.invoice_date_from == 'undefined') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.invoice_date_from == null) {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.invoice_date_from == '') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }

        if (this.state.invoice_date_to == 'undefined') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.invoice_date_to == null) {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.invoice_date_to == '') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }




        var mandatory = "";
        if (this.state.invoice_date_from == '' || this.state.invoice_date_to == '') {
            mandatory = <tr>
                <td style={{color: 'red'}} colSpan={2}>A TÓL-IG INTERVALLUM MEGADÁSA KÖTELEZŐ</td>
            </tr>
        }

        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px',
                        minWidth: 260

                         }}>
            <table  style={{textAlign: 'center'}}  >               
                <tbody>               
                    <tr>
                        <td  colSpan={2}><b>FILTEREK</b></td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>FIZETÉSI PERIÓDUSOK</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>
                            <div style={{display: 'flex'}}>
                                {this.getYearCombo()}        
                                <PaymentPeriodCombo 
                                    id={'PaymentPeriodCombo'} 
                                    value={this.state.calendar_paymentPeriod}  
                                    setBack={this.setPaymentPeriod.bind(this)}
                                    dataSourceUrl={'modules/Calendar/paymentPeriodComboData.php'}
                                    reference={this.paymentPeriodRef}
                                    year={this.state.periodYear}
                                    editable={true}
                                    theme={true}
                                    paymentPeriodReset={this.paymentPeriodReset}
                                    placeHolder={'Fizetési periódusok'}
                                    />
                            </div>
                        </td>
                    </tr>
        
        
                    {mandatory}
                    <tr>
                        <td colSpan={1}>TÓL</td>
                        <td colSpan={1}>IG</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                <DateInsertField 
                    id={'invoice_date_from'} 
                    value={this.state.invoice_date_from}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_from}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                <td  colSpan={1}>
                <DateInsertField 
                    id={'invoice_date_to'} 
                    value={this.state.invoice_date_to}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_to}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}} colSpan={2}>
                        MUNKASZÁMOK
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div key={"workNumberCounter"} style={{display: 'flex', margin: 5}}>
                            <div>
                                <WorkNumberFilterCombo 
                                    id={'workNumber'} 
                                    value={this.state.invoice_workNumber}  
                                    setBack={this.setWorkNumberFilter.bind(this)}
                                    dataSourceUrl={'modules/Results/workNumberFilterCombo.php'}
                                    from={this.state.invoice_date_from}
                                    to={this.state.invoice_date_to}
                                    reference={React.createRef()}
                                    editable={true}
                                    theme={true}
                                    placeHolder={'Munkaszám'}
                                    />
                            </div>    
                            <div>
                                <img id={"delete"} 
                                     onClick={() => {
                                                     this.state.invoice_workNumber = '';
                                                     this.props.setHeaderFilterValues(this.state);
                                                 }} 
                                     style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                            </div>
                        </div>
                    </td>
                </tr>    
        
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <button 
                            style={{minWidth: '100%'}}
                            onClick={this.reset.bind(this)}
                            >Alaphelyzet</button>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                       
                <SumForSidePanel
                    invoice_date_from={this.state.invoice_date_from}
                    invoice_date_to={this.state.invoice_date_to}
                    invoice_workNumber={this.state.invoice_workNumber}
        
        
                    />
                </td>
                </tr>
        
                </tbody>   
            </table>
        
        </div>);
    }
}

export default Filter;

