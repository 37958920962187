import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import { Container, Row, Col, Button } from 'react-bootstrap';

class SumForSidePanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subcontractorSum: 0.0,
            deliverySum: 0.0,
            employeSum: 0.0,
            chequeSum: 0.0,
            houseTreasuryInSum: 0.0,
            otherCostsSum: 0.0,
            invoiceOutgoingStornoSum: 0.0,

            subcontractorStornoSum: 0.0,
            deliveryStornoSum: 0.0,

            houseTreasuryOutSum: 0.0,
            invoiceOutgoingSum: 0.0,

            outSUM: 0.0,
            inSUM: 0.0,
            fullSUM: 0.0

        }

    }

    componentDidUpdate(prevProps) {


        var updateNeed = false;
        if (prevProps.invoice_date_from != this.props.invoice_date_from) {
            updateNeed = true;
        }
        if (prevProps.invoice_date_to != this.props.invoice_date_to) {
            updateNeed = true;
        }
        if (prevProps.invoice_workNumber != this.props.invoice_workNumber) {
            updateNeed = true;
        }


        if (updateNeed == true) {

            this.update();
        }

    }

    update = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Results/SumForSidePanel.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    date_from: this.props.invoice_date_from,
                    date_to: this.props.invoice_date_to,
                    workNumber: this.props.invoice_workNumber
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.setState({

                            subcontractorSum: response.extra.subcontractorSum,
                            deliverySum: response.extra.deliverySum,
                            employeSum: response.extra.employeSum,
                            chequeSum: response.extra.chequeSum,
                            houseTreasuryInSum: response.extra.houseTreasuryInSum,
                            otherCostsSum: response.extra.otherCostsSum,
                            invoiceOutgoingStornoSum: response.extra.invoiceOutgoingStornoSum,

                            subcontractorStornoSum: response.extra.subcontractorStornoSum,
                            deliveryStornoSum: response.extra.deliveryStornoSum,

                            houseTreasuryOutSum: response.extra.houseTreasuryOutSum,
                            invoiceOutgoingSum: response.extra.invoiceOutgoingSum,

                            outSUM: response.extra.outSUM,
                            inSUM: response.extra.inSUM,
                            fullSUM: response.extra.fullSUM






                        });


                        this.setState(response.extra);

                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {

        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px',
                        minWidth: 240

                         }}>
            <table  style={{textAlign: 'left', minWidth: 235}}  >               
                <tbody>               
                    <tr>
                        <td colSpan="2" style={{textAlign: 'center'}}>KIADÁS</td>
                    </tr>
                    <tr>
                        <td><b>Alvállalkozó:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.subcontractorSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Beszállító:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.deliverySum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Bevét sztornó</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.invoiceOutgoingStornoSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Házipénztár kiadás:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.houseTreasuryOutSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Dolgozók:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.employeSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Csekkek:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.chequeSum))).toLocaleString()}</td>
                    </tr>
                    
                    <tr>
                        <td><b>Egyéb költségek:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.otherCostsSum))).toLocaleString()}</td>
                    </tr>
                    
                    <tr>
                        <td><b>Összesen:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.outSUM))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{textAlign: 'center'}}>BEVÉTEL</td>
                    </tr>
                     <tr>
                        <td><b>Alváll sz. stronó:</b></td>
                        <td  style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.subcontractorStornoSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Beszáll sz. stornó:</b></td>
                        <td  style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.deliveryStornoSum))).toLocaleString()}</td>
                    </tr>
                     <tr>
                        <td><b>Bevét:</b></td>
                        <td  style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.invoiceOutgoingSum))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td><b>Házipénztár bevétel:</b></td>
                        <td style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.houseTreasuryInSum))).toLocaleString()}</td>
                    </tr>
        
                   
                   
                    <tr>
                        <td><b>Összesen:</b></td>
                        <td  style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.inSUM))).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{borderTop: 'solid 1px #444444'}}></td>
        
                    </tr>
                    <tr>
                        <td><b>VÉGÖSSZESEN:</b></td>
                        <td  style={{textAlign: 'right'}}>{(new Number(Math.round(this.state.fullSUM))).toLocaleString()}</td>
                    </tr>
        
                </tbody>               
            </table>
        
        </div>);
    }
}

export default SumForSidePanel;

