import React, { Component } from 'react';
import './../../css/light.css';

import * as ajax from './../../ajax.js';


class Tabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                {id:0,title:'Összesítő'},
                {id:1,title:'Alvállalkozó'},
                {id:2,title:'Beszállító'},
                {id:3,title:'Dolgozók'},
                {id:4,title:'Csekkek'},
                {id:5,title:'Házipénztár'},
                {id:6,title:'Bevétel'}
                
                
            ],
            current_id:-1
        }

   

    }

   
    setCurrent=function(e){
        this.setState({current_id:e.target.id})
        this.props.setCurrentTab(e.target.id);
    }
    
    

    getTab = function (current_id,item) {
        var style={};
        if(current_id ==item.id && item.id != -1){
            style={borderBottom:'none',color:'blue'};
        }
        if(this.state.current_id == -1 && item.id == -1  ){
            style={borderBottom:'none',color:'green'};
        }
        
        if(current_id ==item.id && item.id == -2  ){
            style={borderBottom:'none',color:'#e63f3f'};
        }
        
        if(this.state.current_id == -2 && item.id == -2  ){
            style={borderBottom:'none',color:'#e63f3f',fontWeight:'bold'};
        }
        
        return <div key={'emloye_key_'+item.id} onClick={this.setCurrent.bind(this)} style={style} className="tabItem" id={item.id}>
        {item.title}
        </div>
    }

    getTabs = function (current_id) {
        var tabs = [];
        
        
        for (var i = 0; i < this.state.items.length; i++) {
            var item = this.state.items[i];
            var tab = this.getTab(current_id,item);
            tabs.push(tab);
          
        }
        return tabs;
    }

    render() {
        var tabs = this.getTabs(this.state.current_id);
        return  <div className="tabContainer">
            {tabs}
        </div>;
    }
}

export default Tabs;


