export  function getTitle(nameConstant) {
    if ((!lang.hasOwnProperty(nameConstant)) || lang[nameConstant] === '') {
        return nameConstant;
    }
    return lang[nameConstant];
}
var lang = {
    Insert: 'Új tétel'
    , save_state: 'Állapot mentése'
    , save: 'Mentés'
    , new : 'Új rögzítése'
    , View: 'Megtekint'
    , Edit: 'Módosít'
    , Delete: 'Töröl'
    , Update: 'Módosítás'
    , Actions: 'Műveletek'
    , confirmation_dialog: 'Megerősítés kérése'
    , Cancel: 'Mégsem'
    , Close: 'Bezárás'
    , close: 'Bezárás'
    , Submit: 'Rögzít'
    , New: 'Új'
    , transaction_success: 'Sikeres tranzakció'
    , delete_record_confirmation_text: 'Biztos benne, hogy törli az adatot?'
    , Images: 'Képek'
    , all: 'Összes'
    , yes: 'Igen'
    , no: 'Nem'
    , Files: 'Fájlok feltöltése'
    , invalid_form: 'Az űrlap nincs megfelelően kitöltve. A piros felkiáltójel melletti mezőket kötelező kitölteni.'
    , sign: 'Aláír'
    , Login: 'Bejelentkezés'
    , logOut: 'Kijelentkezés'
    , loggedInUser: 'Bejelentkezve'
    , users_password: 'Jelszó'
    , users_password_again: 'Jelszó ismétlése'
    , changePassword: 'Jelszó változtatás'
    , invalid_login_form: 'Sikertelen bejelentkezés'
    , ROW_SELECTION_NEED: 'Kérem válasszon ki egy sort a táblázatban.'
    , DELETE_CONFIRMATION: 'Biztosan törölni kívánja a tételt.'
    , CONFIRMATION_DIALOG_TITLE: 'MEGERŐSÍTÉS KÉRÉSE'
    , ALERT_DIALOG_TITLE: 'FIGYELMEZTETÉS'
    , GoogleDriveFiles: 'Google Drive Sablon'
    , GoogleDriveFilesCustomer: 'Google Drive Munkaszám'
    , BaseData: 'Alapadatok'

    , Analytics: 'Analitika'

    , Customer: 'Ügyfél'    , customer_id: '#'
    , customer_customerName: 'Neve'
    , customer_taxNumber: 'Adószám'
    , customer_MTO: 'Adószám'
    , customer_name: 'Megrendelő'
    , customer_address: 'Cím'

    , Cheques: 'Csekkek'

    , MTOCustomerAddress: 'Ügyfél cím'
    , customerAddress1_id: '#'
    , customerAddress_customer_id: 'Ügyfél'
    , customerAddress_city_id: 'Település'
    , customerAddress_publicSpaceName: 'Közterület'
    , customerAddress_publicSpaceType_id: 'Közterület típusa'
    , customerAddress_floor: 'Emelet'
    , customerAddress_houseNumber: 'Házszám'
    , customerAddress_billingAddress: 'Számlázási cím'

    , city_id: '#'
    , city_zipCode: 'IRSZ'
    , city_cityName: 'Város neve'
    , city_deleted: 'Törölt'
    , publicSpaceType_id: 'Közterület típusa'
    , publicSpaceType_typeName: 'Közterület típusa'

    , CustomerAddress: 'Ügyfél cim'

    , MTOCustomerBankAccountNumber: 'Ügyfél bankszámlaszám'
    , customerBankAccountNumber_id: '#'
    , customerBankAccountNumber_customer_id: 'Ügyfél'
    , customerBankAccountNumber_bankAccountNumber: 'Bankszámlaszám'
    , customerBankAccountNumber_primaryAccount: 'Elsődleges'

    , CustomerBankAccountNumber: 'Ügyfél bankszámlaszám'

    , MTOCustomerContactPerson: 'Ügyfél Kapcsolattartó'
    , customerContactPerson_id: '#'
    , customerContactPerson_customer_id: 'Ügyfél'
    , customerContactPerson_phoneNumber: 'Telefonszám'
    , customerContactPerson_title: 'Titulus'
    , customerContactPerson_personName: 'Név'
    , customerContactPerson_emailAddress: 'E-mail'
    , customerContactPerson_remark: 'Megjegyzés'

    , CustomerContactPerson: 'Ügyfél Kapcsolattartó'

    , CustomerState: 'Megrendelői státusz'
    , emailAddressPostfix_id: 'E-mail'
    , emailAddressPostfix_postfix: 'E-mail szolgáltató'

    , EmailAddressPostfix: 'E-mail végződés'

    , Employee: 'Alkalmazott'
    , employe: 'Nevek'
    , employe_id: '#'
    , employe_employeName: 'Név'
    , employe_identityCardNumber: 'Személy ig. szám'
    , employe_addressCradNumber: 'Lakcím kártya száma'
    , employe_taxNumber: 'Adóazonosító'
    , employe_socialSecurityNumber: 'TAJ szám'
    , employe_contactPersonName: 'Kapcsoltattartó neve'
    , employe_contactPersonPhone: 'Kapcsoltattartó telefonszám'
    , employe_contactPersonAddress: 'Kapcsolattartó cím'
    , employe_emailAddressPrefix: 'E-mail'
    , employe_emailAddressPostfix_id: 'E-mail szolgáltató'
    , employe_birthPlaceCity_id: 'Születési hely'
    , employe_birthDate: 'Születés ideje'
    , employe_MTO: 'Alkalmazott'
    , employe_password: 'Jelszó min (8 karakter)'
    , employe_currentWage: 'Óradíj'
    , employe_employeAddress: 'Cím'
    , employe_employeEmail: 'E-mail'
    , employe_employePhone: 'Telefonszám'


    , MTOEmployeAddress: 'Alkalmazott Címe'
    , employeAddress_id: '#'
    , employeAddress_employe_id: 'Alkalmazott'
    , employeAddress_city_id: 'Város'
    , employeAddress_publicSpaceName: 'Közterület neve'
    , employeAddress_publicSpaceType_id: 'Közterület típusa'
    , employeAddress_floor: 'Emelet'
    , employeAddress_houseNumber: 'Házszám'

    , EmployeAddress: 'Alkalmazott címe'

    , MTOEmployePhone: 'Alkalmazott telefonszám'
    , employePhone_id: '#'
    , employePhone_employe_id: 'Alkalmazott'
    , employePhone_phoneNumber: 'Telefonszám'

    , EmployePhone: 'Alkalmazott telefonszám'

    , MTOHourlyWage: 'Órabér'
    , hourlyWage_id: '#'
    , hourlyWage_employe_id: 'Alkalmazott'
    , hourlyWage_wage: 'Órabér'
    , hourlyWage_wageFull: 'Rezsi órabér'
    , hourlyWage_fromDate: 'Tól'
    , hourlyWage_ceratedBy: 'Készítette'
    , hourlyWage_createdAt: 'Készült'

    , HourlyWage: 'Órabér'

    , PublicSpaceType: 'Közterület típusa'

    , VatView: 'ÁFA MEGTEKINTÉSE'
    , Vat: 'Áfa'
    , vat_id: '#'
    , vat_vatName: 'Áfa megnevezés'
    , vat_vatValue: 'Áfa érték(%)'
    , vat_deleted: 'Törölt'
    , vat_createdAt: 'Készült'
    , vat_createdBy: 'Készítette'
    , vat_deletedBy: 'Törölte'
    , vat_deletedAt: 'Törölve'
    , creatorPersonName: 'Készítette'
    , deletingPersonName: 'Törölte'

    , WorkNumber: 'Munkaszám'
    , workNumber1_id: '#'
    , workNumber_workNumberName: 'Megnevezés'
    , workNumber_sequenceNumber: 'Munkaszám'
    , workNumber_cost: 'Rezsi'
    , workNumber_projectName: 'Projekt neve'
    , workNumber_customerState_id: 'Megrendelői státusz'
    , workNumber_customer_id: 'Megrendelő'
    , workNumber_workNumberSubject: 'Tárgy'
    , workNumber_state: 'Státusz'
    , workNumber_offerRequestDate: 'Ajánlat kérés dátuma'
    , workNumber_projectStartDate: 'Projekt kezdete'
    , workNumber_customerAddress1_id: 'Ügyfél címe'
    , workNumber_taxNumber: 'Adószám'
    , workNumber_companyRegistrationNumber: 'Cégjegyzékszám'
    , workNumber_emailAddressPrefix: 'E-mail cím'
    , workNumber_customerContactPerson_id: 'Kapcsolattartó'
    , workNumber_customerBankAccountNumber_id: 'Bankszámlaszám'
    , workNumber_agreedPriceNET: 'Vállalási ár'
    , workNumber_vat_id: 'ÁFA'
    , workNumber_vatName: 'ÁFA'
    , workNumber_vatValue: 'Fizetendő áfa'
    , workNumber_grossValue: 'Bruttó vállalási ár'
    , workNumber_plannedStartDate: 'Kezdés'
    , workNumber_plannedEndDate: 'Befejezés'
    , WorkNumberOffer: 'Ajánlat'
    , WorkNumberRunning: 'Futó'
    , WorkNumberPayable: 'Rezsi'
    , workNumber_workNumberFullName: 'Munkaszám'
    , customerState_id: 'Megrendelői státusz'
    , customerState_stateName: 'Megrendelői státusz neve'



    , City:'Település'
    , CityInsert: 'TELEPÜLÉS FELVITELE'
    , CustomerInsert: 'ÜGYFÉL FELVITELE'
    , CustomerAddressInsert: 'ÜGYFÉL CÍM FELVITELE'
    , CustomerBankAccountNumberInsert: 'ÜGYFÉL BANKSZÁMLASZÁM FELVITELE'
    , CustomerContactPersonInsert: 'ÜGYFÉL KAPCSOLATTARTÓ FELVITELE'
    , CustomerStateInsert: 'MEGRENDELŐI STÁTUSZ FELVITELE'
    , EmailAddressPostfixInsert: 'E-MAIL VÉGZŐDÉS FELVITELE'
    , EmployeInsert: 'ALKALMAZOTT FELVITELE'
    , EmployeAddressInsert: 'ALKALMAZOTT CÍMÉNEK FELVITELE'
    , EmployePhoneInsert: 'ALKALMAZOTT TELEFONSZÁMÁNAK FELVITELE'
    , EmployeeInsert: 'ALKALMAZOTT FELVITELE'
    , EmployeeAddressInsert: 'ALKALMAZOTT CÍMÉNEK FELVITELE'
    , EmployeePhoneInsert: 'ALKALMAZOTT TELEFONSZÁMÁNAK FELVITELE'
    , HourlyWageInsert: 'ALKALMAZOTT ÓRABÉRÉNEK FELVITELE'
    , PublicSpaceTypeInsert: 'KÖZTERÜLET TÍPUS FELVITELE'
    , WorkNumberInsert: 'MUNKASZÁM FELVITELE'

    , CityUpdate: 'TELEPÜLÉS MÓDOSÍTÁSA'
    , CustomerUpdate: 'ÜGYFÉL MÓDOSÍTÁSA'
    , CustomerAddressUpdate: 'ÜGYFÉL CÍM MÓDOSÍTÁSA'
    , CustomerBankAccountNumberUpdate: 'ÜGYFÉL BANKSZÁMLASZÁM MÓDOSÍTÁSA'
    , CustomerContactPersonUpdate: 'ÜGYFÉL KAPCSOLATTARTÓ MÓDOSÍTÁSA'
    , CustomerStateUpdate: 'MEGRENDELŐI STÁTUSZ MÓDOSÍTÁSA'
    , EmailAddressPostfixUpdate: 'E-MAIL VÉGZŐDÉS MÓDOSÍTÁSA'
    , EmployeUpdate: 'ALKALMAZOTT MÓDOSÍTÁSA'
    , EmployeAddressUpdate: 'ALKALMAZOTT CÍMÉNEK MÓDOSÍTÁSA'
    , EmployePhoneUpdate: 'ALKALMAZOTT TELEFONSZÁMÁNAK MÓDOSÍTÁSA'
    , EmployeeUpdate: 'ALKALMAZOTT MÓDOSÍTÁSA'
    , EmployeeAddressUpdate: 'ALKALMAZOTT CÍMÉNEK MÓDOSÍTÁSA'
    , EmployeePhoneUpdate: 'ALKALMAZOTT TELEFONSZÁMÁNAK MÓDOSÍTÁSA'
    , HourlyWageUpdate: 'ALKALMAZOTT ÓRADBÉRÉNEK MÓDOSÍTÁSA'
    , PublicSpaceTypeUpdate: 'KÖZTERÜLET TÍPUS MÓDOSÍTÁSA'
    , WorkNumberUpdate: 'MUNKASZÁM MÓDOSÍTÁSA'


    , CityView: 'TELEPÜLÉS MEGTEKINTÉSE'
    , CustomerView: 'ÜGYFÉL MEGTEKINTÉSE'
    , CustomerAddressView: 'ÜGYFÉL CÍM MEGTEKINTÉSE'
    , CustomerBankAccountNumberView: 'ÜGYFÉL BANKSZÁMLASZÁM MEGTEKINTÉSE'
    , CustomerContactPersonView: 'ÜGYFÉL KAPCSOLATTARTÓ MEGTEKINTÉSE'
    , CustomerStateView: 'MEGRENDELŐI STÁTUSZ MEGTEKINTÉSE'
    , EmailAddressPostfixView: 'E-MAIL VÉGZŐDÉS MEGTEKINTÉSE'
    , EmployeView: 'ALKALMAZOTT MEGTEKINTÉSE'
    , EmployeAddressView: 'ALKALMAZOTT CÍMÉNEK MEGTEKINTÉSE'
    , EmployePhoneView: 'ALKALMAZOTT TELEFONSZÁMÁNAK MEGTEKINTÉSE'
    , EmployeeView: 'ALKALMAZOTT MEGTEKINTÉSE'
    , EmployeeAddressView: 'ALKALMAZOTT CÍMÉNEK MEGTEKINTÉSE'
    , EmployeePhoneView: 'ALKALMAZOTT TELEFONSZÁMÁNAK MEGTEKINTÉSE'
    , HourlyWageView: 'ALKALMAZOTT ÓRADBÉRÉNEK MEGTEKINTÉSE'
    , PublicSpaceTypeView: 'KÖZTERÜLET TÍPUS MEGTEKINTÉSE'
    , WorkNumberView: 'MUNKASZÁM MEGTEKINTÉSE'



    , MTOCityInsert: 'TELEPÜLÉS FELVITELE'
    , MTOCustomerInsert: 'ÜGYFÉL FELVITELE'
    , MTOCustomerAddressInsert: 'ÜGYFÉL CÍM FELVITELE'
    , MTOCustomerBankAccountNumberInsert: 'ÜGYFÉL BANKSZÁMLASZÁM FELVITELE'
    , MTOCustomerContactPersonInsert: 'ÜGYFÉL KAPCSOLATTARTÓ FELVITELE'
    , MTOCustomerStateInsert: 'MEGRENDELŐI STÁTUSZ FELVITELE'
    , MTOEmailAddressPostfixInsert: 'E-MAIL VÉGZŐDÉS FELVITELE'
    , MTOEmployeInsert: 'ALKALMAZOTT FELVITELE'
    , MTOEmployeAddressInsert: 'ALKALMAZOTT CÍMÉNEK FELVITELE'
    , MTOEmployePhoneInsert: 'ALKALMAZOTT TELEFONSZÁMÁNAK FELVITELE'
    , MTOEmployeeInsert: 'ALKALMAZOTT FELVITELE'
    , MTOEmployeeAddressInsert: 'ALKALMAZOTT CÍMÉNEK FELVITELE'
    , MTOEmployeePhoneInsert: 'ALKALMAZOTT TELEFONSZÁMÁNAK FELVITELE'
    , MTOHourlyWageInsert: 'ALKALMAZOTT ÓRADBÉRÉNEK FELVITELE'
    , MTOPublicSpaceTypeInsert: 'KÖZTERÜLET TÍPUS FELVITELE'
    , MTOWorkNumberInsert: 'MUNKASZÁM FELVITELE'

    , MTOCityUpdate: 'TELEPÜLÉS MÓDOSÍTÁSA'
    , MTOCustomerUpdate: 'ÜGYFÉL MÓDOSÍTÁSA'
    , MTOCustomerAddressUpdate: 'ÜGYFÉL CÍM MÓDOSÍTÁSA'
    , MTOCustomerBankAccountNumberUpdate: 'ÜGYFÉL BANKSZÁMLASZÁM MÓDOSÍTÁSA'
    , MTOCustomerContactPersonUpdate: 'ÜGYFÉL KAPCSOLATTARTÓ MÓDOSÍTÁSA'
    , MTOCustomerStateUpdate: 'MEGRENDELŐI STÁTUSZ MÓDOSÍTÁSA'
    , MTOEmailAddressPostfixUpdate: 'E-MAIL VÉGZŐDÉS MÓDOSÍTÁSA'
    , MTOEmployeUpdate: 'ALKALMAZOTT MÓDOSÍTÁSA'
    , MTOEmployeAddressUpdate: 'ALKALMAZOTT CÍMÉNEK MÓDOSÍTÁSA'
    , MTOEmployePhoneUpdate: 'ALKALMAZOTT TELEFONSZÁMÁNAK MÓDOSÍTÁSA'
    , MTOEmployeeUpdate: 'ALKALMAZOTT MÓDOSÍTÁSA'
    , MTOEmployeeAddressUpdate: 'ALKALMAZOTT CÍMÉNEK MÓDOSÍTÁSA'
    , MTOEmployeePhoneUpdate: 'ALKALMAZOTT TELEFONSZÁMÁNAK MÓDOSÍTÁSA'
    , MTOHourlyWageUpdate: 'ALKALMAZOTT ÓRADBÉRÉNEK MÓDOSÍTÁSA'
    , MTOPublicSpaceTypeUpdate: 'KÖZTERÜLET TÍPUS MÓDOSÍTÁSA'
    , MTOWorkNumberUpdate: 'MUNKASZÁM MÓDOSÍTÁSA'


    , MTOCityView: 'TELEPÜLÉS MEGTEKINTÉSE'
    , MTOCustomerView: 'ÜGYFÉL MEGTEKINTÉSE'
    , MTOCustomerAddressView: 'ÜGYFÉL CÍM MEGTEKINTÉSE'
    , MTOCustomerBankAccountNumberView: 'ÜGYFÉL BANKSZÁMLASZÁM MEGTEKINTÉSE'
    , MTOCustomerContactPersonView: 'ÜGYFÉL KAPCSOLATTARTÓ MEGTEKINTÉSE'
    , MTOCustomerStateView: 'MEGRENDELŐI STÁTUSZ MEGTEKINTÉSE'
    , MTOEmailAddressPostfixView: 'E-MAIL VÉGZŐDÉS MEGTEKINTÉSE'
    , MTOEmployeView: 'ALKALMAZOTT MEGTEKINTÉSE'
    , MTOEmployeAddressView: 'ALKALMAZOTT CÍMÉNEK MEGTEKINTÉSE'
    , MTOEmployePhoneView: 'ALKALMAZOTT TELEFONSZÁMÁNAK MEGTEKINTÉSE'
    , MTOEmployeeView: 'ALKALMAZOTT MEGTEKINTÉSE'
    , MTOEmployeeAddressView: 'ALKALMAZOTT CÍMÉNEK MEGTEKINTÉSE'
    , MTOEmployeePhoneView: 'ALKALMAZOTT TELEFONSZÁMÁNAK MEGTEKINTÉSE'
    , MTOHourlyWageView: 'ALKALMAZOTT ÓRADBÉRÉNEK MEGTEKINTÉSE'
    , MTOPublicSpaceTypeView: 'KÖZTERÜLET TÍPUS MEGTEKINTÉSE'
    , MTOWorkNumberView: 'MUNKASZÁM MEGTEKINTÉSE'

    , WorkingHours: 'Munkaidő nyilvántartás'

    , WorkingRecordInsert: 'JELENLÉTI ÍV FELVITELE'
    , WorkingRecordUpdate: 'JELENLÉTI ÍV MÓDOSÍTÁSA'
    , WorkingRecordView: 'JELENLÉTI ÍV MEGTEKINTÉSE'
    , WorkingRecord: 'Jelenléti ív'
    , workingRecord_id: '#'
    , workingRecord_workNumber1_id: 'Munkaszám'
    , workingRecord_workingDate: 'Dátum'
    , workingRecord_place: 'Hely megnevezés és cím'

    , WorkingTimeRecordInsert: 'IDŐPONT FELVITELE'
    , WorkingTimeRecordUpdate: 'IDŐPONT MÓDOSÍTÁSA'
    , WorkingTimeRecordView: 'IDŐPONT MEGTEKINTÉSE'
    , WorkingTimeRecord: 'Munkaidő nyilvántartás'
    , workingTimeRecord_id: '#'
    , workingTimeRecord_employe_id: 'Munkaválaló'
    , workingTimeRecord_workingRecord_id: 'Jelenléti ív'
    , workingTimeRecord_workingDateTimeFrom: 'Tól időpont'
    , workingTimeRecord_workingDateTimeTo: 'Ig időpont'
    , workingTimeRecord_isAbsence: 'Ez egy távollét'


    , SalaryDeductionInsert: 'LEVONÁS FELVITELE'
    , SalaryDeductionUpdate: 'LEVONÁS MÓDOSÍTÁSA'
    , SalaryDeductionView: 'LEVONÁS MEGTEKINTÉSE'
    , SalaryDeduction: 'Levonások'
    , salaryDeduction_id: '#'
    , salaryDeduction_employe_id: 'Munkavállaló'
    , salaryDeduction_workingRecord_id: 'Jelenléti ív'
    , salaryDeduction_amount: 'Levonás összege'

    , OtherBenefitsInsert: 'EGYÉB JUTTATÁSOK FELVITELE'
    , OtherBenefitsUpdate: 'EGYÉB JUTTATÁSOK MÓDOSÍTÁSA'
    , OtherBenefitsView: 'EGYÉB JUTTATÁSOK MEGTEKINTÉSE'
    , OtherBenefits: 'Egyéb juttatások'
    , otherBenefits_id: '#'
    , otherBenefits_employe_id: 'Munkavállaló'
    , otherBenefits_workingRecord_id: 'Jelenléti ív'
    , otherBenefits_amount: 'Juttatás összege'

    , UserRoles: 'Jogosultságok'

    , ChequeInsert: 'CSEKK FELVITELE'
    , ChequeUpdate: 'CSEKK MÓDOSÍTÁSA'
    , ChequeView: 'CSEKK MEGTEKINTÉSE'
    , Cheque: 'Csekk'
    , cheque_id: '#'
    , cheque_workNumber_id: 'Munkaszám'
    , cheque_workNumber_customerAddress_id: 'Ügyfélcím'
    , cheque_chequeOwner_id: 'Tulajdonos'
    , cheque_chequePayer_id: 'Kibocsátó'
    , cheque_address: 'Hely (Munkaszámból)'
    , cheque_title: 'Megnevezés'
    , cheque_amount: 'Összeg'
    , cheque_paid: 'Fizetve'
    , cheque_remark: 'Megjegyzés'
    
    , ChequeRemarkInsert: 'MEGJEGYZÉSEK FELVITELE'
    , ChequeRemarkUpdate: 'MEGJEGYZÉSEK MÓDOSÍTÁSA'
    , chequeRemark: 'CSEKK MÓDOSÍTÁSA'
    , ChequeRemark: 'Megjegyzés'
    , chequeRemark: 'Megjegyzés'
    , chequeRemark_id: '#'
    , chequeRemark_remark: 'Megjegyzés'

    , ChequeOwnerInsert: 'CSEKK TULAJDONOS FELVITELE'
    , ChequeOwnerUpdate: 'CSEKK TULAJDONOS MÓDOSÍTÁSA'
    , ChequeOwnerView: 'CSEKK TULAJDONOS MEGTEKINTÉSE'
    , ChequeOwner: 'Csekk tulajdonosa'
    , chequeOwner_id: '#'
    , chequeOwner_ownerName: 'Neve'
    , chequeOwner_address: 'Címe'

    , ChequePayerInsert: 'CSEKK KIBOCSÁTÓ FELVITELE'
    , ChequePayerUpdate: 'CSEKK KIBOCSÁTÓ MÓDOSÍTÁSA'
    , ChequePayerView: 'CSEKK KIBOCSÁTÓ MEGTEKINTÉSE'
    , ChequePayer: 'Csekk kibocsátó'
    , chequePayer_id: '#'
    , chequePayer_payerName: 'Neve'
    , chequePayer_address: 'Címe'
    , ChequesOwner: 'Tulajadonos'
    , ChequesPayer: 'Kibocsátó'


    , InvoiceIncoming: 'Bejövő számla'
    , InvoiceOutgoing: 'Kimenő számla'
    , InvoiceInsert: 'SZÁMLA FELVITELE'
    , InvoiceUpdate: 'SZÁMLA MÓDOSÍTÁSA'
    , InvoiceView: 'SZÁMLA TÖRLÉSE'
    , Invoice: 'Számla'
    , invoice_id: '#'
    , invoice_invoiceDirection_id: 'Számla iránya'
    , invoice_workNumber_id: 'Munkasz.'
    , invoice_dateOfCompletion: 'Telj. ideje'
    , invoice_invoiceDate: 'Számla kelte'
    , invoice_transferDate: 'Átutalás dátuma'
    , invoice_paymentDeadline: 'Fiz. hat. idő'
    , invoice_paymentDate: 'Kifizetés dátuma'
    , invoice_companyName: 'Cégnév'
    , invoice_invoiceNumber: 'Sz. szám'
    , invoice_VAT_id: 'ÁFA kulcs'
    , invoice_VAT_name: 'ÁFA kulcs'
    , invoice_VATvalue: 'ÁFA %'
    , invoice_priceNET: 'Nettó'
    , invoice_priceVAT: 'ÁFA'
    , invoice_priceGROSS: 'Bruttó'
    , invoice_holdingBack: 'Visszartartás'
    , invoice_moneyTransfer: 'Utalandó'
    , invoice_paidInCash: 'Kp-s számla'
    , invoice_other: 'Egyéb'
    , invoice_remark: 'Megjegyzés'
    , invoice_invoiceName: 'Megnevezés'
    , invoice_customerId: 'Cég neve'
    , invoice_customerBankAccountNumber_id:'Bankszámlaszám'
    , invoice_bankAccountNumber:'Bankszámlaszám'

    , InvoiceDirection: 'Számla iránya'
    , invoiceDirection_id: '#'
    , invoiceDirection_directionName: 'Megnevezés'
    , invoiceDirection_modifier: 'Szorzó'

    , analytics: 'ÁFA analitika'

    , PayWithCash: 'Készpénz'
    , PayWithTransfer: 'Átutalás'

    , WorkNumberListView: 'MUNKASZÁM MEGTEKINTÉSE'
    , WorkNumberList: 'Projektek'

    , VatInsert: 'Áfa rögzítése'
    , VatUpdate: 'Áfa törlése'
    , VatView: 'Áfa megtekintése'

    , CalendarUpdate: 'Jelenléti ív'
    , Calendar: 'Jelenléti ív'
    , calendar_id: '#'
    , calendar_employe_id: 'Nevek'
    , calendar_workingDate: 'Dátum'
    , calendar_workingDay: 'Nap'
    , calendar_customerAddress1_id: 'Hely'
    , calendar_workNumber1_id: 'M. szám'
    , calendar_numberOfHoursSum: 'Óra'
    , calendar_otherBenefits: 'Egyéb költs'
    , calendar_remark: 'Megjegyzés'
    , calendar_salaryDeduction: 'Levonás'
    , calendar_summary: 'Fizetendő'
    , calendar_calendarName: 'Név'


    , OtherCostsInsert: 'Egyéb költség felvitel'
    , OtherCostsUpdate: 'Egyéb költség módosítás'
    , OtherCostsView: 'Egyéb költség megtekintés'
    , OtherCosts: 'Egyéb költségek'
    , otherCosts_id: '#'
    , otherCosts_costName: 'Megnevezés'
    , otherCosts_remark: 'Megjegyzés'
    , otherCosts_amount: 'Összeg'
    , otherCosts_workNumber: 'Munkaszám'
    , otherCosts_firstDate: 'Tól'
    , otherCosts_lastDate: 'Ig'
    , otherCosts_dateIntervall: 'Dátum periódus'
    , otherCosts_otherCostsType_id: 'Státusz'
    , otherCostsType_typeName: 'Státusz'

    , HouseTreasuryInsert: 'Tétel hozzáadás'
    , HouseTreasuryUpdate: 'Tétel módosítás'
    , HouseTreasuryView: 'Tétel megtekintése'
    , HouseTreasuryDelete: 'Tétel törlése'
    , HouseTreasury: 'Házipénztár'
    , houseTreasury_id: '#'
    , houseTreasury_inDate: 'Dátum'
    , houseTreasury_inName: 'Megnevezés'
    , houseTreasury_inPlace: 'Költséghely'
    , houseTreasury_inWorkNumber_id: 'Munkaszám'
    , houseTreasury_inWorkNumberName:'Munkaszám'
    , houseTreasury_inAmount: 'Összeg'
    , houseTreasury_outDate: 'Dátum'
    , houseTreasury_outName: 'Megnevezés'
    , houseTreasury_outPlace: 'Munkahely'
    , houseTreasury_outWorkNumber_id: 'Munkaszám'
    , houseTreasury_outWorkNumberName:'Munkaszám'
    , houseTreasury_outAmount: 'Összeg'
    , houseTreasury_inSum: 'Bevétel'
    , houseTreasury_outSum: 'Kiadás'
    , houseTreasury_grossSum: 'Eredmény'
    , houseTreasury_addToResultsOutgoing:' '

    
    , Cheque: 'Csekk'
    , cheque_id: '#'
    , cheque_customer_id: 'Név'
    , cheque_workNumber_id: 'Munkaszám'
    , cheque_customerAddress_id: 'Felhasználási hely'
    , cheque_customerAddress: 'Ügyfélcím'
    , cheque_grossValue: 'Összeg (Bruttó)'
    , cheque_dateOfArrival: 'Érkezett'
    , cheque_remark: 'Megjegyzés'
    , cheque_paymentDate: 'Befizetve'
    , cheque_transferable: 'Fizetendő'
    , cheque_chequeIssuer_id: 'Szolgáltató'

    , ChequeIssuerContent: 'Szolgáltató'
    , ChequeIssuerInsert: 'Szolgáltató felvitele'
    , ChequeIssuerUpdate: 'Szolgáltató módosítása'
    , ChequeIssuer: 'Szolgáltató'
    , chequeIssuer_id: '#'
    , chequeIssuer_issuerName: 'Szolgáltató'
    , Updoad:'Google Drive Feltöltés'

    ,Results:'Eredménykimutatás'
};
