import React, { Component } from 'react';
import EasyTableResults from './EasyTableResults';
import {Button, Col, Container} from 'react-bootstrap';
import * as ajax from './../../ajax';

class Sum extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.filters = {};
        this.dataLoader();

        this.sum = 0.0;

        this.agreedPriceNET = 0.0;
        this.incomingSum = 0.0;
        this.outgoingSum = 0.0;
        this.resultSum = 0.0;

    }

    componentDidUpdate(prevProps) {



        if (this.props.date_from != prevProps.date_from) {
            this.dataLoader();

        }
        if (this.props.date_to != prevProps.date_to) {
            this.dataLoader();

        }
        if (this.props.workNumber != prevProps.workNumber) {
            this.dataLoader();

        }
        return false;
    }

    dataLoader = function ( ) {
        this.filters["exportMode"] = this.exportMode;
        var state = this.state;
        try {
            const url = ajax.getUrlBase() + "modules/Results/index.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    date_from: this.props.date_from,
                    date_to: this.props.date_to,
                    workNumber: this.props.workNumber,
                    pageName: 'Sum'
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        this.agreedPriceNET = (new Number(Math.round(response.extra.agreedPriceNET))).toLocaleString();
                        this.incomingSum = (new Number(Math.round(response.extra.incomingSum))).toLocaleString();
                        this.outgoingSum = (new Number(Math.round(response.extra.outgoingSum))).toLocaleString();
                        this.resultSum = (new Number(Math.round(response.extra.resultSum))).toLocaleString();


                        this.forceUpdate();
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        return (<Container>
            <div style={{border: 'solid 1px #00000066', padding: 5, borderRadius: 10, maxWidth: 270, marginLeft: 0, marginTop: 45, minHeight: '100%'}}>
                <table style={{minWidth: '100%'}}>    
                    <tbody>
                        <tr>
                            <td style={{textAlign: 'left'}}>Vállalási ár:</td>
                            <td style={{textAlign: 'right'}}>{this.agreedPriceNET}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}}>Össz bevétel:</td>
                            <td style={{textAlign: 'right'}}>{this.incomingSum}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}}>Össz kiadás:</td>
                            <td style={{textAlign: 'right'}}>{this.outgoingSum}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'left'}}>Eredmény:</td>
                            <td style={{textAlign: 'right'}}><b>{this.resultSum}</b></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
        </Container>
                );
    }
}

export default Sum;
