import React, { Component } from 'react';
import './../../css/login.css';
import * as ajax from './../../ajax';
import Logo from './../../img/MZS-logo-250-min-1.png';
import PasswordChange from './PasswordChange';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordSended:false,
            passwordChangeVisible: false,
            loginSuccess: true,
            userName: '',
            password: '',
            fail:''
        }

        this.onLoginCheck();
    }

    onLoginCheck = function () {
        try {
            const url = ajax.getUrlBase() + "secure/loginCheck.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.props.setLoginState(response);
                        } else {
                            this.setState({loginSuccess: false,fail:'Sikertelen bejelentkezés'});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    onLogin = function () {
        try {
            const url = ajax.getUrlBase() + "secure/login.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({userName: this.state.userName, password: this.state.password})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.state.passwordSended=true;
                        if (response.success) {
                            
                            this.props.setLoginState(response);
                        } else {
                            this.setState({loginSuccess: false,fail:response.fail});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    setUserName = function (e) {
        this.setState({userName: e.target.value})
    }

    setPassword = function (e) {
        this.setState({password: e.target.value})
    }

    passwordChange = function (e) {
        this.setState({passwordChangeVisible: true});
    }
    
    sendigPasswordChangeMail = function(){
          try {
            const url = ajax.getUrlBase() + "secure/passwordChangeEmail.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({userName: this.state.userName})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success==false) {
                            this.setState({fail:response.fail});
                        } else {
                            this.setState({passwordChangeVisible:true});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {

        var passwordChange = "";
        
        if (this.state.passwordChangeVisible) {
            passwordChange = <PasswordChange />
        }
        
        
        var loginSuccess = (this.state.passwordSended == true &&   this.state.loginSuccess==false ) ?'loginFail' : "loginSuccess";
        
        return <div className={"loginWindow"}>
            <div className={"loginBox"} >
                <div className={"loginRow"}>
                    <div style={{marginBottom: '30px'}} className={"loginInput"}>
                        <img className={"logoStyle"} src={Logo}/>
                    </div>
                </div>
                <div className={"loginHeader"}  >
                    <p className={"loginHeaderText"}>JELENTKEZZEN BE A FIÓKJÁBA</p>
                </div>
                <div className={"loginRow"}>
                    <div className={"loginInput"}>
                        <input placeHolder="Felhasználónév*" onChange={this.setUserName.bind(this)} className={"loginTextField"} value={this.state.userName} type="text" />
                    </div>
                </div>
                <div className={"loginRow"}>
                    <div className={"loginInput"}>
                        <input placeHolder="Jelszó*" onChange={this.setPassword.bind(this)} className={"loginTextField"} value={this.state.password} type="password" />
                    </div>
                </div>
        
                <div style={{display: 'flex', marginTop: '50px'}}>
                    <div style={{display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center'}}>
                        <button onClick={this.onLogin.bind(this)} className={"loginButton"} >Bejelentkezés</button>
                    </div>
                </div>
                <div style={{display: 'flex', marginTop: '0px'}}>
                    <div className={loginSuccess}>
                        {this.state.fail}
                    </div>
                </div>
            </div>
        
            
        
        </div>;
    }
}

export default Login;
