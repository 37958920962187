import React, { Component } from 'react';
import EasyTableAnalytics from './../../EasyTable/EasyTableAnalytics';
import VATAnalytics from './VATAnalytics';
import {Button, Col} from 'react-bootstrap';
import AnalyticsFilter from './AnalyticsFilter';
import * as ajax from './../../ajax';

class AnalyticsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {}

        var numberRenderer = (row, value) => {
            var numberStyle = {whiteSpace: 'nowrap', color: '#1880c9'};
            if (value == 0) {
                numberStyle.color = '#00000044';
            }
            var valStr = (isNaN(value)) ? '' : (new Number(value)).toLocaleString();
            if (valStr != '0') {
                return <div style={numberStyle}>{valStr}</div>;
            } else {
                return <div style={numberStyle}>{valStr}</div>;
            }
        };



        this.tableDescriptor = {
            tableHeader: {
                title: '',
                filterComboData: []
            },
            idColumn: 'outDate',
            titleVisible: true,
            columnDescriptors: [

                {order: 0, name: 'outDate', title: 'Dátum', renderer: null, flex: 1, visible: true}
                , {order: 1, name: 'outName', title: 'Számlaszám', renderer: null, flex: 1, visible: true}
                , {order: 2, name: 'outPlace', title: 'Cím', renderer: null, flex: 1, visible: true}
                , {order: 3, name: 'outWorkNumber', title: 'Munkaszám', renderer: null, flex: 1, visible: true}
                , {order: 4, name: 'outRowSummary', title: 'Összeg', renderer: numberRenderer, flex: 1, visible: true}

                , {order: 5, name: 'inDate', title: 'Dátum', renderer: null, flex: 1, visible: true}
                , {order: 6, name: 'inName', title: 'Számlaszám', renderer: null, flex: 1, visible: true}
                , {order: 7, name: 'inPlace', title: 'Cím', renderer: null, flex: 1, visible: true}
                , {order: 8, name: 'inWorkNumber', title: 'Munkaszám', renderer: null, flex: 1, visible: true}
                , {order: 9, name: 'inRowSummary', title: 'Összeg', renderer: numberRenderer, flex: 1, visible: true}




            ],
            data: [

            ]
        }
        this.filters = {};
        this.tableDescriptor.data = [];
        this.exportMode = 0;

        this.VATAnalytics = null;
        this.VATAnalyticsDisplayRef = React.createRef();



        this.dataLoader();


    }

    componentDidUpdate() {
        //  this.dataLoader();

    }

    dataLoader = function ( ) {
        this.filters["exportMode"] = this.exportMode;
        var state = this.state;
        try {
            const url = ajax.getUrlBase() + "modules/Analytics/analyticsTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.filters)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.tableDescriptor.data = response.data;
                        if (typeof response.extra.analytics != 'undefined') {
                            this.VATAnalytics = response.extra.analytics;
                            this.VATAnalyticsDisplayRef.current.update(this.VATAnalytics);
                            
                        }
                        this.forceUpdate();

                        if (this.exportMode == 1) {
                            this.exportMode = 0;
                            window.open(ajax.getUrlBase() + response.extra['pdf'], '_blank');
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getFilter = function () {

        return <Col style={{maxWidth: 260, minWidth: 260, paddingTop: 30}}>
        <AnalyticsFilter  
            reload={this.reload.bind(this)}  
            setHeaderFilterValues={this.setHeaderFilterValues.bind(this)} 
            />
            
            
        <div style={{width: 260}}><VATAnalytics ref={this.VATAnalyticsDisplayRef} data={this.VATAnalytics} /></div>
        
        </Col>
    }

    reload = function () {}
    setHeaderFilterValues = function (filters) {
        this.filters = filters;
        this.dataLoader();

    }

    render() {
        return (<div>
            <div style={{display: 'flex'}}>
                <div style={{margin: 10}}>
                    {this.getFilter()}
                </div>
        
                <div style={{
                                minWidth: '75vw',
                                maxWidth: '75vw',
                                overflowY: 'auto',
                                minHeight: '80vh',
                                maxHeight: '80vh',

                                 }} id="AnalyticsTable">
                    <EasyTableAnalytics 
                        tableDescriptor={this.tableDescriptor} 
                        />
                </div>
        
            </div>
            <div style={{padding: 5}}>
                <Button className={'btn-sm'} id={"Export"} onClick={() => {
                                this.exportMode = 1;
                                this.dataLoader();
                                    }} variant="info">Exportálás</Button>
            </div>
        </div>
                );
    }
}

export default AnalyticsTable;
