import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import {Button,Col,Row} from 'react-bootstrap';
import './../css/easyTableAnalytics.css';
import DefaultHeader from './DefaultHeader';
import DefaultCell from './DefaultCell';


class EasyTableAnalytics extends Component {

    constructor(props) {
        super(props);
    }

    getColumNameByOrder = function (tableDescriptor, order) {
        var columnDescriptors = tableDescriptor.columnDescriptors;
        for (var i = 0; i < columnDescriptors.length; i++) {
            var columnDescriptor = columnDescriptors[i];
            if (columnDescriptor.order == order) {
                return columnDescriptor.name;
            }
        }
    }

    getColumNamesByOrder = function (tableDescriptor) {
        var columnDescriptors = tableDescriptor.columnDescriptors;
        var columnNamesByOrder = [];

        for (var i = 0; i < columnDescriptors.length; i++) {
            var columnName = this.getColumNameByOrder(tableDescriptor, i);
            columnNamesByOrder.push(columnName);
        }
        return columnNamesByOrder;
    }

    getColumnDescriptorByName = function (tableDescriptor, columnName) {
        var columnDescriptors = tableDescriptor.columnDescriptors;
        for (var i = 0; i < columnDescriptors.length; i++) {
            if (columnDescriptors[i].name == columnName) {
                return columnDescriptors[i];
            }
        }
        return null;
    }

    getTableCell = function (rowData, tableDescriptor, columnName) {
        var columnDescriptor = this.getColumnDescriptorByName(tableDescriptor, columnName);
        if (typeof columnDescriptor.renderer == 'undefined') {
            return <DefaultCell row={rowData} data={rowData[columnName]} />;
        }
        if (columnDescriptor.renderer == null) {
            return <DefaultCell row={rowData} data={rowData[columnName]} />;
        }

        return columnDescriptor.renderer(rowData, rowData[columnName]);
    }

    getTableRow = function (rowData, tableDescriptor, columnNamesByOrder) {

        var row = [];
        for (var i = 0; i < columnNamesByOrder.length; i++) {
            var tableCell = this.getTableCell(rowData, tableDescriptor, columnNamesByOrder[i]);
            row.push(<td className="easyTableAnalyticsTd">{tableCell}</td>);
        }

        return <tr>{row}</tr>;
    }

    getTableRows = function (tableDescriptor, columnNamesByOrder) {
        var tableRows = tableDescriptor.data;
        var rows = []
        for (var i = 0; i < tableRows.length; i++) {
            var tableRow = this.getTableRow(tableRows[i], tableDescriptor, columnNamesByOrder);
            rows.push(tableRow);
        }
        return <tbody  className="easyTableAnalyticsBody">{rows}</tbody>
    }

    getTableHeader = function (tableDescriptor, columnNamesByOrder) {
        if (tableDescriptor.titleVisible == false) {
            return null;
        }

        var tableGroupTitles = [];
        tableGroupTitles.push(<th colSpan={5} className="easyTableAnalyticsTh"><u>BEJÖVŐ SZÁMLÁK</u></th>);
        tableGroupTitles.push(<th colSpan={5}  className="easyTableAnalyticsTh"><u>KIMENŐ SZÁMLÁK</u></th>);

        var tableTitles = [];
        for (var i = 0; i < columnNamesByOrder.length; i++) {
            var columnDescriptor = this.getColumnDescriptorByName(tableDescriptor, columnNamesByOrder[i]);
            tableTitles.push(<th className="easyTableAnalyticsTh">{columnDescriptor.title}</th>);
        }
        return <thead><tr>{tableGroupTitles}</tr><tr>{tableTitles}</tr></thead>;
    }

   

    render() {
        var tableDescriptor = this.props.tableDescriptor;
        var columNamesByOrder = this.getColumNamesByOrder(tableDescriptor);
        var header = this.getTableHeader(tableDescriptor, columNamesByOrder);
        var rows = this.getTableRows(tableDescriptor, columNamesByOrder);
        return <div  className="block">
          
            <div>
                <table  className="easyTableAnalytics">
                    {header}
                    {rows}
                </table>
            </div>
        </div>
    }
}

export default EasyTableAnalytics;

