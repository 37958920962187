import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import MaskedInsertField from './../../components/MaskedInsertField';


class MTOEmployePhoneInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employePhone_phoneNumber: ''

        }

        this.references = [];
        this.references['employePhone_phoneNumber'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({employePhone_phoneNumber: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});

    }

    getForm = function () {
        return <Container  theme={this.props.theme}>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employePhone_phoneNumber')}</Col>
                <Col style={{textAlign: 'right'}}>
                <MaskedInsertField 
                    id={'employePhone_phoneNumber'} 
                    value={this.state.employePhone_phoneNumber}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['employePhone_phoneNumber']}
                    editable={true}
                    theme={this.props.theme}
                    regexPattern={new RegExp(/^\+[0-9]{2}-[0-9]{2}-[0-9]{7}$/)}
                    placeHolder="+36-30-1234567"
                    />
        
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    addRow = function () {
        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {
        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} >
            <div className={'popupWrapper' + className} id="MTOEmployePhoneInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('MTOEmployePhoneInsert')}</Col>
                        <Col style={{textAlign: 'right', paddingRight: 30}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOEmployePhoneInsertMTO;


