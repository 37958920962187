import React, { Component } from 'react';
import EasyTable from './../../EasyTable/EasyTable';


class NewTableTest extends Component {

    constructor(props) {
        super(props);
        this.tableDescriptor = {
            tableHeader: {
                title: '',
                filterComboData: []
            },
            idColumn: 'id',
            titleVisible: true,
            columnDescriptors: [
                {order: 0, name: 'id', title: '#', renderer: null, flex: 1, visible: false},
                {order: 1, name: 'customer', title: 'Customer', renderer: null, flex: 1, visible: true},
                {order: 2, name: 'order', title: 'Order', renderer: null, flex: 1, visible: true,
                    renderer: (row, data) => {

                        return <div 
                        style={{
                            color: 'white',
                            fontWeight:'bold',
                            borderRadius:30,
                            backgroundColor:(row.status==1)?'#68bb69':'#f65f6e',
                            textAlign:'center',
                            minWidth:100
                        }}>{data}</div>;
                    }


                },
                {order: 3, name: 'location', title: 'Location', flex: 1, visible: true},
                {order: 4, name: 'status', title: 'Status', flex: 1, visible: true,
                    renderer: (row, data) => {
                        if (data == 1) {
                            return <div style={{color: '#68bb69'}}>success</div>;
                        }
                        return <div style={{color: '#f65f6e'}}>fail</div>;
                    }

                },
                {order: 5, name: 'order_total', title: 'Order total', flex: 1, visible: true,
                    renderer: (row, data) => {
                        return <div style={{color: '#1880c9'}}>${data}</div>;
                    }

                }
            ],
            data: [
                {id: 1, customer: 'Test customer', order: 'OR-001', location: 'New York', status: '1', order_total: 220},
                {id: 2, customer: 'Test customer', order: 'OR-002', location: 'New York', status: '1', order_total: 220},
                {id: 3, customer: 'Test customer', order: 'OR-003', location: 'New York', status: '0', order_total: 220},
                {id: 4, customer: 'Test customer', order: 'OR-004', location: 'New York', status: '0', order_total: 220},
                {id: 5, customer: 'Test customer', order: 'OR-005', location: 'New York', status: '1', order_total: 220},
                {id: 6, customer: 'Test customer', order: 'OR-006', location: 'New York', status: '1', order_total: 220},
                {id: 7, customer: 'Test customer', order: 'OR-007', location: 'New York', status: '1', order_total: 220},
                {id: 8, customer: 'Test customer', order: 'OR-008', location: 'New York', status: '1', order_total: 220},
                {id: 9, customer: 'Test customer', order: 'OR-009', location: 'New York', status: '0', order_total: 220}
            ]
        }
    }

    render() {
        return (
                <div  id="NewTableTest">
                    <EasyTable tableDescriptor={this.tableDescriptor} />
                </div>
                );
    }
}

export default NewTableTest;
