
import React, { Component } from 'react';
import EmployeAddressTable from './EmployeAddressTable.js';
import EmployeAddressInsertForm from './EmployeAddressInsertForm.js';
import EmployeAddressUpdateForm from './EmployeAddressUpdateForm.js';
import EmployeAddressViewForm from './EmployeAddressViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class EmployeAddressContent extends Component {

    constructor(props) {
        super(props);
        this.state = {theme: this.props.theme}
    }
  shouldComponentUpdate(nextProps,nextState){
        return false;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    setBack = function (id, value) {
        // Nothing to do here
    }
    render() {

        var table = <EmployeAddressTable 
            setBack={this.setBack.bind(this)} 
            additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
            formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
            theme={this.state.theme}
            />;



        return (
                <div  id="EmployeAddressContent">
                    <div className="tableTitle"><b>{lang.getTitle("EmployeAddress")}</b></div> 
                    {table}
                </div>
                );
    }
}

export default EmployeAddressContent;
