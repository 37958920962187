
import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import Tree from './../../components/Tree.js';
import TextInsertField from './../../components/TextInsertField';
import MultiFileChooser from './../../components/MultiFileChooser';



class GoogleDriveFilesCustomerContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme,
            activeNodeId: null,
            newTitle: '',
            openedTreeNodes: []
        }

        this.nodes = [];
        this.load();
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    hasChildren = function (id) {
        var nodes = this.nodes;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            if (node.parentId == id) {
                return true;
            }
        }
        return false;
    }

    openAllNodes = function () {
        var nodes = this.nodes;
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            var hasChildren = this.hasChildren(node.id);
            if (hasChildren) {
                var isNodeOpened = this.state.openedTreeNodes.indexOf(node.id) > -1;
                if (isNodeOpened == false) {
                    this.state.openedTreeNodes.push(node.id);
                }
            }
        }

    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFilesCustomer/googleDriveFilesCustomerFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id, workNumberId: this.props.workNumberId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            self.nodes = response.data;
                            self.openAllNodes();
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    insertNode = function () {
        var self = this;


        self.state.openedTreeNodes.push(self.state.activeNodeId);
        self.openAllNodes();


        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFilesCustomer/googleDriveFilesCustomerFormInsert.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: self.state.activeNodeId, title: self.state.newTitle, workNumberId: this.props.workNumberId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.nodes = response.data;
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    deleteNodeStructure = function (id) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/GoogleDriveFilesCustomer/googleDriveFilesCustomerFormDelete.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({googleDriveFiles_id: id, workNumberId: this.props.workNumberId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (response.success) {

                            this.nodes = response.data;
                            self.state.activeNodeId = null;
                            self.forceUpdate();
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getTitleField = function () {

        return <div style={{display: 'flex', width: '100%',alignItems:'center'}}>
            <div style={{margin: 5}}>
                <TextInsertField 
                    id={'newTitle'} 
                    value={this.state.newTitle}  
                    setBack={this.setBack.bind(this)}
                    editable={true}
                    theme={this.props.theme}
                    />
            </div>
            <div>
                <Button className={'btn-sm'} variant="info" onClick={this.insertNode.bind(this)}>{lang.getTitle('Insert')}</Button>
            </div>
        
        </div>

    }

    treeClickHandler = function (e, action) {
        if (action == 'SELECT') {
            this.setState({activeNodeId: e.id});
        }

        if (action == 'DELETE') {
            this.deleteNodeStructure(e.id);
        }
    }

    render() {
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var titleField = this.getTitleField();
        var header = (this.state.activeNodeId != null) ? titleField : <div style={{minHeight: 37}}>Új elem akkor adható hozzá, ha kiválaszt egy mappát.<br/> A mappa kiválasztásához kattintson a mappa nevére.</div>;
        return (<div style={{minHeight:420,minWidth:900}}  id="GoogleDriveFilesCustomerContent">
                    <div  >{header}</div>
                    <div  style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <Tree 
                                onNodeSelect={(e, action) => {
                                        this.treeClickHandler(e, action);
                                    }} 
                                nodes={this.nodes} 
                                openedTreeNodes = {this.state.openedTreeNodes}    
                                parentId={null} 
                                treeActiveNodeId ={this.state.activeNodeId}    
                                theme={this.props.theme}
                                />        
                        </div>
                        <div style={{flex: 1}}>
                            <MultiFileChooser     
                                multiUploadFileChooserClose={()=>{}}
                                blackTheme={this.props.blackTheme}
                                invoiceDirection ={"incoming"}
                                alertDialog={()=>{}}
                                activeNodeId={this.state.activeNodeId}
                                />
                        </div>
                    </div>
                </div>
                );
    }
}

export default GoogleDriveFilesCustomerContent;
