import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './../../components/PagingToolbar';
import InfoTableToolbar from './InfoTableToolbar';

import {Button, Row, Col} from 'react-bootstrap';

import InsertForm from './InvoiceInsertForm.js';
import UpdateForm from './InvoiceUpdateForm.js';
import ViewForm from './InvoiceViewForm.js';
import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCell';
import FormatedNumberCell from './../../components/FormatedNumberCell';
import InvoiceFilter from './../../ComponentsSpecial/InvoiceFilter';
import VATAnalytics from './../InvoiceIncoming/VATAnalytics';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class InvoiceTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'invoice_id',
            'invoice_dateOfCompletion',
            'invoice_invoiceDate',
            'invoice_paymentDeadline',
            'invoice_paymentDate',
            'invoice_companyName',
            'invoice_invoiceNumber',
            'workNumber_workNumberFullName',
            'invoice_VAT_id',
            'invoice_VAT_name',

            'invoice_priceNET',
            'invoice_priceVAT',
            'invoice_priceGROSS',
            'invoice_paidInCash',
            'invoice_moneyTransfer',

            'invoice_invoiceName',
            'invoice_VATvalue',
            'invoice_invoiceDirection_id',
            'invoice_workNumber_id',
            'invoice_other',
            'invoice_remark'
        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "InvoiceOutgoing", "invoice");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 1000000,
                showingRowFrom: 0,
                showingRowTo: 1000000,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();


        // Filterhez
        this.invoice_date_from = '';
        this.invoice_date_to = '';
        this.invoice_workNumber = '';
        this.invoice_Customer = '';
        this.showFilter = true;

        this.netValueSum = 0.0;
        this.vatValueSum = 0.0;
        this.grossValueSum = 0.0;

        this.exportMode = 0;
        
        this.VATAnalytics=null;
        this.VATAnalyticsOutRef =React.createRef();


    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {

        return <InfoTableToolbar 
            blackTheme={this.props.theme} 
            netValueSum={this.netValueSum}
            vatValueSum={this.vatValueSum}
            grossValueSum={this.grossValueSum}
            />
        //return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    setFilterValue = function (filters, operator, attr, value) {
        for (var i = 0; i < filters.length; i++) {
            if (filters[i].name == attr) {
                filters[i].operator = operator;
                filters[i].value = value;
            }
        }
    }

    removeEmptyWorkNumbers = function (originalArray) {
        if (typeof originalArray == 'undefined') {
            return [];
        }
        if (originalArray == null) {
            return [];
        }
        if (originalArray.length == 0) {
            return [];
        }

        var validArray = [];

        for (var i = 0; i < originalArray.length; i++) {
            var originalItem = originalArray[i];
            var validValue = (originalItem != null && originalItem != '' && originalItem != -1) ? true : false;
            if (validValue) {
                validArray.push(originalItem);
            }
        }
        return validArray;

    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);

        // Külső dátum filter

        if (this.invoice_date_from != '' && this.invoice_date_to != '') {
            // this.setFilterValue(filterValue, 'inrange', 'invoice_invoiceDate', {start: this.invoice_date_from, end: this.invoice_date_to});
            this.setFilterValue(filterValue, 'inrange', 'invoice_dateOfCompletion', {start: this.invoice_date_from, end: this.invoice_date_to});
        } else {
           // this.setFilterValue(filterValue, 'eq', 'invoice_invoiceDate', '');
            this.setFilterValue(filterValue, 'eq', 'invoice_dateOfCompletion', '');
        }

        // Ügyfél filter
        if (this.invoice_Customer != '') {
            this.setFilterValue(filterValue, 'eq', 'invoice_customerId', this.invoice_Customer);
        } else {
            this.setFilterValue(filterValue, 'eq', 'invoice_customerId', '');
        }


        // Munkaszám filter
        if (this.invoice_workNumber != '') {
            var validValues = this.removeEmptyWorkNumbers(this.invoice_workNumber);
            this.setFilterValue(filterValue, 'eq', 'invoice_workNumber_id', validValues);
        } else {
            this.setFilterValue(filterValue, 'eq', 'invoice_workNumber_id', '');
        }


        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Invoice/invoiceTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, exportMode: this.exportMode})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (this.exportMode == 3) {
                            this.exportMode = 0;
                            window.open(ajax.getUrlBase() + response.extra['pdf'], '_blank');
                        }

                        if (typeof response.extra != 'undefined') {

                            var extra = response.extra;

                            this.netValueSum = extra['netValueSum'];
                            this.vatValueSum = extra['vatValueSum'];
                            this.grossValueSum = extra['grossValueSum'];

                            if (typeof response.extra.analytics != 'undefined') {
                                this.VATAnalytics = response.extra.analytics;
                                this.VATAnalyticsOutRef.current.update(this.VATAnalytics);

                            }

                        } else {

                            this.netValueSum = 0.0;
                            this.vatValueSum = 0.0;
                            this.grossValueSum = 0.0;
                        }


                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Invoice/invoiceFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({invoice_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.invoice_id == 'undefined') {
            return false;
        }

        if (rowProps.data.invoice_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.invoice_id, openedForm: this.FORM_UPDATE});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    getFilter = function () {
        if (this.showFilter == false) {
            return "";
        }
        return <Col style={{maxWidth: 260, minWidth: 260}}>
        <InvoiceFilter  reload={this.reload.bind(this)}  setHeaderFilterValues={this.setHeaderFilterValues.bind(this)} />
        <div style={{width: 300,backgroundColor:'white'}}><VATAnalytics ref={this.VATAnalyticsOutRef} data={this.VATAnalytics} /></div>
        </Col>
    }

    setHeaderFilterValues = function (filterState) {

        this.invoice_date_from = filterState.invoice_date_from;
        this.invoice_date_to = filterState.invoice_date_to;
        this.invoice_workNumber = filterState.invoice_workNumber;
        this.invoice_Customer = filterState.invoice_customer;
        this.invoice_CustomerAddressArray = filterState.invoice_customerAddress;

        this.reload();
    }

    removeNegative = function (currentArray) {
        var result = [];
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] * 1 != -1 && currentArray[i] != '') {
                result.push(currentArray[i]);
            }
        }
        return result;
    }

    render() {


        var isViewForm = this.isViewForm();
        var columns = [

            {name: 'invoice_id', sortable: true, header: lang.getTitle('invoice_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'invoice_customerId', sortable: true, header: lang.getTitle('invoice_customerId'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_customerId', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'invoice_invoiceDirection_id', sortable: true, header: lang.getTitle('invoice_invoiceDirection_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_invoiceDirection_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'invoice_workNumber_id', sortable: true, header: lang.getTitle('invoice_workNumber_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_workNumber_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'invoice_dateOfCompletion', sortable: true, header: lang.getTitle('invoice_dateOfCompletion'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_dateOfCompletion', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'invoice_invoiceDate', sortable: true, header: lang.getTitle('invoice_invoiceDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_invoiceDate', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'invoice_paymentDeadline', sortable: true, header: lang.getTitle('invoice_paymentDeadline'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_paymentDeadline', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'invoice_paymentDate', sortable: true, header: lang.getTitle('invoice_transferDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_paymentDate', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'invoice_companyName', sortable: true, header: lang.getTitle('invoice_companyName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_companyName', 150), defaultVisible: true}
            , {name: 'invoice_invoiceNumber', sortable: true, header: lang.getTitle('invoice_invoiceNumber'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_invoiceNumber', 150), defaultVisible: true}
            , {name: 'workNumber_workNumberFullName', sortable: true, header: lang.getTitle('workNumber_workNumberFullName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('workNumber_workNumberFullName', 370), defaultVisible: true}
            , {name: 'invoice_VAT_name', sortable: true, header: lang.getTitle('invoice_VAT_name'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_VAT_name', 150), defaultVisible: true}
            , {name: 'invoice_VAT_id', sortable: true, header: lang.getTitle('invoice_VAT_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_VAT_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'invoice_VATvalue', sortable: true, header: lang.getTitle('invoice_VATvalue'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_VATvalue', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'invoice_priceNET', sortable: true, header: lang.getTitle('invoice_priceNET'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_priceNET', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'invoice_priceVAT', sortable: true, header: lang.getTitle('invoice_priceVAT'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_priceVAT', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'invoice_priceGROSS', sortable: true, header: lang.getTitle('invoice_priceGROSS'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_priceGROSS', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'invoice_moneyTransfer', sortable: true, header: lang.getTitle('invoice_moneyTransfer'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_moneyTransfer', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'invoice_paidInCash', sortable: true, header: lang.getTitle('invoice_paidInCash'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_paidInCash', 150), defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return (value == 1) ? 'igen' : 'nem';
                }
            }
            , {name: 'invoice_other', sortable: true, header: lang.getTitle('invoice_other'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_other', 150), defaultVisible: false}
            , {name: 'invoice_invoiceName', sortable: true, header: lang.getTitle('invoice_invoiceName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_invoiceName', 150), defaultVisible: false}
            , {name: 'invoice_remark', sortable: true, header: lang.getTitle('invoice_remark'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('invoice_remark', 150), defaultVisible: false}

        ];

        const filters = [
            {name: 'invoice_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_customerId', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_invoiceDirection_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_workNumber_id', operator: 'inlist', type: 'select', value: ''}
            , {name: 'invoice_dateOfCompletion', operator: 'eq', type: 'date', value: ''}
            , {name: 'invoice_invoiceDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'invoice_paymentDeadline', operator: 'eq', type: 'date', value: ''}
            , {name: 'invoice_paymentDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'invoice_companyName', operator: 'contains', type: 'string', value: ''}
            , {name: 'invoice_invoiceNumber', operator: 'contains', type: 'string', value: ''}
            , {name: 'workNumber_workNumberFullName', operator: 'contains', type: 'string', value: ''}
            , {name: 'invoice_VAT_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_VATvalue', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_priceNET', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_priceVAT', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_priceGROSS', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_moneyTransfer', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoice_paidInCash', operator: 'eq', type: 'boolean', value: ''}
            , {name: 'invoice_other', operator: 'contains', type: 'string', value: ''}
            , {name: 'invoice_remark', operator: 'contains', type: 'string', value: ''}

        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'invoice_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'invoice_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[(typeof this.props.additionalParams != 'undefined') ? this.props.additionalParams[0] : []]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm delete={this.delete.bind(this)} theme={this.props.theme} key={'invoice_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'invoice_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'invoice_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'invoice_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'invoice_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;

        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [insertButton];
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div  className={'buttonBar'} id="InvoiceTable">
                    <div style={{display: "flex"}}>
                        {buttons}&nbsp;&nbsp;&nbsp;
                        <Button className={'btn-sm'} id={"Export"} onClick={() => {
                                this.exportMode = 3;
                                this.reload();
                                    }} variant="info">Exportálás</Button>
                    </div>
                    <div style={{backgroundColor: 'white'}}>
                        <div style={{float: 'left', width: 300}}>{this.getFilter()}</div>
                    </div>
                    <div style={{marginLeft: 300}}>
                        <ReactDataGrid 
                            idProperty="invoice_id" 
                            i18n={ajax.getI18n()}
                            columns={this.columnStateHandler.myColumnsReorder(columns)}
                            dataSource={this.dataLoader.bind(this)}
                            defaultFilterValue={filters}
                            enableSelection={true}
                
                            onRowClick={this.onRowClick.bind(this)}
                            onSelectionChange={this.onSelectionChange.bind(this)}
                            theme={theme}
                            style={{height: '80vh', marginTop: 10}}    
                            rowHeight={25}
                            defaultLimit={1000000}
                            pagination={true}
                            renderPaginationToolbar={this.renderPaginationToolbar}
                            skip={this.state.skip}
                            limit={this.state.paging.pageRowCount}
                
                            onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                            onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                            onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                            enableFiltering={false}
                            showColumnMenuTool={false}
                
                            />
                    </div>
                    {openedForm}            
                </div>
                );
    }
}

export default InvoiceTable;
