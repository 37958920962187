import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import { Container, Row, Col, Button }
from 'react-bootstrap';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';

import WorkNumberFilterCombo from './../../modules/InvoiceIncoming/WorkNumberFilterCombo';
import CustomerFilterCombo from './../../modules/InvoiceIncoming/CustomerFilterCombo';

import DateInsertField from './../../components/DateInsertField';
import deleteIcon from './../../img/black/delete_black_24dp.svg';

class AnalyticsFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            periodYear: new Date().getFullYear(),
            invoice_date_from: '',
            invoice_date_to: '',
            invoice_workNumber: '',
            invoice_customerAddress: '',
            invoice_customer: ''


        }

        this.refWorkingDate_from = React.createRef();
        this.refWorkingDate_to = React.createRef();
        this.refWorkNumber = React.createRef();
        this.refCalendarCustomerAddress = React.createRef();
        this.refCustomer = React.createRef();
        this.currentYear = new Date().getFullYear();
        this.loadStoredFilters();

    }

    storeFilters = function () {

        localStorage.setItem('invoice_periodYear', this.state.periodYear);
        localStorage.setItem('invoice_date_from', this.state.invoice_date_from);
        localStorage.setItem('invoice_date_to', this.state.invoice_date_to);


    }

    componentWillUnmount = function () {
        this.storeFilters();
    }

    nullToEmptyString = function (val) {
        if (typeof val == 'undefined') {
            return '';
        }
        if (typeof val == null) {
            return '';
        }
        return val;

    }

    onYearChanged = function (e) {
        var value = e.target.value;
        this.state.invoice_date_from = value + '-01-01';
        this.state.invoice_date_to = value + '-12-31';
        this.props.setHeaderFilterValues(this.state);
    }

    getYearFilter = function () {
        var options = [];
        for (var i = 2022; i < 2100; i++) {
            options.push(<option selected={this.currentYear == i} key={'AnalitycsYearOption_key_' + i}  value={i}>{i}</option>);
        }

        return <select style={{minWidth: '100%', textAlign: 'left'}} onChange={this.onYearChanged.bind(this)} >{options}</select>;

    }

    loadStoredFilters = function () {

        this.state.invoice_periodYear = this.nullToEmptyString(localStorage.getItem('invoice_periodYear'));

        this.state.invoice_date_from = this.nullToEmptyString(localStorage.getItem('invoice_date_from'));
        this.state.invoice_date_to = this.nullToEmptyString(localStorage.getItem('invoice_date_to'));
        //  this.state.invoice_workNumber = (localStorage.getItem('invoice_workNumber') == '') ? [] : localStorage.getItem('invoice_workNumber').split(',');
        //  this.state.invoice_employe = (localStorage.getItem('invoice_employe') == '') ? [] : localStorage.getItem('invoice_employe').split(',');
        //  this.state.invoice_customerAddress = (localStorage.getItem('invoice_customerAddress') == '') ? [] : localStorage.getItem('invoice_customerAddress').split(',');
        //  this.state.invoice_customer = (localStorage.getItem('invoice_customer') == '') ? [] : localStorage.getItem('invoice_customer').split(',');
        //  console.log('Loaded state:', this.state)



        if (this.state.invoice_date_from == 'undefined') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.invoice_date_from == null) {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.invoice_date_from == '') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
        }

        if (this.state.invoice_date_to == 'undefined') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.invoice_date_to == null) {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.invoice_date_to == '') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
        }

        this.props.setHeaderFilterValues(this.state);


    }

    hasNewCell = function (currentArray) {

        // Ez azért kell, hogy ne ajánljon meg újabb szűrő filtereket.
        if (currentArray.length == 1) {
            return true;
        }
        // Ez azért kell, hogy ne ajánljon meg újabb szűrő filtereket. VÉGE

        if (currentArray.length == 0) {
            return false;
        }
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] == -1 || currentArray[i] == '') {
                return true;
            }
        }

        return false;
    }

    setCustomerFilter = function (id, value) {
        
        this.state.invoice_customer = value;
        this.props.setHeaderFilterValues(this.state);
        this.forceUpdate();
    }

    setWorkNumberFilter = function (id, value) {
        this.state.invoice_workNumber = value;
        this.props.setHeaderFilterValues(this.state);
    }

    setBack = function (id, value) {
        this.state[id] = value;
        this.props.setHeaderFilterValues(this.state);
        this.storeFilters();
    }

    deleteCustomer = function () {
        this.state.invoice_customer = -1;
        this.props.setHeaderFilterValues(this.state);
        this.forceUpdate();


    }

    reset = function () {
        this.state.invoice_date_from = '';
        this.state.invoice_date_to = '';
        this.state.invoice_workNumber = '';
        this.state.invoice_customerAddress = '';
        this.state.invoice_customer = '';
        this.props.setHeaderFilterValues(this.state);
        this.setState({});
    }

    render() {

        var refreshNeed = false;

        if (this.state.invoice_date_from == 'undefined') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            refreshNeed = true;

        }
        if (this.state.invoice_date_from == null) {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            refreshNeed = true;

        }
        if (this.state.invoice_date_from == '') {
            this.state.invoice_date_from = ajax.getFirstDayOfYear();
            refreshNeed = true;

        }

        if (this.state.invoice_date_to == 'undefined') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            refreshNeed = true;

        }
        if (this.state.invoice_date_to == null) {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            refreshNeed = true;

        }
        if (this.state.invoice_date_to == '') {
            this.state.invoice_date_to = ajax.getLastDayOfYear();
            refreshNeed = true;
        }
        if (refreshNeed == true) {
            this.props.setHeaderFilterValues(this.state);
        }

        var mandatory = "";
        if (this.state.invoice_date_from == '' || this.state.invoice_date_to == '') {
            mandatory = <tr>
                <td style={{color: 'red'}} colSpan={2}>A TÓL-IG INTERVALLUM MEGADÁSA KÖTELEZŐ</td>
            </tr>
        }

        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px'

                         }}>
            <table  style={{textAlign: 'center'}}  >               
                <tbody>               
                    <tr>
                        <td  colSpan={2}><b>FILTEREK</b></td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>ÉV</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>{this.getYearFilter()}</td>
                    </tr>
                    {mandatory}
                    <tr>
                        <td colSpan={1}>TÓL</td>
                        <td colSpan={1}>IG</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                <DateInsertField 
                    id={'invoice_date_from'} 
                    value={this.state.invoice_date_from}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_from}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                <td  colSpan={1}>
                <DateInsertField 
                    id={'invoice_date_to'} 
                    value={this.state.invoice_date_to}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_to}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                </tr>
                <tr>
                    <td  colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}} colSpan={2}>
                        ÜGYFELEK
                    </td>
                </tr>
        
                <tr>
                    <td colSpan={2}>
                        <div  key={"customerCounter"} style={{display: 'flex', margin: 5}}>
                            <div>
                                <CustomerFilterCombo 
                                    id={'customer'} 
                                    value={this.state.invoice_customer}  
                                    setBack={this.setCustomerFilter.bind(this)}
                                    dataSourceUrl={'modules/Invoice/customerFilterCombo.php'}
                                    reference={this.refCustomer}
                                    from={this.state.invoice_date_from}
                                    to={this.state.invoice_date_to}
                                    editable={true}
                                    theme={true}
                                    placeHolder={'Címek'}
                                    />
                            </div>    
                            <div>
                                <img id={"delete"} 
                                     onClick={() => {
                                                     this.deleteCustomer();
                                                 }} 
                                     style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                            </div>
                        </div>
        
        
                    </td>
                </tr>  
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}} colSpan={2}>
                        MUNKASZÁMOK
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div key={"workNumberCounter"} style={{display: 'flex', margin: 5}}>
                            <div>
                                <WorkNumberFilterCombo 
                                    id={'workNumber'} 
                                    value={this.state.invoice_workNumber}  
                                    setBack={this.setWorkNumberFilter.bind(this)}
                                    dataSourceUrl={'modules/Analytics/workNumberFilterCombo.php'}
                                    customerId ={this.state.invoice_customer}
                                    from={this.state.invoice_date_from}
                                    to={this.state.invoice_date_to}
                                    reference={React.createRef()}
                                    editable={true}
                                    theme={true}
                                    placeHolder={'Munkaszám'}
                                    />
                            </div>    
                            <div>
                                <img id={"delete"} 
                                     onClick={() => {
                                                     this.state.invoice_workNumber = '';
                                                     this.props.setHeaderFilterValues(this.state);
                                                 }} 
                                     style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                            </div>
                        </div>
                    </td>
                </tr>    
        
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
        
                </tbody>   
            </table>
            <div>
                <button 
                    style={{minWidth: '100%'}}
                    onClick={this.reset.bind(this)}
        
        
                    >Alaphelyzet</button>
            </div>
        
        </div>);
    }
}

export default AnalyticsFilter;

