import React, { Component } from 'react';
import './../css/menu.css';


import dropDownClosedWhite from "./../img/white/arrow_drop_down_white_24dp.svg";
import dropDownOpenedWhite from "./../img/white/arrow_right_white_24dp.svg";

import dropDownClosedBlack from "./../img/black/arrow_drop_down_black_24dp.svg";
import dropDownOpenedBlack from "./../img/black/arrow_right_black_24dp.svg";


import docOtherWhite from "./../img/white/note_add_white_24dp.svg";
import docOtherBlack from "./../img/black/note_add_black_24dp.svg";

import employeWhite from "./../img/white/engineering_white_24dp.svg";
import employeBlack from "./../img/black/engineering_black_24dp.svg";


import userRoles from "./../img/black/admin_panel_settings_black_24dp.svg";
import GoogleDriveFiles from "./../img/google-drive-social-media-svgrepo-com.svg";

import customerBlack from "./../img/black/account_box_black_24dp.svg";
import workNumberBlack from "./../img/black/receipt_long_black_24dp.svg";
import baseDataBlack from "./../img/black/dataset_black_24dp.svg";
import publicSpaceTypeBlack from "./../img/black/location_city_black_24dp.svg";
import emailAddressPostfixBlack from "./../img/black/contact_mail_black_24dp.svg";
import vatBlack from "./../img/black/price_change_black_24dp.svg";
import customerStateBlack from "./../img/black/military_tech_black_24dp.svg";
import chequeBlack from "./../img/black/money_black_24dp.svg";
import ChequeIssuerContent from "./../img/black/recent_actors_black_24dp.svg";


import invoiceIncomingBlack from "./../img/black/keyboard_double_arrow_left_black_24dp.svg";
import invoiceOutgoingBlack from "./../img/black/keyboard_double_arrow_right_black_24dp.svg";

import invoiceBlack from "./../img/black/request_page_black_24dp.svg";

import InvoiceIncoming from "./../img/black/InvoiceIncoming.svg";
import InvoiceOutgoing from "./../img/black/InvoiceOutgoing.svg";

import calendarBlack from "./../img/black/date_range_black_24dp.svg";

import payWithCash from "./../img/black/payments_black_24dp.svg";
import payWithTransfer from "./../img/black/credit_card_black_24dp.svg";

import analytics from "./../img/black/assessment_black_24dp.svg";
import houseTreasury from "./../img/black/point_of_sale_black_24dp.svg";
import result from "./../img/black/price_change_black_24dp.svg";




import './../css/driveTree.css';


class MenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onMouseOver: false,
            dropDownOpened: false
        }
    }

    getMenuIconByName = function (iconName) {

        var docOther = (this.props.theme == "true") ? docOtherWhite : docOtherBlack;
        var employe = (this.props.theme == "true") ? employeWhite : employeBlack;

        switch (iconName) {
            case 'doc'    :
                return docOther;
                break;
            case 'customer'    :
                return customerBlack;
                break;
            case 'employee'    :
                return employe;
                break;
            case 'UserRoles'    :
                return userRoles;
                break;
            case 'GoogleDriveFiles'    :
                return GoogleDriveFiles;
                break;
            case 'workNumber'    :
                return workNumberBlack;
                break;
            case 'baseData'    :
                return baseDataBlack;
                break;
            case 'publicSpaceType'    :
                return publicSpaceTypeBlack;
                break;
            case 'emailAddressPostfix'    :
                return emailAddressPostfixBlack;
                break;
            case 'vat'    :
                return vatBlack;
                break;
            case 'customerState'    :
                return customerStateBlack;
                break;
            case 'cheque'    :
                return chequeBlack;
                break;
            case 'ChequeIssuerContent'    :
                return ChequeIssuerContent;
                break;
            case 'ChequeRemarkContent'    :
                return workNumberBlack;
                break;
           
            case 'invoiceIncoming'    :
                return InvoiceIncoming;
                break;
            case 'invoiceOutgoing'    :
                return InvoiceOutgoing;
                break;
            case 'invoice'    :
                return invoiceBlack;
                break;
            case 'calendar'    :
                return calendarBlack;
                break;
            case 'payWithCash'    :
                return payWithCash;
                break;
            case 'payWithTransfer'    :
                return payWithTransfer;
                break;
            case 'analytics'    :
                return analytics;
                break;
            case 'houseTreasury'    :
                return houseTreasury;
                break;
            case 'results'    :
                return result;
                break;
        }

    }

    onMouseOver = function (e) {
        this.setState({onMouseOver: true});
    }
    onMouseLeave = function (e) {
        this.setState({onMouseOver: false});
    }

    setActiveMenuItem = function () {
        this.props.setActiveMenu(this.props.item.id);
    }

    childMenuOpener = function () {
        this.setState({dropDownOpened: !this.state.dropDownOpened});
    }

    openNode = function () {
        this.setState({dropDownOpened: true});
    }

    render() {
        var dropDownClosed = (this.props.theme == "true") ? dropDownClosedWhite : dropDownClosedBlack;
        var dropDownOpened = (this.props.theme == "true") ? dropDownOpenedWhite : dropDownOpenedBlack;
        var menuIcon = this.getMenuIconByName(this.props.item.ico);



        var isSelected = this.props.activeMenuId == this.props.item.id;

        var selectionColor = (this.props.theme == "true") ? "#00000000" : "#FFFFFF";
        var selectionBackground = (this.props.theme == "true") ? "#313943" : "#FFFFFF";
        if (isSelected) {
            selectionColor = (this.props.theme == "true") ? "#ffc107" : "#ffc107";
            selectionBackground = (this.props.theme == "true") ? "#374350" : "#dfe2ef";
        }

        if (this.state.onMouseOver) {
            selectionBackground = (this.props.theme == "true") ? "#374350" : "#dfe2ef";
        }

        var collapsed = this.props.collapsed;

        var title = (collapsed) ? "" : this.props.item.title;

        var hasChildren = (typeof this.props.children != 'undefined' && this.props.children != null && this.props.children.length > 0) ? true : false;

        var childMenuOpener = "";
        if (hasChildren) {
            childMenuOpener = <img  onClick={this.childMenuOpener.bind(this)} src={(this.state.dropDownOpened) ? dropDownClosed : dropDownOpened }/>;
        }

        var childMenu = (this.props.isChild) ? <div style={{width: "25px"}} /> : "";
        var menuItemTextClass = (this.props.theme == "true") ? "menuItemTextDark" : "menuItemTextLight";

        var menuSeparator = (this.props.theme == "true") ? 'solid 1px #ffffff11' : 'solid 1px #e4e3e2';

        return <div style={{borderBottom: menuSeparator}}>
            <div 
                style={{backgroundColor: selectionBackground}} 
                className={"menuItem"} 
                onClick={this.setActiveMenuItem.bind(this)}
                onMouseOver={this.onMouseOver.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                >
                <div style={{minWidth: '10px', backgroundColor: selectionColor, marginRight: '5px'}} >&nbsp;</div>
                {childMenu}
                <img src={menuIcon}/><div onClick={this.openNode.bind(this)} className={menuItemTextClass}>{title}</div>
                &nbsp;{childMenuOpener}
        
            </div>
            <div className={(this.state.dropDownOpened) ? 'fadeIn' : 'fadeOut'}>
                {(this.state.dropDownOpened) ? this.props.children : ""}
        
            </div>
        </div>
                ;
    }
}

export default MenuItem;
