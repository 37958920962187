import React, { Component } from 'react';
import './../css/light.css';

import * as ajax from './../ajax.js';


class EmployeTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            current_id:-1
        }

        this.dataLoader();

    }

    dataLoader = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/employeTabContent.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({items: response.data});
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    setCurrent=function(e){
        this.setState({current_id:e.target.id})
        this.props.setCurrentEmploye(e.target.id);
    }
    
    

    getTab = function (current_id,item) {
        var style={};
        if(current_id ==item.id && item.id != -1){
            style={borderBottom:'none',color:'blue'};
        }
        if(this.state.current_id == -1 && item.id == -1  ){
            style={borderBottom:'none',color:'green'};
        }
        
        if(current_id ==item.id && item.id == -2  ){
            style={borderBottom:'none',color:'#e63f3f'};
        }
        
        if(this.state.current_id == -2 && item.id == -2  ){
            style={borderBottom:'none',color:'#e63f3f',fontWeight:'bold'};
        }
        
        return <div key={'emloye_key_'+item.id} onClick={this.setCurrent.bind(this)} style={style} className="tabItem" id={item.id}>
        {item.employeName}
        </div>
    }

    getTabs = function (current_id) {
        var tabs = [];
        
        var tabSummary = this.getTab(-2,{id:-2,employeName:'Összesítők'});
        tabs.push(tabSummary);
        
        var tab = this.getTab(-1,{id:-1,employeName:'Minden alkalmazott'});
        tabs.push(tab);
        for (var i = 0; i < this.state.items.length; i++) {
            var item = this.state.items[i];
            tab = this.getTab(current_id,item);
            tabs.push(tab);
          
        }
        return tabs;
    }

    render() {
        var tabs = this.getTabs(this.state.current_id);
        return  <div className="tabContainer">
            {tabs}
        </div>;
    }
}

export default EmployeTabs;


