import React, { Component } from 'react';
import * as lang from './../lang.js';
import './../css/easyTable.css';

class DefaultCell extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var row = this.props.row;
        var data=this.props.data;
        return <div className="defaultCell">{data}</div>
    }
}

export default DefaultCell;

