import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import { Container, Row, Col, Button }
from 'react-bootstrap';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';


import PaymentPeriodCombo from './../Calendar/PaymentPeriodCombo';
import DateInsertField from './../../components/DateInsertField';
import deleteIcon from './../../img/black/delete_black_24dp.svg';
class OtherCostsFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            periodYear: new Date().getFullYear(),
            calendar_paymentPeriod: '',
            calendar_workingDate_from: '',
            calendar_workingDate_to: '',
            calendar_workNumber: [],
            calendar_employe: [],
            calendar_customerAddress: [],
            calendar_customer: []


        }


        this.refEmploye = React.createRef();
        this.refWorkingDate_from = React.createRef();
        this.refWorkingDate_to = React.createRef();
        this.refWorkNumber = React.createRef();
        this.refCalendarCustomerAddress = React.createRef();
        this.createFilters();
        this.paymentPeriodRef = React.createRef();
        this.paymentPeriodReset = {call: null};
    }

    storeFilters = function () {

        localStorage.setItem('otherCosts_periodYear', this.state.periodYear);
        localStorage.setItem('otherCosts_paymentPeriod', this.state.calendar_paymentPeriod);
        localStorage.setItem('otherCosts_workingDate_from', this.state.calendar_workingDate_from);
        localStorage.setItem('otherCosts_workingDate_to', this.state.calendar_workingDate_to);
    }

    componentWillUnmount = function () {
        this.storeFilters();
    }

    nullToEmptyString = function (val) {
        if (typeof val == 'undefined') {
            return '';
        }
        if (typeof val == null) {
            return '';
        }
        return val;
    }

    loadStoredFilters = function () {
        
        this.state.calendar_periodYear = this.nullToEmptyString(localStorage.getItem('otherCosts_periodYear'));
        console.log(this.state.calendar_periodYear);
        this.state.calendar_paymentPeriod = this.nullToEmptyString(localStorage.getItem('otherCosts_paymentPeriod'));
        this.state.calendar_workingDate_from = this.nullToEmptyString(localStorage.getItem('otherCosts_workingDate_from'));
        this.state.calendar_workingDate_to = this.nullToEmptyString(localStorage.getItem('otherCosts_workingDate_to'));
        this.props.setHeaderFilterValues(this.state);
    }
    componentDidMount() {
        this.loadStoredFilters();
        this.forceUpdate();
    }

    hasNewCell = function (currentArray) {

        if (currentArray.length == 0) {
            return false;
        }
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] == -1 || currentArray[i] == '') {
                return true;
            }
        }

        return false;
    }

    createFilters = function () {

        this.employeFilters = [];
        this.customerAddressFilters = [];
        this.customerFilters = [];
        this.workNumberFilters = [];
        var hasNewEmploye = this.hasNewCell(this.state.calendar_employe);
        if (hasNewEmploye == false) {
            this.state.calendar_employe.push(-1);
        }
        var hasNewWorkNumber = this.hasNewCell(this.state.calendar_workNumber);
        if (hasNewWorkNumber == false) {
            this.state.calendar_workNumber.push(-1);
        }

        var hasNewCustomerAddress = this.hasNewCell(this.state.calendar_customerAddress);
        if (hasNewCustomerAddress == false) {
            this.state.calendar_customerAddress.push(-1);
        }

        var hasNewCustomer = this.hasNewCell(this.state.calendar_customer);
        if (hasNewCustomer == false) {
            this.state.calendar_customer.push(-1);
        }


        for (var i = 0; i < this.state.calendar_workNumber.length; i++) {
            var workNumberFilter = this.createWorkNumberFilter(i);
            this.workNumberFilters.push(workNumberFilter);
        }
        this.forceUpdate();
    }

    setEmployeFilter = function (id, value) {
        var index = id.split('_')[1];
        this.state.calendar_employe[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }
    setCustomerAddressFilter = function (id, value) {
        var index = id.split('_')[1];
        this.state.calendar_customerAddress[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    setCustomerFilter = function (id, value) {
        var index = id.split('_')[1];
        this.state.calendar_customer[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    setWorkNumberFilter = function (id, value) {
        var index = id.split('_')[1];
        this.state.calendar_workNumber[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    removeArrayIndex = function (currentArray, arrayIndex) {
        var result = [];
        for (var i = 0; i < currentArray.length; i++) {
            if (i != arrayIndex) {
                result.push(currentArray[i]);
            }
        }
        return result;
    }

    removeNegative = function (currentArray) {
        var result = [];
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] * 1 != -1 && currentArray[i] != '') {
                result.push(currentArray[i]);
            }
        }
        return result;
    }

    deleteWorkNumberArrayIndex = function (e) {
        var arrayIndex = e.target.id.split("_")[1];
        this.state.calendar_workNumber = this.removeArrayIndex(this.state.calendar_workNumber, arrayIndex);
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    createWorkNumberFilter = function (counter) {
        return (
                <div key={"workNumberCounter_" + (counter)} style={{display: 'flex', margin: 5}}>
                    <div>
                        <ComboBoxInsertField 
                            id={'workNumber_' + counter} 
                            value={this.state.calendar_workNumber[counter]}  
                            setBack={this.setWorkNumberFilter.bind(this)}
                            dataSourceUrl={'modules/Calendar/workNumber1_idFormComboInsertData.php'}
                            reference={React.createRef()}
                            editable={true}
                            theme={true}
                            placeHolder={'Munkaszám'}
                            />
                    </div>    
                    <div>
                        <img id={"delete_" + counter} 
                             onClick={this.deleteWorkNumberArrayIndex.bind(this)} 
                             style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                    </div>
                </div>
                );
    }

    setBack = function (id, value) {
        this.state[id] = value;
        this.props.setHeaderFilterValues(this.state);
        this.storeFilters();
    }

    setPaymentPeriod = function (id, value) {
        if (value.includes('_')) {
            this.state.calendar_paymentPeriod = value;
            var from_to_array = value.split('_');
            var from = from_to_array[0];
            var to = from_to_array[1];
            this.state.calendar_workingDate_from = from;
            this.state.calendar_workingDate_to = to;
            this.props.setHeaderFilterValues(this.state);
        } else {
            this.state.calendar_workingDate_from = '';
            this.state.calendar_workingDate_to = '';
            this.props.setHeaderFilterValues(this.state);
        }


    }

    reset = function () {
        this.paymentPeriodReset.call();
        this.state.calendar_paymentPeriod = '';
        this.state.calendar_workingDate_from = '';
        this.state.calendar_workingDate_to = '';
        this.state.calendar_workNumber = [];
        this.state.calendar_employe = [];
        this.state.calendar_customerAddress = [];
        this.state.calendar_customer = [];
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
        this.setState({});
    }

    getYearCombo = function () {
        var items = [];
        for (var i = 2022; i < 2101; i++) {
            items.push(<option value={i} key={"paymentPreiodYears_" + i} >{i}</option>);
        }
        return <select value={this.state.periodYear} onChange={(e) => {
                        this.setState({periodYear: e.target.value})
                            }}>
            {items}
        </select>
    }

    render() {
        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px',
                        maxHeight: '73vh',
                        minHeight: '73vh',
                        overflowY: 'scroll'

                         }}>
            <table  style={{textAlign: 'center'}}  >               
                <tbody>               
                    <tr>
                        <td  colSpan={2}><b>FILTEREK</b></td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>FIZETÉSI PERIÓDUSOK</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>
                            <div style={{display: 'flex'}}>
                                {this.getYearCombo()}        
                                <PaymentPeriodCombo 
                                    id={'PaymentPeriodCombo'} 
                                    value={this.state.calendar_paymentPeriod}  
                                    setBack={this.setPaymentPeriod.bind(this)}
                                    dataSourceUrl={'modules/Calendar/paymentPeriodComboData.php'}
                                    reference={this.paymentPeriodRef}
                                    year={this.state.periodYear}
                                    editable={true}
                                    theme={true}
                                    paymentPeriodReset={this.paymentPeriodReset}
                                    placeHolder={'Fizetési periódusok'}
                                    />
                            </div>
                        </td>
                    </tr>
            
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                </tbody>   
            </table>
            <div>
                <button 
                    style={{minWidth: '100%'}}
                    onClick={this.reset.bind(this)}
        
        
                    >Alaphelyzet</button>
            </div>
        
        </div>);
    }
    // render(){
    renderWithDate = function () {
        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px',
                        maxHeight: '73vh',
                        minHeight: '73vh',
                        overflowY: 'scroll'

                    }}>
            <table  style={{textAlign: 'center'}}  >               
                <tbody>               
                    <tr>
                        <td  colSpan={2}><b>FILTEREK</b></td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>FIZETÉSI PERIÓDUSOK</td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>
                            <div style={{display: 'flex'}}>
                                {this.getYearCombo()}        
                                <PaymentPeriodCombo 
                                    id={'PaymentPeriodCombo'} 
                                    value={this.state.calendar_paymentPeriod}  
                                    setBack={this.setPaymentPeriod.bind(this)}
                                    dataSourceUrl={'modules/Calendar/paymentPeriodComboData.php'}
                                    reference={this.paymentPeriodRef}
                                    year={this.state.periodYear}
                                    editable={true}
                                    theme={true}
                                    paymentPeriodReset={this.paymentPeriodReset}
                                    placeHolder={'Fizetési periódusok'}
                                    />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>TÓL</td>
                        <td colSpan={1}>IG</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                <DateInsertField 
                    id={'calendar_workingDate_from'} 
                    value={this.state.calendar_workingDate_from}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_from}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                <td  colSpan={1}>
                <DateInsertField 
                    id={'calendar_workingDate_to'} 
                    value={this.state.calendar_workingDate_to}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_to}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                </tbody>   
            </table>
            <div>
                <button 
                    style={{minWidth: '100%'}}
                    onClick={this.reset.bind(this)}
        
        
                    >Alaphelyzet</button>
            </div>
        
        </div>);
    }
}

export default OtherCostsFilter;

