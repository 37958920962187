import React, { Component } from 'react';
import trashIcon from './../../img/black/delete_black_24dp.svg';
import {Button, Row, Col} from 'react-bootstrap';

class InfoTableToolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        var summary = "";

        if (typeof this.props.calendar_summary != 'undefined') {
            summary = <div style={{display: 'flex', flex: '1', marginRight: 20}}>
                <div style={{position: 'relative', marginRight: 'auto', color: 'black'}}> 
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Vágólap ürítése</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}></td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Másolás</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Beillesztés</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr> 
                                <td style={{textAlign: 'center'}}>
                                <Button 
                                variant="warning" 
                                style={{width: '100%', height: '100%'}} 
                                onClick={()=>{this.props.clearClipboard()}}
                                >
                                    {this.props.multiSelection.length}&nbsp;-&nbsp;Tétel
                                </Button>
                                </td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}></td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>
                                <Button 
                                variant="info" 
                                style={{width: '100%', height: '100%'}} 
                                onClick={()=>{this.props.copyToClipboard()}}
                                >
                                    {(this.props.clipboardItemId==null?0:1)}-Tétel
                                </Button>
                                </td>
                                
                                <td style={{textAlign: 'center'}}></td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>
                                <Button 
                                variant="success" 
                                style={{width: '100%', height: '100%'}} 
                                onClick={()=>{this.props.pasteClipboard()}}
                                >
                                    {this.props.multiSelection.length}&nbsp;-&nbsp;Tétel
                                </Button>
                                </td>
                                <td>&nbsp;</td>
            
                            </tr>
                        </tbody>
                    </table>
            
                </div>
                <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összes óraszám</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Egyéb költségek</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Levonások</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összesen</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>Fizetendő</td>
                            </tr>
            
                            <tr> 
                                <td style={{textAlign: 'center'}}>{new Number(this.props.numberOfHoursSum).toLocaleString()}</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.otherBenefits).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.salaryDeduction).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center'}}>{new Number(this.props.calendar_hoursMultipliedByHourlyWage).toLocaleString()}&nbsp;Ft</td>
                                <td>&nbsp;</td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}}>{new Number(this.props.calendar_summary).toLocaleString()}&nbsp;Ft</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }

        return <div  >
        
            {summary}
        </div>;
    }
}

export default InfoTableToolbar;
