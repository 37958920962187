
import React, { Component } from 'react';
import EmployeeTable from './EmployeeTable.js';
import EmployeeInsertForm from './EmployeeInsertForm.js';
import EmployeeUpdateForm from './EmployeeUpdateForm.js';
import EmployeeViewForm from './EmployeeViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';



class EmployeeContent extends Component {

    constructor(props) {
        super(props);
        this.state = {theme: this.props.theme}
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    setBack = function (id, value) {
        // Nothing to do here
    }
    render() {

        var table = <EmployeeTable 
            setBack={this.setBack.bind(this)} 
            additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
            formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
            theme={this.state.theme}
            />;

        return (
                <div  id="EmployeeContent">
                    <div className="tableTitle"><b>{lang.getTitle("Employee")}</b></div> 
                    {table}
                </div>
                );
    }
}

export default EmployeeContent;
