import React, { Component } from 'react';



class InfoTableToolbar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{display: 'flex', flex: '1', marginRight: 20}}>
            <div style={{position: 'relative', marginLeft: 'auto', color: 'black'}}> 
                <table>
                    <tbody>
                        <tr>
                            <td style={{textAlign: 'center', fontWeight: 'bold'}}>Összesen</td>
                        </tr>
                        <tr> 
                            <td style={{textAlign: 'center'}}>{new Number(this.props.netValueSum).toLocaleString()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }

}

export default InfoTableToolbar;
