import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './InfoTableToolbar';


import ComboEditor from './../../components/ComboEditor';
import {Button, Row, Col} from 'react-bootstrap';

import InsertForm from './CalendarInsertForm.js';
import UpdateForm from './CalendarUpdateForm.js';
import ViewForm from './CalendarViewForm.js';
import Dialog from './../../components/Dialog.js';
import EmployeeInsertForm from './../Employee/EmployeeInsertForm';


import ComboEditorEmploye from './../../ComponentsSpecial/ComboEditorEmploye';
import ComboEditorWorkNumber from './../../ComponentsSpecial/ComboEditorWorkNumber';
import ComboEditorCustomerAddress from './../../ComponentsSpecial/ComboEditorCustomerAddress';
import EmployeTabs from './../../ComponentsSpecial/EmployeTabs';

import EventIcon  from './../../img/black/event_black_24dp.svg'
import employeBlack from "./../../img/black/engineering_black_24dp.svg";
import filterIcon from './../../img/black/filter_alt_black_24dp.svg';
import filterOffIcon from './../../img/black/filter_alt_off_black_24dp.svg';
import CalendarFilter from './CalendarFilter';

import SummaryContentHorizontalDate from './../Summary/SummaryContentHorizontalDate';
import SummaryContentVerticalDate from './../Summary/SummaryContentVerticalDate';
import FormatedNumberCell from './../../components/FormatedNumberCell';



import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class CalendarTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";
        this.ADD_EMPLOYE = "ADD_EMPLOYE";

        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'calendar_id',
            'checkbox',
            'calendar_employe_id',
            'calendar_workingDate',
            'calendar_workingDay',

            'calendar_numberOfHoursSum',

            'calendar_customerAddress1_id',
            'calendar_customerAddress2_id',
            'calendar_customerAddress3_id',
            'calendar_customerAddress4_id',
            'calendar_customerAddress5_id',
            'calendar_customerAddress6_id',
            'calendar_customerAddress7_id',
            'calendar_customerAddress8_id',
            'calendar_customerAddress9_id',
            'calendar_customerAddress10_id',

            'calendar_workNumber1_id',
            'calendar_workNumber2_id',
            'calendar_workNumber3_id',
            'calendar_workNumber4_id',
            'calendar_workNumber5_id',
            'calendar_workNumber6_id',
            'calendar_workNumber7_id',
            'calendar_workNumber8_id',
            'calendar_workNumber9_id',
            'calendar_workNumber10_id',

            'calendar_numberOfHours1',
            'calendar_numberOfHours2',
            'calendar_numberOfHours3',
            'calendar_numberOfHours4',
            'calendar_numberOfHours5',
            'calendar_numberOfHours6',
            'calendar_numberOfHours7',
            'calendar_numberOfHours8',
            'calendar_numberOfHours9',
            'calendar_numberOfHours10',

            'calendar_otherBenefits',
            'calendar_salaryDeduction',
            'calendar_calendarName',
            'calendar_remark',
            'calendar_summary'

        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "Calendar", "calendar");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 100,
                showingRowFrom: 0,
                showingRowTo: 100,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;

        this.gridRef = null;

        this.ref = React.createRef();
        this.employeComboData = [];
        this.employeList = [];

        this.daysComboData = [{id: 1, label: 'Hétfő'}, {id: 2, label: 'Kedd'}, {id: 3, label: 'Szerda'}, {id: 4, label: 'Csütörtök'}, {id: 5, label: 'Péntek'}, {id: 6, label: 'Szombat'}, {id: 0, label: 'Vasárnap'}];
        this.placesComboData = [];
        this.workNumbersList = [];
        this.customerAddressList = [];

        this.currentEmploye_id = -1;

        this.doubleClick = 0;

        // Filterhez
        this.calendar_workingDate_from = '';
        this.calendar_workingDate_to = '';
        this.calendar_workNumberArray = [];
        this.calendar_EmployeArray = [];
        this.showFilter = true;


        this.calendar_hoursMultipliedByHourlyWage = null;
        this.otherBenefits = null;
        this.salaryDeduction = null;
        this.calendar_summary = null;
        this.numberOfHoursSum = null;


        this.summaryVertical = 1;
        this.calendarCreationTryAgain = 0;

        this.gridData = [];

        this.currentEditorProps = null;
        this.multiSelection = [];
        this.clipboardItemId = null;



    }

    removeNegative = function (currentArray) {
        var result = [];
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] * 1 != -1 && currentArray[i] != '') {
                result.push(currentArray[i]);
            }
        }
        return result;
    }
    setHeaderFilterValues = function (filterState) {
        this.calendar_workingDate_from = filterState.calendar_workingDate_from;
        this.calendar_workingDate_to = filterState.calendar_workingDate_to;
        this.calendar_workNumberArray = this.removeNegative(filterState.calendar_workNumber);
        this.calendar_EmployeArray = this.removeNegative(filterState.calendar_employe);
        this.calendar_CustomerAddressArray = this.removeNegative(filterState.calendar_customerAddress);

        this.reload();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    calendarFilter = function (filterParams) {
        this.setState({});
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {



        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }
    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }
    copyToClipboard = function () {
        this.clipboardItemId = this.state.selectedRow;
        this.forceUpdate();
    }

    pasteClipboard = function () {
        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/pasteClipboard.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    currentEmploye_id: this.currentEmploye_id,
                    multiSelection: this.multiSelection,
                    clipboardItemId: this.clipboardItemId
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            self.setState({openedForm: this.DIALOG_ALERT, selectedRow: null, dialogText: response.fail});
                        } else {

                             self.reload();
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }

    }

    customPaging = function (pagingProps) {
        return <PagingToolbar 
        
            multiSelection={this.multiSelection}
        
            blackTheme={this.props.theme} 
            tableReload={this.reload.bind(this)} 
            paging={this.state.paging} 
        
        
        
            calendar_hoursMultipliedByHourlyWage={this.calendar_hoursMultipliedByHourlyWage}
            otherBenefits={this.otherBenefits}
            salaryDeduction={this.salaryDeduction}
        
            calendar_summary={this.calendar_summary}
            numberOfHoursSum={this.numberOfHoursSum}
        
            clearClipboard={() => {
                            this.multiSelection = [];
                            this.clipboardItemId = null;
                            this.forceUpdate();
                        }}
        
            pasteClipboard={this.pasteClipboard.bind(this)}                
            copyToClipboard={this.copyToClipboard.bind(this)}
            clipboardItemId={this.clipboardItemId}
        
            />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    setFilterValue = function (filters, operator, attr, value) {
        for (var i = 0; i < filters.length; i++) {
            if (filters[i].name == attr) {
                filters[i].operator = operator;
                filters[i].value = value;
            }
        }
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){

        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        var self = this;
        filterValue = this.addForeignKeyFilter(filterValue);


        if (self.currentEmploye_id > -1) {
            this.setFilterValue(filterValue, 'eq', 'calendar_employe_id', [self.currentEmploye_id]);
        } else {
            this.setFilterValue(filterValue, 'eq', 'calendar_employe_id', []);
        }


        var calendar_workingDate_from = (this.calendar_workingDate_from == null || this.calendar_workingDate_from == '' || this.calendar_workingDate_from == 'null') ? null : this.calendar_workingDate_from;
        var calendar_workingDate_to = (this.calendar_workingDate_to == null || this.calendar_workingDate_to == '' || this.calendar_workingDate_to == 'null') ? null : this.calendar_workingDate_to;
        this.setFilterValue(filterValue, 'inrange', 'calendar_workingDate', {start: calendar_workingDate_from, end: calendar_workingDate_to});

        var workNumber_id = this.calendar_workNumberArray;
        var calendar_EmployeArray = this.calendar_EmployeArray;


        //this.setFilterValue(filterValue, 'inlist', 'calendar_workNumber2_id', workNumber_id);
        if (this.currentEmploye_id == -1) {
            this.setFilterValue(filterValue, 'inlist', 'calendar_employe_id', calendar_EmployeArray);
        }



        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/calendarTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, workNumbers: this.calendar_workNumberArray, customerAddress: this.calendar_CustomerAddressArray})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        var totalCount = response.count;
                        var data = response.data;




                        // Ha nincs egyéb szűrés és ki van választva a fülön az alkalmazott és nem jön vissza adat akkor a calendar készítése folyamatban van.
                        // Ez esetben várunk egy másodpercet és újra lekérjük az adatokat. 
                        // Ezt maximum 5x ismételjük meg.

                        var hasEmployeFilter = self.calendar_EmployeArray.length > 0;
                        var hasWorkNumberFilter = self.calendar_workNumberArray.length > 0;
                        var hasCustomerAddressFilter = (typeof self.calendar_CustomerAddressArray != 'undefined') ? self.calendar_CustomerAddressArray.length > 0 : false;

                        var filtersEmpty = hasEmployeFilter == false && hasWorkNumberFilter == false && hasCustomerAddressFilter == false;


                        if (filtersEmpty && data.length == 0 && self.calendarCreationTryAgain < 3 && self.currentEmploye_id > -1) {
                            var delayInMilliseconds = 1000; //1 second
                            console.log(self.currentEmploye_id);
                            setTimeout(function () {
                                self.calendarCreationTryAgain = self.calendarCreationTryAgain + 1;
                                console.log('try again', self.calendarCreationTryAgain);
                                self.reload();
                            }, delayInMilliseconds);
                        }

                        if (data.length > 0) {
                            self.calendarCreationTryAgain = 0;
                        }

                        var extra = response.extra;
                        if (extra != null) {
                            self.employeComboData = extra['employeComboData'];
                            self.employeList = extra['employeList'];
                            self.placesComboData = extra['placesComboData'];
                            self.workNumbersList = extra['workNumbersList'];

                            self.calendar_summary = extra['calendar_summary'];
                            self.calendar_hoursMultipliedByHourlyWage = extra['calendar_hoursMultipliedByHourlyWage'];
                            self.numberOfHoursSum = extra['numberOfHoursSum'];
                            self.otherBenefits = extra['otherBenefits'];
                            self.salaryDeduction = extra['salaryDeduction'];
                        }


                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;
                        this.gridData = data;
                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/calendarFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({calendar_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            self.setState({openedForm: this.DIALOG_ALERT, selectedRow: null, dialogText: response.fail});
                        } else {

                            self.setState({openedForm: 'HIDDEN', selectedRow: null});
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    insertRecord = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/insertRecord.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({currentEmploye_id: self.currentEmploye_id, calendar_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.setState({selectedRow: response.data.lastInsertId, openedForm: this.FORM_UPDATE});
                        // self.reload();
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    updateRecord = function (id, key, value) {
        if (value == null) {
            return null;
        }
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/cellUpdater.php";
            var reload = this.reload.bind(this);
            var skip = this.state.paging.skip;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({calendar_id: id, key: key, value: value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            alert(response.fail);
                        }

                        this.reload();
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);

                if (null == error) {
                    console.error("Fetch error:");

                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {
        var self = this;
        self.doubleClick = self.doubleClick + 1;
        setTimeout(() => {
            self.doubleClick = 0;
        }, 400);





        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.calendar_id == 'undefined') {
            return false;
        }

        if (rowProps.data.calendar_id == null) {
            return false;
        }
        if (self.doubleClick == 2) {

            //this.gridRef.current.blur();
            this.setState({selectedRow: rowProps.data.calendar_id, openedForm: this.FORM_UPDATE});
        }
        this.setState({selectedRow: rowProps.data.calendar_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    findLabelById = function (array, id) {

        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    comboUpdateRecord = function (path, id, attrName, value) {
        if (typeof id == 'undefined') {
            return null;
        }
        if (id == null) {
            return null;
        }
        if (id == -1) {
            return null;
        }
        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + path;
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({calendar_id: id, [attrName]: value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                        self.reload();

                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }
    setGridRef = function (gridRef) {
        this.gridRef = gridRef;
    }

    setCurrentEmploye = function (currentEmploye_id) {

        this.currentEmploye_id = currentEmploye_id;
        this.reload();
    }

    getFilter = function () {
        if (this.showFilter == false) {
            return "";
        }
        return <Col style={{maxWidth: 260, minWidth: 260}}>
        <CalendarFilter  
        reload={this.reload.bind(this)}  
        setHeaderFilterValues={this.setHeaderFilterValues.bind(this)}
        currentEmploye_id={this.currentEmploye_id}
        />
        </Col>
    }

    getSummary = function (calendar_workingDate_from, calendar_workingDate_to) {

        if (calendar_workingDate_from == null || calendar_workingDate_to == null) {
            return <div style={{minHeight: '80vh', backgroundColor: 'white'}}>"Adja meg a filterben az időintervallumot."</div>;
        }
        if (calendar_workingDate_from == '' || calendar_workingDate_to == '') {
            return <div style={{minHeight: '80vh', backgroundColor: 'white'}}>"Adja meg a filterben az időintervallumot."</div>;
        }

        if (calendar_workingDate_from == calendar_workingDate_to) {
            alert("A TÓL-IG dátum azonos. Kérem adjon meg nagyobb időintervallumot.");

            return <div style={{minHeight: '80vh', backgroundColor: 'white'}}>"Adja meg a filterben az időintervallumot."</div>;
        }

        if (false) {
            var content = (this.summaryVertical == 1) ?
                    <SummaryContentVerticalDate from={calendar_workingDate_from} to={calendar_workingDate_to}/> :
                    <SummaryContentHorizontalDate from={calendar_workingDate_from} to={calendar_workingDate_to}/>

            var bacgroundVertical = (this.summaryVertical == 1) ? '#dfe2ef' : 'white';
            var bacgroundHorizontal = (this.summaryVertical == 1) ? 'white' : '#dfe2ef';
        }
        var content = <SummaryContentHorizontalDate from={calendar_workingDate_from} to={calendar_workingDate_to}/>

        var bacgroundVertical = 'white';
        var bacgroundHorizontal = 'white';

        return <table>
            <thead>
                <tr>
                    <td 
                        style={{backgroundColor: bacgroundHorizontal, cursor: 'pointer'}}
                        onClick={(e) => {
                                        this.summaryVertical = 0;
                                        this.forceUpdate();
                                    }}>Nevek szerint</td></tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="2">
                        {content}
                    </td>
                </tr>
            </tbody>
        </table>
    }

    bottomBorderRenderer = function (cellProps, value) {

        var isPaymentDate = cellProps.data.isPaymentDate;
        var isPeriodDate = cellProps.data.isPeriodDate;


        if (isPaymentDate == true) {
            return <div style={{minHeight: 24, minWidth: '100%', borderBottom: "solid 2px #000000", margin: 0, padding: 0, textAlign: 'center'}}>{value}</div>
        }
        if (isPeriodDate == true) {
            return <div style={{minHeight: 24, minWidth: '100%', borderBottom: "solid 1px #00000088", margin: 0, padding: 0, textAlign: 'center'}}>{value}</div>
        }

        return <div style={{minHeight: 24, minWidth: '100%', margin: 0, padding: 0, textAlign: 'center'}}>{value}</div>

    }

    checkAll = function (e) {
        var action = e.target.id;
        if (action == "remove") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.calendar_id;
                const index = this.multiSelection.indexOf(id);
                if (index > -1) {
                    this.multiSelection.splice(index, 1);
                }

            }
        }
        if (action == "add") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.calendar_id;
                if (this.multiSelection.indexOf(id) == -1) {
                    this.multiSelection.push(id);
                }
            }
        }
        this.forceUpdate();
    }

    handleCheckBox = function (e) {
        var lastSelectedId = e.target.id * 1;
        const index = this.multiSelection.indexOf(lastSelectedId);
        if (index > -1) {
            this.multiSelection.splice(index, 1);
        } else {
            this.multiSelection.push(lastSelectedId);
        }
        this.forceUpdate();
    }

    isCheckboxSelected = function (id) {
        return this.multiSelection.indexOf(id) > -1;
    }

    getCustomerAddressValue(calendar_id, compN) {
        for (var i = 0; i < this.gridData.length; i++) {
            var tableRecord = this.gridData[i];
            if (tableRecord.calendar_id == calendar_id) {
                return tableRecord['calendar_customerAddress' + compN + '_id'];
            }
        }
        return -1;
    }

    getWorkNumberValue(calendar_id, compN) {
        for (var i = 0; i < this.gridData.length; i++) {
            var tableRecord = this.gridData[i];
            if (tableRecord.calendar_id == calendar_id) {
                return tableRecord['calendar_workNumber' + compN + '_id'];
            }
        }
        return -1;
    }

    selectedRowOnClipboard() {

    }

    rowStyle(row) {

        if (this.multiSelection.length == 0) {
            return {};
        }
        var selectedRow = this.state.selectedRow;
        if (selectedRow == null) {
            return {};
        }

        if (this.multiSelection.indexOf(selectedRow) > -1) {
            return {};
        }

        if (row.data.calendar_id == selectedRow) {
            return {boxShadow: '0px 0px 6px #FF0000'}
        }
        return {};
    }

    render() {

        const cellDOMProps = (cellProps) => {
            return {
                onClick: () => {
                    this.gridRef.current.startEdit({columnId: cellProps.id, rowIndex: cellProps.rowIndex})
                }
            }
        }

        var headerPorpsStyle = {textAlign: 'center', paddingLeft: 13}


        var columns = [

            {name: 'calendar_id', sortable: true, editable: false, header: lang.getTitle('calendar_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'checkbox', sortable: false,
                header: () => {
                    return <div style={{display: "flex", textAlign: 'center'}}>
                        <div 
                            id={"add"}
                            style={{fontSize: 14, cursor: 'pointer', minWidth: '50%'}} 
                            onClick={this.checkAll.bind(this)}
                            >&#9746;</div>
                        <div 
                            id={"remove"}
                            style={{fontSize: 14, cursor: 'pointer', minWidth: '50%'}} 
                            onClick={this.checkAll.bind(this)}
                            >&#9744;</div>
                    </div>
                },
                render: ({ data }) => {
                    return <div style={{textAlign: 'center'}}>
                        <input 
                            id={data.calendar_id}
                            key={"checkbox_key_" + data.calendar_id} 
                            type="checkbox" 
                            onChange={this.handleCheckBox.bind(this)}
                            checked={this.isCheckboxSelected(data.calendar_id)}
                            style={{cursor: 'pointer'}}
                            /></div>
                },
                align: 'center',
                filterDelay: 1000,
                defaultWidth: this.columnStateHandler.getColumnWidth('checkbox', 100),
                type: 'number',
                defaultVisible: true
            }
            , {name: 'calendar_employe_id', sortable: true, editable: false, header: lang.getTitle('calendar_employe_id'), filterDelay: 1000, defaultWidth: 200, cellDOMProps, defaultVisible: (this.currentEmploye_id == -1),
                style: {padding: 0},
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.calendar_id;  // Rekord azonosító
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditorEmploye 
                        id={'calendar_employe_id@' + id} 
                        value={-1}
                        blackTheme={false}
                        dataSourceUrl={'modules/Calendar/employe_idFormComboInsertData.php'}
                        setBack={(id, value) => this.comboUpdateRecord("modules/Calendar/updateEmploye.php", id, 'employe_id', value) } 
                        values={this.employeList} 
                        editorProps={editorProps}
                        state={this.state}
                        /></div>
                },
                render: ({value, cellProps}) => {
                    return this.bottomBorderRenderer(cellProps, this.findLabelById(this.employeComboData, value));
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.employeList.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                },
                headerProps: {
                    style: headerPorpsStyle
                }
            }
            , {name: 'calendar_workingDate', sortable: true, editable: true, header: lang.getTitle('calendar_workingDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_workingDate', 120), filterEditor: DateFilter, cellDOMProps,
                style: {padding: 0},
                filterEditorProps: (props, { index }) => {
                    return {

                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {

                    return  this.bottomBorderRenderer(cellProps, moment(value).format('YYYY-MM-DD'));
                }
                , defaultVisible: true

                , headerProps: {
                    style: headerPorpsStyle
                }
            }
            , {name: 'calendar_workingDay', sortable: true, editable: false, header: lang.getTitle('calendar_workingDay'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_workingDay', 120), defaultVisible: true, cellDOMProps, style: {padding: 0},
                render: ({value, cellProps}) => {
                    var dayLabelColor = '';
                    switch (value) {
                        case 0:
                            dayLabelColor = 'red';
                            break;
                        case 6:
                            dayLabelColor = 'green';
                            break;
                        default:
                            dayLabelColor = '#555e68';
                    }

                    return  this.bottomBorderRenderer(cellProps, <div style={{color: dayLabelColor}}> {this.findLabelById(this.daysComboData, value)}</div>);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.daysComboData.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
                , headerProps: {
                    style: headerPorpsStyle
                }
            }
            , {name: 'calendar_numberOfHoursSum', sortable: true, editable: false, header: "Óra össz.", filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_numberOfHoursSum', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0}
                , headerProps: {
                    style: headerPorpsStyle
                },
                render: ({value, cellProps}) => {
                    return  this.bottomBorderRenderer(cellProps, value);
                }
            }
            , {name: 'calendar_otherBenefits', sortable: true, editable: true, header: lang.getTitle('calendar_otherBenefits'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_otherBenefits', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0},
                render: ({ value, cellProps }) => {
                    return this.bottomBorderRenderer(cellProps, <FormatedNumberCell value={value}  />);
                }
                , headerProps: {
                    style: headerPorpsStyle
                }
            }
            , {name: 'calendar_remark', sortable: true, editable: true, header: lang.getTitle('calendar_remark'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_remark', 150), defaultVisible: true, cellDOMProps, style: {padding: 0},
                headerProps: {
                    style: headerPorpsStyle
                },
                render: ({value, cellProps}) => {
                    return  this.bottomBorderRenderer(cellProps, value);
                }
            }
            , {name: 'calendar_calendarName', sortable: true, editable: true, header: lang.getTitle('calendar_calendarName'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_calendarName', 150), defaultVisible: false, cellDOMProps, style: {padding: 0},
                headerProps: {
                    style: headerPorpsStyle
                },
                render: ({value, cellProps}) => {
                    return  this.bottomBorderRenderer(cellProps, value);
                }
            }
            , {name: 'calendar_salaryDeduction', sortable: true, editable: true, header: lang.getTitle('calendar_salaryDeduction'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_salaryDeduction', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0},
                render: ({ value, cellProps }) => {
                    return this.bottomBorderRenderer(cellProps, <FormatedNumberCell value={value}  />);
                }
                , headerProps: {
                    style: headerPorpsStyle
                }
            }
            , {name: 'calendar_summary', sortable: true, editable: false, header: lang.getTitle('calendar_summary'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_summary', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0},
                render: ({ value, cellProps }) => {
                    return this.bottomBorderRenderer(cellProps, <FormatedNumberCell value={value}  />);
                }
                , headerProps: {
                    style: headerPorpsStyle
                }
            }

        ];

        for (var compNum = 1; compNum < 11; compNum++) {
            // Munkaszámok beszúrása
            columns.push(
                    {compNumb: compNum, name: 'calendar_workNumber' + compNum + '_id', sortable: true, editable: true, header: "M. szám " + compNum, filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_workNumber' + compNum + '_id', 120), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0},
                        renderEditor: (editorProps) => {

                            var id = editorProps.cellProps.data.calendar_id;  // Rekord azonosító
                            var key = editorProps.cellProps.id;
                            var compN = editorProps.cellProps.compNumb;
                            return <div style={{width: '100%'}} key={"combo" + key + "" + compNum + "_" + id}>
                                <ComboEditorWorkNumber 
                                    id={'calendar_workNumber' + compN + '_id@' + id} 
                                    value={this.getWorkNumberValue(id, compN)}
                                    blackTheme={false}
                                    dataSourceUrl={'modules/Calendar/workNumber1_idFormComboInsertData.php'}
                                    setBack={(id, attr, value) => this.comboUpdateRecord("modules/Calendar/updateWorkNumber.php", id, attr, value) } 
                                    values={this.employeList} 
                                    editorProps={editorProps}
                                    state={this.state}
                                    /></div>
                        },
                        render: ({value, cellProps}) => {
                            return  this.bottomBorderRenderer(cellProps, this.findLabelById(this.workNumbersList, value));
                        },
                        filterEditor: SelectFilter,
                        filterEditorProps: {
                            multiple: true,
                            wrapMultiple: false,
                            dataSource: this.workNumbersList.map(c => {
                                return {id: c.id, label: c.label}
                            }),
                        }
                        , headerProps: {
                            style: headerPorpsStyle
                        }
                    }

            );
            // Címek beszúrása
            columns.push(
                    {compNumb: compNum, name: 'calendar_customerAddress' + compNum + '_id', sortable: true, editable: true, header: "Hely " + compNum, filterDelay: 1000, defaultWidth: 200, style: {padding: 0},

                        renderEditor: (editorProps) => {
                            this.currentEditorProps = editorProps;
                            var id = editorProps.cellProps.data.calendar_id;  // Rekord azonosító
                            var key = editorProps.cellProps.id;
                            var compN = editorProps.cellProps.compNumb;
                            var calendar_workNumber1_id = editorProps.cellProps.data['calendar_workNumber' + compN + '_id'];  // Rekord azonosító
                            return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                            <ComboEditorCustomerAddress 
                                id={'calendar_customerAddress' + compN + '_id@' + id} 
                                calendar_workNumber1_id={calendar_workNumber1_id}
                                value={this.getCustomerAddressValue(id, compN)}
                                blackTheme={false}
                                dataSourceUrl={'modules/Calendar/customerAddress1_idFormComboInsertData.php'}
                                setBack={(id, attr, value) => this.comboUpdateRecord("modules/Calendar/updateCustomerAddress.php", id, attr, value) } 
                                values={this.placesComboData} 
                                editorProps={editorProps}
                                state={this.state}
                                style={{textAlign: 'center'}}
                                /></div>
                        },
                        render: ({value, cellProps}) => {
                            return this.bottomBorderRenderer(cellProps, this.findLabelById(this.placesComboData, value));
                        },
                        filterEditor: SelectFilter,
                        filterEditorProps: {
                            multiple: true,
                            wrapMultiple: false,
                            dataSource: this.placesComboData.map(c => {
                                return {id: c.id, label: c.label}
                            }),
                        }
                        , headerProps: {
                            style: headerPorpsStyle
                        }
                    });

            // Óraszámok beszúrása        
            columns.push(
                    {name: 'calendar_numberOfHours' + compNum, sortable: true, editable: true, header: "Óra " + compNum, filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('calendar_numberOfHours' + compNum, 100), type: 'number', filterEditor: NumberFilter, defaultVisible: true, cellDOMProps, style: {padding: 0}
                        , headerProps: {
                            style: headerPorpsStyle
                        },
                        render: ({value, cellProps}) => {
                            return  this.bottomBorderRenderer(cellProps, value);
                        }
                    }
            );
        }


        var filters = [
            {name: 'calendar_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'calendar_employe_id', operator: 'eq', type: 'select', value: []}
            , {name: 'calendar_workingDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'calendar_workingDay', operator: 'eq', type: 'select', value: []}
            , {name: 'calendar_numberOfHoursSum', operator: 'eq', type: 'number', value: ''}
            , {name: 'calendar_otherBenefits', operator: 'eq', type: 'number', value: ''}
            , {name: 'calendar_remark', operator: 'contains', type: 'string', value: ''}
            , {name: 'calendar_calendarName', operator: 'contains', type: 'string', value: ''}
            , {name: 'calendar_salaryDeduction', operator: 'eq', type: 'number', value: ''}
            , {name: 'calendar_summary', operator: 'eq', type: 'number', value: ''}

        ]

        for (var i = 1; i < 11; i++) {
            filters.push({name: 'calendar_workNumber' + i + '_id', operator: 'eq', type: 'select', value: []});
            filters.push({name: 'calendar_customerAddress' + i + '_id', operator: 'eq', type: 'select', value: []});
        }


        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'calendar_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  theme={this.props.theme} key={'calendar_KEY_INSERT'} formType={this.FORM_INSERT} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.ADD_EMPLOYE) {
            openedForm = <EmployeeInsertForm  
                theme={this.props.theme} 
                key={'calendar_ADD_EMPLOYE'} 
                formType={this.ADD_EMPLOYE} 
                additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} 
                closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm delete={this.delete.bind(this)} theme={this.props.theme} key={'calendar_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[(typeof this.props.additionalParams[0] != 'undefined') ? this.props.additionalParams[0] : []]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'calendar_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
//var insertButton = <div key={'calendar_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.insertRecord.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'calendar_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'calendar_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;
        var addEmploye = <img 
            id={this.ADD_EMPLOYE}
            key={"add_employe_" + this.ADD_EMPLOYE}
            onClick={this.setView.bind(this)}
            style={{cursor: 'pointer'}} 
            title={'Alkalmazott hozáadása'} 
            src={employeBlack} 
            />;



        var isViewForm = this.isViewForm();

        var insertButton = "";
        if (false) { // automatikusan jönnek lére a bejegyzések. 

            //if (this.currentEmploye_id > -1) {
            insertButton = <div key={'calendar_KEY_INSERT_BUTTON'} 
            
                 style={{
                                     cursor: 'pointer',
                                     display: 'flex',
                                     textAlign: 'center',

                                 }} 
            
                 onClick={this.insertRecord.bind(this)}><img title="Munkaidők hozzáadása" src={EventIcon}/></div>;
        }

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [addEmploye, <div>&nbsp;</div>, insertButton];
        }


        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        var updateRecord = this.updateRecord.bind(this);
        const callback = function (result, data) {
            updateRecord(result.rowId, result.columnId, result.value);
        }




        var content = null;
        if (this.currentEmploye_id > -2) {

            content = <ReactDataGrid 
            onReady={this.setGridRef.bind(this)}
            idProperty="calendar_id" 
            i18n={ajax.getI18n()}
            columns={this.columnStateHandler.myColumnsReorder(columns)}
            dataSource={this.dataLoader.bind(this)}
            defaultFilterValue={filters}
            enableSelection={true}
            enableFiltering={false}
            onEditComplete={callback}
            sortable={false}
            rowStyle={this.rowStyle.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            onSelectionChange={this.onSelectionChange.bind(this)}
            theme={theme}
            style={{height: '75vh', marginTop: 10, width: '100%'}}    
            rowHeight={25}
            defaultLimit={100}
            pagination={true}
            renderPaginationToolbar={this.renderPaginationToolbar}
            skip={this.state.skip}
            limit={this.state.paging.pageRowCount}
            showColumnMenuTool={false}
            onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
            onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
            onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
        
        
            />;
        } else {
            // content = this.getSummary(this.calendar_workingDate_from, this.calendar_workingDate_to); // Ez működik a szűrőkkel.
            content = this.getSummary('2021-01-01', '2080-01-01');
        }

        return (<div className={'buttonBar'} id="CalendarTable">
            <div style={{display: "flex"}}>
                <div style={{minWidth: 20, display: 'flex', maxHeight: 22}}>{buttons}</div>
                <EmployeTabs multiSelection={this.multiSelection} setCurrentEmploye={this.setCurrentEmploye.bind(this)} />
            </div>
            <div style={{backgroundColor: 'white'}}>
        
        
                <div style={{float: 'left', width:(this.currentEmploye_id!=-2)? 300:0}}>{this.getFilter()}</div>
                <div style={{marginLeft: (this.currentEmploye_id!=-2)? 300:0}}>{content}</div>
        
        
            </div>
            {openedForm}            
        </div>
                );
    }
}

export default CalendarTable;
