import React, { Component } from 'react';

import {Button, Row, Col} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import Felhasznalok from './Felhasznalok';
import Modulok from './Modulok';
import JogosultsagTipusok from './JogosultsagTipusok';

class JogosultsagokContent extends Component {

    constructor(props) {
        super(props);

        this.ACTION_TYPE_GOD_MODE_ADD = "GOD_MODE_ADD";
        this.ACTION_TYPE_GOD_MODE_REMOVE = "GOD_MODE_REMOVE";
        
        this.ACTION_TYPE_SINGLE_ROLE_ADD="SINGLE_ROLE_ADD";
        this.ACTION_TYPE_SINGLE_ROLE_REMOVE="SINGLE_ROLE_REMOVE";


        this.state = {
            theme: this.props.theme,
            addPanelVisible: false,
            jogosultsagTipus_nev: '',
            jogosultsagokData: [],
            modulokData: [],

            activeJogosultsagTipus: null
        }

        this.error = true;
        this.msg = "";
        this.jogosultsagLoader();



    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        if (errorStringConstant.includes('3')) {
            this.msg = "A jogosultság nevének minimum 3 karaktert kell tartalmazzon. ";
            this.setState({});
        } else {
            this.msg = lang.getTitle(errorStringConstant);
            this.setState({});
        }
    }

    setValue = function (e) {
        var id = e.target.id;
        var value = e.target.value;
        this.setState({[id]: value});
    }

    jogosultsagLetrehozasa = function (e) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/jogosultsagTipusokLetrehozasa.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({jogosultsagTipus_nev: this.state.jogosultsagTipus_nev})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.msg = '';
                            this.error = false;
                            self.setState({addPanelVisible: false});
                            self.jogosultsagLoader();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    jogosultsagTipusTorlese = function (id) {
        var self = this;

        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/jogosultsagTipusTorlese.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({jogosultsagTipus_id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.msg = '';
                            this.error = false;
                            self.setState({addPanelVisible: false});
                            self.jogosultsagLoader();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    jogosultsagBeallitasa = function (actionType, jogosultsagTipus_id, jogosultsag_id) {
        var self = this;

        if (typeof actionType == 'undefined' || actionType == null || typeof actionType != 'string') {
            return false;
        }
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/jogosultsagBeallitasa.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({actionType: actionType, jogosultsagTipus_id: jogosultsagTipus_id, jogosultsag_id: jogosultsag_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.msg = '';
                            this.error = false;
                            self.setState({addPanelVisible: false});
                            self.loadModulok(self.state.activeJogosultsagTipus);

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    felhasznaloBesorolasa = function (id) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/felhasznaloBesorolasa.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({jogosultsagTipus_id: this.state.activeJogosultsagTipus, user_id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.msg = '';
                            this.error = false;
                            self.setState({addPanelVisible: false});
                            self.jogosultsagLoader();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }

    }

    felhasznaloBesorolasTorlese = function (id) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/felhasznaloBesorolasTorlese.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({jogosultsagFelhasznalo_id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.msg = '';
                            this.error = false;
                            self.setState({addPanelVisible: false});
                            self.jogosultsagLoader();

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }

    }

    jogosultsagLoader = function () {
        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/selectJogosultsagTipusok.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({jogosultsagokData: response.data});
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    loadModulok = function (jogosultsagTipus_id) {
        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Jogosultsagok/selectModulok.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({jogosultsagTipus_id: jogosultsagTipus_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({modulokData: response.data});
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    addJogosultsagPanel = function () {

        return <div>
            <div><input onChange={this.setValue.bind(this)}  id="jogosultsagTipus_nev" value={this.state.jogosultsagTipus_nev}   type="text"  /></div>
            <div><Button onClick={this.jogosultsagLetrehozasa.bind(this)} size="sm" variant="success">Jogosultság létrehozása</Button></div>
        
        </div>
    }

    setActiveJogosultsagTipus = function (id) {
        this.setState({activeJogosultsagTipus: id});
        this.loadModulok(id);

    }

    render() {

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        var headerTextColor = (this.props.theme == "true") ? "wheat" : "grey";
        var fontColor = (this.props.theme == "true") ? "white" : "black";
        var felhasznalok = "";
        if (this.state.activeJogosultsagTipus != null) {
            felhasznalok = <Felhasznalok
                felhasznaloBesorolasa={this.felhasznaloBesorolasa.bind(this)}
                />
        }



        var jogsultsagTipusok = <JogosultsagTipusok     
            setActiveJogosultsagTipus={this.setActiveJogosultsagTipus.bind(this)}    
            activeJogosultsagTipus={this.state.activeJogosultsagTipus}
            felhasznaloBesorolasTorlese={this.felhasznaloBesorolasTorlese.bind(this)}
            jogosultsagTipusTorlese={this.jogosultsagTipusTorlese.bind(this)}
            data={this.state.jogosultsagokData}
            />;
        var modulok = "";
        if (this.state.activeJogosultsagTipus != null) {
            modulok = <Modulok
                data={this.state.modulokData}
                parent={this}
                />
        }
        var addPanel = null;
        if (this.state.addPanelVisible) {
            addPanel = this.addJogosultsagPanel();
        }


        return (
                <div style={{userSelect: 'none', color: fontColor, minHeight: '100vh'}}  className={"content" + className} id="JogosultsagokContent">
                    <Row>
                        <Col md={2} style={{whiteSpace: 'nowrap', textAlign: 'center'}}>
                        {(this.state.activeJogosultsagTipus != null) ? <b>FELHASZNÁLÓK</b> : ""}
                        </Col>
                        <Col md={2} style={{whiteSpace: 'nowrap', textAlign: 'center'}} >
                        <div>
                            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
                                <div><b>JOGOSULTSÁGOK&nbsp;</b></div>
                                <div style={{cursor: 'pointer'}} onClick={() => {
                                        this.setState({addPanelVisible: !this.state.addPanelVisible})
                                         }}>{(this.state.addPanelVisible) ? '[-]' : '[+]'}</div>
                            </div>
                            {addPanel}
                        </div>
                        </Col>
                        <Col md={8} >
                        {(this.state.activeJogosultsagTipus != null) ?
                                    <div style={{whiteSpace: 'nowrap', textAlign: 'center', display: 'flex'}}>
                            
                                        <div 
                                            style={{cursor: 'pointer'}} 
                                            onClick={() => {
                                                            this.jogosultsagBeallitasa(this.ACTION_TYPE_GOD_MODE_ADD, this.state.activeJogosultsagTipus, -1)
                                                        }}
                                            title="Teljes hozzáféres">[+]</div>
                                        <div><b>MODUL JOGOSULTSÁGOK</b></div>
                                        <div  
                                            style={{cursor: 'pointer'}}  
                                            onClick={() => {
                                                            this.jogosultsagBeallitasa(this.ACTION_TYPE_GOD_MODE_REMOVE, this.state.activeJogosultsagTipus, -1)
                                                        }}
                                            title="Jogosultságok megvonása" >[-]</div>
                            
                                    </div>
                                    : ""}        
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{color:'red',textAlign:'left'}} md={12}>&nbsp;{this.msg}</Col>
                    </Row>
                    <Row>
                        <Col md={2}  style={{whiteSpace: 'nowrap', textAlign: 'center', overflow: 'hidden'}}>{felhasznalok}</Col>
                        <Col md={2}  style={{whiteSpace: 'nowrap', textAlign: 'center', overflow: 'hidden'}}>{jogsultsagTipusok}</Col>
                        <Col md={8}  style={{whiteSpace: 'nowrap', textAlign: 'center'}}>{modulok}</Col>
                    </Row>
                </div>
                );
    }
}

export default JogosultsagokContent;
