import React, { Component } from 'react';
import MTOHourlyWageTable from './MTOHourlyWageTable.js';
import MTOHourlyWageInsertForm from './MTOHourlyWageInsertForm.js';
import MTOHourlyWageUpdateForm from './MTOHourlyWageUpdateForm.js';
import MTOHourlyWageViewForm from './MTOHourlyWageViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class MTOHourlyWageContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: (typeof this.props.value != 'undefined' && this.props.value != null) ? this.props.value : [],
            theme: this.props.theme
        }

    }
    
      shouldComponentUpdate(nextProps,nextState){
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    render() {
        var emptyFunction = () => {
        };
        var setBack = (typeof this.props.setBack != 'undefined') ? this.props.setBack.bind(this) : emptyFunction;
        
        var table = <MTOHourlyWageTable 
                      value={this.state.value} 
                      setBack={setBack} 
                      additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                      formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                      theme={this.props.theme}
                    />;
        return (
                <div id="MTOHourlyWageContent">
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOHourlyWage")}</div> 
                    <div className={"text-center"}><h6>{lang.getTitle("MTOHourlyWage")}</h6></div> 
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOHourlyWage")}</div> 
                
                    {table}
                </div>
                );
    }
}

export default MTOHourlyWageContent;

