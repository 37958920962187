import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';


import {Button} from 'react-bootstrap';

import InsertForm from './MTOHourlyWageInsertForm.js';
import FormatedNumberCell from './../../components/FormatedNumberCell';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

class MTOHourlyWageTableMTO extends Component {

    constructor(props) {
        super(props);
        var columnNames = [
            'hourlyWage_id'
                    , 'hourlyWage_wage'
                    , 'hourlyWage_wageFull'
                    , 'hourlyWage_fromDate'
                    , 'hourlyWage_ceratedBy'
                    , 'hourlyWage_createdAt'
        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "MTOHourlyWage", "hourlyWage");

        this.state = {
            columnOrder: columnNames,
            openedForm: 'NONE', // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRowId: -1,
            data: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (this.state.theme != this.props.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {
        this.setState({openedForm: e.target.id});
    }

    closeForm = function () {
        this.setState({openedForm: 'NONE'});
    }

    addRow = function (row, close) {
        var rowNumber = this.state.data.length + 1;
        row.hourlyWage_id = rowNumber;
        this.state.data.push(row);
        if (close) {
            this.closeForm();
        } else {
            this.setState({});
        }
        this.props.setBack('MTOHourlyWage', this.state.data);
    }
    onSelectionChange = function (selected) {
        var selectedRowId = selected.data.hourlyWage_id;
        this.setState({selectedRowId: selectedRowId});
    }

    getRowIndexById = function () {
        for (var i = 0; i < this.state.data.length; i++) {
            var currentData = this.state.data[i];
            if (currentData.hourlyWage_id == this.state.selectedRowId) {
                return i;
            }
        }
        return -1;
    }

    deleteRow = function () {

        if (this.state.selectedRowId == -1) {
            return false;
        }

        var rowIndexById = this.getRowIndexById(this.state.selectedRowId);
        if (rowIndexById == -1) {
            return false;
        }


        this.state.data.splice(rowIndexById, 1);
        this.state.selectedRowId = -1;
        this.setState({});
        this.props.setBack('MTOHourlyWage', this.state.data);
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    render() {
        const columns = [
            {name: 'hourlyWage_id', sortable: true, header: lang.getTitle('hourlyWage_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_id', 100), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'hourlyWage_wage', sortable: true, header: lang.getTitle('hourlyWage_wage'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_wage', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'hourlyWage_wageFull', sortable: true, header: lang.getTitle('hourlyWage_wageFull'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_wageFull', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true,
                render: ({ value, cellProps }) => {
                    return <FormatedNumberCell value={value}  />
                }
            }
            , {name: 'hourlyWage_fromDate', sortable: true, header: lang.getTitle('hourlyWage_fromDate'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_fromDate', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}
            , {name: 'hourlyWage_ceratedBy', sortable: true, header: lang.getTitle('hourlyWage_ceratedBy'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_ceratedBy', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: true}
            , {name: 'hourlyWage_createdAt', sortable: true, header: lang.getTitle('hourlyWage_createdAt'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('hourlyWage_createdAt', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    return (value) ? moment(value).format('YYYY-MM-DD') : '';
                }
                , defaultVisible: true}

        ];

        const filters = [
            {name: 'hourlyWage_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'hourlyWage_wage', operator: 'eq', type: 'number', value: ''}
            , {name: 'hourlyWage_wageFull', operator: 'eq', type: 'number', value: ''}
            , {name: 'hourlyWage_fromDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'hourlyWage_ceratedBy', operator: 'eq', type: 'number', value: ''}
            , {name: 'hourlyWage_createdAt', operator: 'eq', type: 'date', value: ''}

        ];



        var openedForm = "";
        if (this.state.openedForm == 'INSERT') {
            openedForm = <InsertForm 
                addRow={this.addRow.bind(this)} 
                closeForm={this.closeForm.bind(this)} 
                additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                theme={this.props.theme}
                />
        }

        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="MTOHourlyWageTable">
                    <div style={{textAlign: 'left'}}>
                        &nbsp;<Button className={'btn-sm'} id={'INSERT'} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button>
                        &nbsp;<Button className={'btn-sm'} id={'DELETE'} onClick={this.deleteRow.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button>
                    </div>
                    <ReactDataGrid 
                        idProperty="hourlyWage_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.state.data}
                        theme={theme}
                        style={{height: 200, marginTop: 10}}  
                        enableSelection={true}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        rowHeight={25}
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                        />
                
                    {openedForm}            
                </div>
                );
    }
}

export default MTOHourlyWageTableMTO;
