import React, { Component } from 'react';
import './../../css/light.css';

import * as ajax from './../../ajax.js';
import ComboBoxInsertField from './CRUDComboBoxInsertField';

import CustomerBankAccountNumberInsertForm from './../CustomerBankAccountNumber/CustomerBankAccountNumberInsertForm';
import CustomerBankAccountNumberUpdateForm from './../CustomerBankAccountNumber/CustomerBankAccountNumberUpdateForm';

class CRUDComboCustomerBankAccountNumber extends ComboBoxInsertField {

    openInsertForm = function () {
        this.props.openInsertForm();
        this.insertFormVisible = false;
    }
    closeForm = function () {
        this.insertFormVisible = false;
        this.updateFormVisible = false;
        this.setState({});
    }
    openForm = function () {
        this.insertFormVisible = true;
        this.setState({});
    }

    setBackRecordData = function (data) {
        this.props.addRow(data);
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/CustomerBankAccountNumber/customerBankAccountNumberFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({customerBankAccountNumber_id: this.state.value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            this.state.value = '';
                            this.state.text = '';
                            this.insertFormVisible = false;
                            this.updateFormVisible = false;
                        } else {
                            if (typeof this.state.value == 'undefined') {
                                alert('Válassza ki a listából a törlendő tételt.');
                            } else {
                                console.log(this.state.value)
                                alert("Siertelen törlés. A cím használatban van a munkalapokon.");
                            }
                        }
                        self.loadItems('');
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    loadItems = function (filterText) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: filterText, customer_id: this.props.customer_id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null && typeof response.data.data != 'undefined' && response.data.data != null) {
                            if (response.data.data.length === 0) {
                                this.setState({dropDownVisible: false});
                            } else {
                                this.setState({items: response.data.data, dropDownVisible: true, cursor: -1});
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});
    }

    render() {

        var comboItems = "";
        if (this.state.dropDownVisible) {
            comboItems = this.comboItems();
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";

        var visibleForm = "";
        if (this.insertFormVisible) {
            visibleForm = <CustomerBankAccountNumberInsertForm
                closeForm ={this.closeForm.bind(this)}
                setBackRecordData={this.setBackRecordData.bind(this)}
                theme={this.props.theme}
                addRow={(row, flag) => this.setBackRecordData(row, flag)}
                additionalParams={[{customerBankAccountNumber_customer_id: this.props.customer_id}]}
                resetCaller={()=>{this.setState({text:'',value:''});this.loadItems('')}}
                />;
        }

        if (this.updateFormVisible) {
            visibleForm = <CustomerBankAccountNumberUpdateForm
                closeForm ={this.closeForm.bind(this)}
                setBackRecordData={this.setBackRecordData.bind(this)}
                theme={this.props.theme}
                addRow={(row, flag) => this.setBackRecordData(row, flag)}
                additionalParams={[{customerAddress1_id: this.state.value}]}
                id={this.state.value}
                delete={this.delete.bind(this)}
                resetCaller={()=>{this.setState({text:'',value:''});this.loadItems('')}}
                />;
        }



        return  <div style={{position: 'relative', textAlign: 'right'}} >
            <table>
                <tbody>
                    <tr>
                        <td style={{width: '100%'}}>
                            <div style={{display: 'contents', textAlign: 'right'}}>
        
                                <input 
                                    type={'text'} 
                                    onChange={this.onChange.bind(this)}  
                                    onFocus={this.onFocus.bind(this)}  
                                    onBlur={this.onBlur.bind(this)}  
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    id={this.props.id} 
                                    value={this.state.text} 
                                    ref={this.props.reference}
                                    disabled={this.props.editable == false}
                                    style={{textAlign: 'left',minWidth: '100%'}}
                                    className ={"component" + className}
                                    autoComplete="off"
                                    />
                                {comboItems}
                            </div>
                        </td>
                        <td style={{verticalAlign:'top'}}>  
                            <div style={{borderBottom: 'solid 1px white', marginLeft: 3}}>
                                <input disabled={this.props.editable == false}  onClick={
                                            this.openForm.bind(this)
                                                                                                                    } type='button' name='add' value='Felvitel'/>
                            </div>
                        </td>
                        <td style={{verticalAlign:'top'}}>  
                            <div style={{borderBottom: 'solid 1px white', marginLeft: 3}}>
                                <input disabled={this.props.editable == false}  onClick={() => {
                                                if (typeof this.state.value == 'undefined' || this.state.value == null || this.state.value == '') {
                                                    alert("Válasszon ki egy címet a listából a módosításhoz.");
                                                } else {
                                                    this.updateFormVisible = true;
                                                    this.forceUpdate();
                                                }

                                                   }} type='button' name='edit' value='Módosítás'/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>                        
            {visibleForm}
        </div>
    }

}

export default CRUDComboCustomerBankAccountNumber;