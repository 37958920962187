import React, { Component } from 'react';
import './../css/light.css';

import PopUpWindow from './PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as lang from './../lang.js';

class Dialog extends React.Component {

    constructor(props) {
        super(props);
        this.NONE = "NONE";
        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        
    }

    

    getDialog = function () {

        if (typeof this.props.type == 'undefined') {
            return "";
        }
        if (this.props.type == null) {
            return "";
        }
        if (this.props.type == this.NONE) {
            return "";
        }

        var header = null;
        var buttons = null;

        var ok = <div key={"dialog_button_ok"} style={{flex:1}}>
                   <Button variant="info" className={'btn-sm'} onClick={()=>{this.props.callback()}} >
                    {lang.getTitle('ok')}
                   </Button>
                </div>;
        var yes = <div  key={"dialog_button_yes"} style={{flex:1}}>
                    <Button variant="danger" className={'btn-sm'} onClick={()=>{this.props.callback()}} >
                      {lang.getTitle('yes')}
                    </Button>
                  </div>;
        var no =  <div key={"dialog_button_no"} style={{flex:1}}>
                   <Button   variant="info" className={'btn-sm'} onClick={()=>{this.props.cancel()}} >
                    {lang.getTitle('no')}
                   </Button>
                  </div>
                  ;

        switch (this.props.type) {
            case this.DIALOG_INFO:
                header = lang.getTitle("INFO_DIALOG_TITLE");
                buttons = [ok];
                break;
            case this.DIALOG_ALERT :
                header = lang.getTitle("ALERT_DIALOG_TITLE");
                buttons = [ok];
                break;
            case this.DIALOG_CONFIRMATION :
                header = lang.getTitle("CONFIRMATION_DIALOG_TITLE");
                buttons = [yes, no];
                break;
            default:
                header = lang.getTitle("INFO_DIALOG_TITLE");
                buttons = [ok];
                break;
        }
        
        var style={maxWidth:300,maxHeight:200};
        var className = (this.props.theme=="true")?"Dark":"Light";
        
        return <PopUpWindow style={style} theme={this.props.theme}>
      
        <div className={"popupWrapper"+className}>
            <div className={"dialogHeader"+className}>{header}</div>
            <div className={"popupBody"+className}><div style={{whiteSpace:'break-spaces'}}>{this.props.dialogText}</div></div>
            <div style={{display:'flex', textAlign: 'center',margin: '20px',justifyContent: 'center'}}>{buttons}</div>
        </div>
    </PopUpWindow>
    }

    render() {
        var dialog = this.getDialog();
        return  <div>{dialog}</div>;
    }
}

export default Dialog;
