import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import { Container, Row, Col, Button }
from 'react-bootstrap';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import WorkNumberFilterCombo from './../InvoiceIncoming/WorkNumberFilterCombo';
import CustomerFilterCombo from './../InvoiceIncoming/CustomerFilterCombo';
import DateInsertField from './../../components/DateInsertField';
import deleteIcon from './../../img/black/delete_black_24dp.svg';

class ChequeFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            periodYear: new Date().getFullYear(),
            cheque_date_from: '',
            cheque_date_to: '',
            cheque_workNumber: [],
            cheque_customer_id: '',
            cheque_customer: ''
        }

        this.refWorkingDate_from = React.createRef();
        this.refWorkingDate_to = React.createRef();
        this.refWorkNumber = React.createRef();
        this.refCalendarCustomerAddress = React.createRef();
        this.workNumberFilters = [];
        this.currentCustomerId = -1;
        this.createFilters();

    }

    createFilters = function () {

        this.workNumberFilters = [];

        var hasNewWorkNumber = this.hasNewCell(this.state.cheque_workNumber);
        if (hasNewWorkNumber == false) {
            this.state.cheque_workNumber.push(-1);
        }

        for (var i = 0; i < this.state.cheque_workNumber.length; i++) {
            var workNumberFilter = this.createWorkNumberFilter(i);
            this.workNumberFilters.push(workNumberFilter);
        }

        this.forceUpdate();
    }

    setWorkNumberFilter = function (id, value) {

        var index = id.split('_')[1];
        this.state.cheque_workNumber[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);

    }

    createWorkNumberFilter = function (counter) {
        return (
                <div key={"workNumberCounter_" + (counter)} style={{display: 'flex', margin: 5}}>
                    <div>
                        <WorkNumberFilterCombo 
                            id={'workNumber_' + counter} 
                            value={this.state.cheque_workNumber[counter]}  
                            setBack={this.setWorkNumberFilter.bind(this)}
                            dataSourceUrl={'modules/Cheque/workNumberByCustomerComboFilterData.php'}
                            reference={React.createRef()}
                            editable={true}
                            theme={true}
                            placeHolder={'Munkaszám'}
                            customerId={this.currentCustomerId}
                            />
                    </div>    
                    <div>
                        <img id={"delete_" + counter} 
                             onClick={this.deleteWorkNumberArrayIndex.bind(this)} 
                             style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                    </div>
                </div>
                );
    }

    deleteWorkNumberArrayIndex = function (e) {
        var arrayIndex = e.target.id.split("_")[1];
        this.state.cheque_workNumber = this.removeArrayIndex(this.state.cheque_workNumber, arrayIndex);
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    removeArrayIndex = function (currentArray, arrayIndex) {
        var result = [];
        for (var i = 0; i < currentArray.length; i++) {
            if (i != arrayIndex) {
                result.push(currentArray[i]);
            }
        }
        return result;
    }

    storeFilters = function () {

        localStorage.setItem('cheque_periodYear', this.state.periodYear);
        localStorage.setItem('cheque_date_from', this.state.cheque_date_from);
        localStorage.setItem('cheque_date_to', this.state.cheque_date_to);


    }

    componentWillUnmount = function () {
        this.storeFilters();
    }

    nullToEmptyString = function (val) {
        if (typeof val == 'undefined') {
            return '';
        }
        if (typeof val == null) {
            return '';
        }
        return val;

    }

    loadStoredFilters = function () {

        this.state.cheque_periodYear = this.nullToEmptyString(localStorage.getItem('cheque_periodYear'));

        this.state.cheque_date_from = this.nullToEmptyString(localStorage.getItem('cheque_date_from'));
        this.state.cheque_date_to = this.nullToEmptyString(localStorage.getItem('cheque_date_to'));
        //  this.state.cheque_workNumber = (localStorage.getItem('cheque_workNumber') == '') ? [] : localStorage.getItem('cheque_workNumber').split(',');
        //  this.state.cheque_employe = (localStorage.getItem('cheque_employe') == '') ? [] : localStorage.getItem('cheque_employe').split(',');
        //  this.state.cheque_customer_id = (localStorage.getItem('cheque_customer_id') == '') ? [] : localStorage.getItem('cheque_customer_id').split(',');
        //  this.state.cheque_customer = (localStorage.getItem('cheque_customer') == '') ? [] : localStorage.getItem('cheque_customer').split(',');
        //  console.log('Loaded state:', this.state)



        if (this.state.cheque_date_from == 'undefined') {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.cheque_date_from == null) {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
        }
        if (this.state.cheque_date_from == '') {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
        }

        if (this.state.cheque_date_to == 'undefined') {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.cheque_date_to == null) {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
        }
        if (this.state.cheque_date_to == '') {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
        }



        this.props.setHeaderFilterValues(this.state);

    }
    componentDidMount() {
        this.loadStoredFilters();
        this.forceUpdate();
    }

    hasNewCell = function (currentArray) {


        if (currentArray.length == 0) {
            return false;
        }
        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i] == -1 || currentArray[i] == '') {
                return true;
            }
        }

        return false;
    }

    setCustomerFilter = function (id, value) {
        this.currentCustomerId = value;
        this.state.cheque_customer = value;
        this.props.setHeaderFilterValues(this.state);
        this.createFilters();
        this.forceUpdate();
    }

    setWorkNumberFilter = function (id, value) {
        var index = id.split('_')[1];
        this.state.cheque_workNumber[index] = value;
        this.createFilters();
        this.props.setHeaderFilterValues(this.state);
    }

    setBack = function (id, value) {
        this.state[id] = value;
        this.props.setHeaderFilterValues(this.state);
        this.storeFilters();
    }

    reset = function () {
        this.state.cheque_date_from = '';
        this.state.cheque_date_to = '';
        this.state.cheque_workNumber = [];
        this.state.cheque_customer_id = '';
        this.state.cheque_customer = '';
        this.props.setHeaderFilterValues(this.state);
        this.setState({});
    }

    render() {

        if (this.state.cheque_date_from == 'undefined') {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.cheque_date_from == null) {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.cheque_date_from == '') {
            this.state.cheque_date_from = ajax.getFirstDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }

        if (this.state.cheque_date_to == 'undefined') {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.cheque_date_to == null) {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }
        if (this.state.cheque_date_to == '') {
            this.state.cheque_date_to = ajax.getLastDayOfYear();
            this.props.setHeaderFilterValues(this.state);
        }




        var mandatory = "";
        if (this.state.cheque_date_from == '' || this.state.cheque_date_to == '') {
            mandatory = <tr>
                <td style={{color: 'red'}} colSpan={2}>A TÓL-IG INTERVALLUM MEGADÁSA KÖTELEZŐ</td>
            </tr>
        }



        return (<div style={{
                        textAlign: 'left',
                        marginTop: 5,
                        fontFamily: 'robo',
                        fontSize: '12px'

                         }}>
            <table  style={{textAlign: 'center'}}  >               
                <tbody>               
                    <tr>
                        <td  colSpan={2}><b>FILTEREK</b></td>
                    </tr>
                    <tr>
                        <td  colSpan={2}>&nbsp;</td>
                    </tr>
                    {mandatory}
                    <tr>
                        <td colSpan={1}>TÓL</td>
                        <td colSpan={1}>IG</td>
                    </tr>
                    <tr>
                        <td colSpan={1}>
                <DateInsertField 
                    id={'cheque_date_from'} 
                    value={this.state.cheque_date_from}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_from}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                <td  colSpan={1}>
                <DateInsertField 
                    id={'cheque_date_to'} 
                    value={this.state.cheque_date_to}  
                    setBack={this.setBack.bind(this)}
                    reference={this.refWorkingDate_to}
                    editable={true}
                    theme={this.props.theme}
                    />  
                </td>
                </tr>
                <tr>
                    <td  colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}} colSpan={2}>
                        ÜGYFELEK
                    </td>
                </tr>
        
                <tr>
                    <td colSpan={2}>
                        <div  key={"customerCounter"} style={{display: 'flex', margin: 5}}>
                            <div>
                                <CustomerFilterCombo 
                                    id={'customer'} 
                                    value={this.state.cheque_customer}  
                                    setBack={this.setCustomerFilter.bind(this)}
                                    dataSourceUrl={'modules/Cheque/customerFilterCombo.php'}
                                    reference={React.createRef()}
                                    from={this.state.cheque_date_from}
                                    to={this.state.cheque_date_to}
                                    editable={true}
                                    theme={true}
                                    placeHolder={'Címek'}
                                    />
                            </div>    
                            <div>
                                <img id={"delete"} 
                                     onClick={() => {
                                                     this.state.cheque_customer = '';
                                                     this.state.cheque_workNumber = [];
                                                     this.workNumberFilters = [];
                                                     this.props.setHeaderFilterValues(this.state);
                                                     this.createFilters();
                                                     this.forceUpdate();
                                                 }} 
                                     style={{maxHeight: 20, cursor: 'pointer'}} src={deleteIcon}/>
                            </div>
                        </div>
        
        
                    </td>
                </tr>  
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}} colSpan={2}>
                        MUNKASZÁMOK
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div key={"workNumberCounter"} style={{display: 'flex', margin: 5}}>
                            <div>
                                { this.workNumberFilters}
                            </div>    
                        </div>
                    </td>
                </tr>    
        
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
        
                </tbody>   
            </table>
            <div>
                <button 
                    style={{minWidth: '100%'}}
                    onClick={this.reset.bind(this)}
        
        
                    >Alaphelyzet</button>
            </div>
        
        </div>);
    }
}

export default ChequeFilter;

