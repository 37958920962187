import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';

import WorkNumberListContent from './../../modules/WorkNumberList/WorkNumberListContent';
import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import MaskedInsertField from './../../components/MaskedInsertField';
import CRUDComboCustomerAddress from './CRUDComboCustomerAddress';
import CRUDComboCustomerBankAccountNumber from './CRUDComboCustomerBankAccountNumber';
import CRUDComboCustomerContactPerson from './CRUDComboCustomerContactPerson';

class CustomerUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer_id: ''
            , customer_customerType_id: ''
            , customer_customerName: ''
            , customer_taxNumber: ''
            , CustomerAddress: []
            , CustomerBankAccountNumber: []
            , CustomerContactPerson: []
            , WorkNumberList: []

        }


        this.references = [];
        this.references['customer_id'] = React.createRef();
        this.references['customer_customerName'] = React.createRef();
        this.references['customer_customerType_id'] = React.createRef();
        this.references['customer_taxNumber'] = React.createRef();
        this.references['customer_MTO'] = React.createRef();
        this.references['customer_MTO'] = React.createRef();
        this.references['customer_MTO'] = React.createRef();


        this.validators = [];
        this.validators['customer_id'] = {fieldName: 'customer_id', dataType: 'INT', value: this.state.customer_id, mandatory: false, min: null, max: null};
        this.validators['customer_customerName'] = {fieldName: 'customer_customerName', dataType: 'VARCHAR', value: this.state.customer_customerName, mandatory: false, min: null, max: null};
        this.validators['customer_customerType_id'] = {fieldName: 'customer_customerType_id', dataType: 'INT', value: this.state.customer_customerType_id, mandatory: true, min: 1, max: null};
        this.validators['customer_taxNumber'] = {fieldName: 'customer_taxNumber', dataType: 'VARCHAR', value: this.state.customer_taxNumber, mandatory: false, min: null, max: null};
        this.validators['customer_MTO'] = {fieldName: 'customer_MTO', dataType: 'MTO', value: this.state.customer_MTO, mandatory: false, min: null, max: null};
        this.validators['customer_MTO'] = {fieldName: 'customer_MTO', dataType: 'MTO', value: this.state.customer_MTO, mandatory: false, min: null, max: null};
        this.validators['customer_MTO'] = {fieldName: 'customer_MTO', dataType: 'MTO', value: this.state.customer_MTO, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({customer_id: ''
            , customer_customerName: ''
            , customer_customerType_id: ''
            , customer_taxNumber: ''
            , CustomerAddress: []
            , CustomerBankAccountNumber: []
            , CustomerContactPerson: []
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    addRow = function (customerAddress) {
        console.log(customerAddress);
    }

    getForm = function () {
        var customerId = this.props.additionalParams[0].customerAddress_customer_id;
        var customerAddressCustomerId = [{customerAddress_customer_id: customerId}];
        var customerBankAccountNumberCustomerId = [{customerBankAccountNumber_customer_id: customerId}];
        var customerContactPersonCustomerId = [{customerContactPerson_customer_id: customerId}];
        var workNumbercustomerid = [{workNumber_customer_id: customerId}];
        return <div style={{width: '99%'}}>
            <Row>
                <Col>Típus</Col><Col>Neve</Col><Col>Adószáma</Col>
            </Row>
            <Row>
        
                <Col  style={{textAlign: 'center'}}>
                <ComboBoxInsertField 
                    id={'customer_customerType_id'} 
                    value={this.state.customer_customerType_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Customer/customerType_idFormComboInsertData.php'}
                    reference={this.references['customer_customerType_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
        
                <Col style={{textAlign: 'center'}}  >
        
                <TextInsertField 
                    id={'customer_customerName'} 
                    value={this.state.customer_customerName}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['customer_customerName']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
        
        
                <Col style={{textAlign: 'center'}}   >
                <MaskedInsertField 
                    id={'customer_taxNumber'} 
                    value={this.state.customer_taxNumber}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['customer_taxNumber']}
                    editable={true}
                    theme={this.props.theme}
                    regexPattern={new RegExp(/^\d{8}-\d{1}-\d{2}$/)}
                    placeHolder="12345678-9-10"
                    />
                </Col>
            </Row>
            <Row style={{textAlign: 'left', marginTop: 40}}>
                <Col md={2}>
                Cím
                </Col>
                <Col style={{textAlign: 'right'}} md={10}>
                <CRUDComboCustomerAddress 
                    addRow={this.addRow.bind(this)}
                    id={'MTOCustomerAddress'} 
                    value={this.state.MTOCustomerAddress}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/CustomerAddress/CRUDComboCustomerAddress.php'}
                    reference={React.createRef()}
                    editable={true}
                    theme={this.props.theme}
                    stateRef={this.state}
                    customer_id={customerId}
                    />
                </Col>
            </Row>
            <Row style={{textAlign: 'left', marginTop: 40}}>
                <Col md={2}>
                Bankszámlaszám
                </Col>
                <Col style={{textAlign: 'right'}} md={10}>
                <CRUDComboCustomerBankAccountNumber 
                    addRow={this.addRow.bind(this)}
                    id={'MTOCustomerBankAccountNumber'} 
                    value={this.state.MTOCustomerBankAccountNumber}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/CustomerBankAccountNumber/CRUDComboCustomerBankAccountNumber.php'}
                    reference={React.createRef()}
                    editable={true}
                    theme={this.props.theme}
                    stateRef={this.state}
                    customer_id={customerId}
                    />
                </Col>
            </Row>
            <Row style={{textAlign: 'left', marginTop: 40}}>
                <Col md={2}>
                Kapcsolattartó
                </Col>
                <Col style={{textAlign: 'right'}} md={10}>
                <CRUDComboCustomerContactPerson 
                    addRow={this.addRow.bind(this)}
                    id={'MTOCustomerContactPerson'} 
                    value={this.state.MTOCustomerContactPerson}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/CustomerContactPerson/CRUDComboCustomerContactPerson.php'}
                    reference={React.createRef()}
                    editable={true}
                    theme={this.props.theme}
                    stateRef={this.state}
                    customer_id={customerId}
                    />
                </Col>
            </Row>
        
            <Row>
                <Col style={{textAlign: 'right'}}>
                <div>
                    <WorkNumberListContent   
                        value={this.state.WorkNumberList} 
                        setBack={this.setBack.bind(this)} 
                        additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : workNumbercustomerid}
                        editable={false}
                        formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                        theme={this.props.theme}
                        />
                </div>
                </Col>
            </Row>
        </div>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Customer/customerFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Customer/customerFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{width: 1000}} >
            <div className={'popupWrapper' + className} id="CustomerUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('CustomerUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'delete'} onClick={() => this.props.delete()} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('Delete')}</Button>&nbsp;&nbsp;&nbsp;      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default CustomerUpdate;

