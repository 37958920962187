import React, { Component } from 'react';
import MenuItem from "./../components/MenuItem";
import './../css/menu.css';
import * as lang from './../lang';


class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuItems: this.getMenu()
        };
    }

    getMenuBCK = function () {
        return [

            {id: 'Calendar', title: lang.getTitle('Calendar'), ico: 'employee', childrens: []},
            {id: 'Employee', title: lang.getTitle('Employee'), ico: 'employee', childrens: []},
            {id: 'UserRoles', title: lang.getTitle('UserRoles'), ico: 'UserRoles', childrens: []},
            {id: 'ChequeContent', title: lang.getTitle('Cheque'), ico: 'cheque', childrens: [
                    {id: 'ChequeContent', title: lang.getTitle('ChequeContent'), ico: 'chequeContent', childrens: []},
                ]},
            {id: 'GoogleDriveFiles', title: lang.getTitle('GoogleDriveFiles'), ico: 'GoogleDriveFiles', childrens: []},
            {id: 'Invoice', title: lang.getTitle('Invoice'), ico: 'doc', childrens: [
                    {id: 'InvoiceIncoming', title: lang.getTitle('InvoiceIncoming'), ico: 'doc', childrens: []},
                    {id: 'InvoiceOutgoing', title: lang.getTitle('InvoiceOutgoing'), ico: 'doc', childrens: []},
                ]},
        ]

    }

    getMenu = function () {
        return [

            {id: 'Calendar', title: lang.getTitle('Calendar'), ico: 'calendar', childrens: [
                    {id: 'OtherCosts', title: lang.getTitle('OtherCosts'), ico: 'calendar', childrens: []}
                ]},
            {id: 'Customer', title: lang.getTitle('Customer'), ico: 'customer', childrens: []},
            {id: 'Employee', title: lang.getTitle('Employee'), ico: 'employee', childrens: []},
            {id: 'WorkNumber', title: lang.getTitle('WorkNumber'), ico: 'workNumber', childrens: [
                    {id: 'WorkNumberOffer', title: lang.getTitle('WorkNumberOffer'), ico: 'workNumber', childrens: []},
                    {id: 'WorkNumberRunning', title: lang.getTitle('WorkNumberRunning'), ico: 'workNumber', childrens: []},
                    {id: 'WorkNumberPayable', title: lang.getTitle('WorkNumberPayable'), ico: 'workNumber', childrens: []}
                ]},

            {id: 'InvoiceIncoming', title: lang.getTitle('InvoiceIncoming'), ico: 'invoiceIncoming', childrens: [
                    {id: 'PayWithCash', title: lang.getTitle('PayWithCash'), ico: 'payWithCash', childrens: []},
                    {id: 'PayWithTransfer', title: lang.getTitle('PayWithTransfer'), ico: 'payWithTransfer', childrens: []}
                ]},

            {id: 'InvoiceOutgoing', title: lang.getTitle('InvoiceOutgoing'), ico: 'invoiceOutgoing', childrens: []},
            {id: 'cheque', title: lang.getTitle('Cheque'), ico: 'cheque', childrens: [
                    {id: 'ChequeIssuerContent', title: lang.getTitle('ChequeIssuer'), ico: 'ChequeIssuerContent', childrens: []},
                    {id: 'ChequeRemarkContent', title: lang.getTitle('ChequeRemark'), ico: 'ChequeRemarkContent', childrens: []},
                ]},
            {id: 'HouseTreasury', title: lang.getTitle('HouseTreasury'), ico: 'houseTreasury', childrens: []},

            {id: 'Analytics', title: lang.getTitle('analytics'), ico: 'analytics', childrens: []},
            {id: 'Results', title: lang.getTitle('Results'), ico: 'results', childrens: []},
            {id: 'BaseData', title: lang.getTitle('BaseData'), ico: 'baseData', childrens: [
                    {id: 'City', title: lang.getTitle('City'), ico: 'publicSpaceType', childrens: []},
                    {id: 'PublicSpaceType', title: lang.getTitle('PublicSpaceType'), ico: 'publicSpaceType', childrens: []},
                    {id: 'EmailAddressPostfix', title: lang.getTitle('EmailAddressPostfix'), ico: 'emailAddressPostfix', childrens: []},
                    {id: 'Vat', title: lang.getTitle('Vat'), ico: 'vat', childrens: []},
                    {id: 'CustomerState', title: lang.getTitle('CustomerState'), ico: 'customerState', childrens: []},
                    {id: 'GoogleDriveFiles', title: lang.getTitle('GoogleDriveFiles'), ico: 'GoogleDriveFiles', childrens: []},
                ]},
            {id: 'UserRoles', title: lang.getTitle('UserRoles'), ico: 'UserRoles', childrens: []},
        ];
    }

    setActiveMenu = function (menuId) {
        this.props.setContent(menuId);
        this.setState({});
    }

    getMenuItems = function () {

        var menuItems = this.state.menuItems;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            var menuChildrens = this.getMenuChidrens(item);
            items.push(
                    <MenuItem 
                        isChild={false}
                        activeMenuId={this.props.activeMenuId} 
                        setActiveMenu={this.setActiveMenu.bind(this)} 
                        key={"menu_item_" + i} 
                        item={item} 
                        collapsed={this.props.collapsed}
                        theme={this.props.theme}
                        >{menuChildrens}</MenuItem>

                    );
        }

        return items;

    }

    getMenuChidrens = function (menuItem) {

        var menuItems = menuItem.childrens;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            items.push(
                    <MenuItem 
                        isChild={true}
                        activeMenuId={this.props.activeMenuId} 
                        setActiveMenu={this.setActiveMenu.bind(this)} 
                        key={"menu_item_" + i} 
                        item={item} 
                        collapsed={this.props.collapsed}
                        theme={this.props.theme}
                        />

                    );
        }

        return items;

    }

    render() {
        var menuItems = this.getMenuItems();

        return <div style={{minHeight: '87vh', maxHeight: '87vh', overflowY: 'auto'}}>{menuItems}</div>;
    }
}

export default Menu;
