import React, { Component } from 'react';
import './../css/light.css';


class MaskedInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    onFocusLost = function (e) {
        if (typeof this.props.regexPattern != 'undefined' && typeof this.props.regexPattern != null && this.state.value != null && this.state.value.trim() != "") {
            var value = "" + this.state.value;
            if (this.props.regexPattern.test(value) == false) {
                alert("Hibás adat!");
            } else {
                this.setState({});
            }
        }
    }

    isValidValue = function (value) {
        if (typeof this.props.acceptedChars == 'undefined') {
            return true;
        }
        if (this.props.acceptedChars == null) {
            return true;
        }
        if (this.props.acceptedChars.length == 0) {
            return true;
        }


        if (typeof value == 'undefined') {
            return true;
        }
        if (typeof value == null) {
            return true;
        }
        value = value + "";
        for (var i = 0; i < value.length; i++) {
            var currentChar = value[i];
            if(this.props.acceptedChars.indexOf(currentChar)==-1){
                return false;
            }
        }
        return true;
    }

    onChange = function (e) {
        // var regexPattern={new RegExp(/^\+[0-9]{2}-[0-9]{2}-[0-9]{7}$/)} // -TELEFONSZÁM - LECSERÉLVE
        // var pattern =  /^\d{8}-\d{1}-\d{2}$/; // -ADÓSZÁM 012345678-9-10 - LECSERÉLVE
        // var pattern =  /^\d{10}$/; // ADÓAZONOSÍTÓ JEL  0123456789  - LECSERÉLVE
        // var pattern = /^.*@.*\.[a-zA-z].*$/; // E-mail cím - LECSERÉLVE
        // var pattern =  /^\d{10}$/; // CÉGJEGYZÉKSZÁM JEL  0123456789  - 
        if(this.isValidValue(e.target.value) == false){
            return false;
        }


        
        this.props.setBack(e.target.id, e.target.value);
        this.setState({value: e.target.value});
    }

    render() {
        
        var style = {textAlign: 'left'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            
           style = Object.assign({},this.props.style);
            
            style['textAlign'] = 'left';
        }


        if (typeof this.props.regexPattern != 'undefined' && typeof this.props.regexPattern != null && this.state.value != null && this.state.value.trim() != "") {
            var value = "" + this.state.value;
            if (this.props.regexPattern.test(value) == false) {
                style['color'] = "#FF000099";
            } else {
                style['color'] = "#25323b";
            }
        }

        return  <div>
            <input 
                onChange={this.onChange.bind(this)} 
                type={'text'} 
                id={this.props.id} 
                value={this.state.value} 
                placeHolder={(typeof this.props.placeHolder != 'undefined') ? this.props.placeHolder : ''}
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                style={style}
                className ={"componentLight"}
                onBlur={this.onFocusLost.bind(this)}
                autoComplete="off"
                />
        </div>;
    }
}

export default MaskedInsertField;
