import React, { Component } from 'react';
import PopUpWindow from './PopUpWindow';
import upload_dnd from './../img/black/upload_dnd.svg';
import alert from './../img/dialog/alert.svg';
import * as ajax from './../ajax';


class MultiFileChooser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            referenceFajlHelye: React.createRef(),
            files: [],
            uploadedCount: 0
        };
        this.counter = 0;
    }
    displayFiles = function () {
        var formElements = [];

        var files = this.state.files;
        for (var i = 0; i < files.length; i++) {
            var fileDescriptor = files[i];
            var formElement = this.displayFile(fileDescriptor);
            formElements.push(formElement);
        }

        return formElements;
    }

    deleteFile = function (e) {
        var id = e.target.id.split('_')[1];
        this.state.files.splice(this.state.files.findIndex(function (i) {
            return i.id == id;
        }), 1);
        this.setState({});
    }

    displayFile = function (fileDescriptor) {
        var backgroundTheme = (this.props.blackTheme) ? "#313943" : "#FFFFFF";
        var foregroundTheme = (this.props.blackTheme) ? "#FFFFFF" : "#313943";
        return <div key={"multiFileDiv_" + fileDescriptor.id} style={{display: 'flex', borderBottom: 'solid 1px #00000022'}}>
            <div style={{width: '100%', marginLeft: '20px', backgroundColor: backgroundTheme, color: foregroundTheme}}>
                {fileDescriptor.fileName}
            </div>
            <div style={{marginRight: '20px'}}>
                <img 
                    id={"delete_" + fileDescriptor.id}
                    style={{
                                    maxWidth: '20px',
                                    maxHeight: '20px',
                                    marginTop: '10px',
                                    cursor: 'pointer'
                                }} 
                    src={alert} 
                    title={"Törlés"}
                    onClick={this.deleteFile.bind(this)}
                    />
            </div>
        
        </div>


    }

    getBase64 = function (file, fileName) {
        var reader = new FileReader();
        var self = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
            var fileDescriptor = {id: self.counter++, fileName: fileName, file: reader.result};
            self.state.files.push(fileDescriptor);
            self.setState({});
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    updloadPDF = function (e) {
        var files = e.target.files;
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var fileName = file['name'];
            this.getBase64(file, fileName);
        }
    }

    dropHandler = function (ev) {
        ev.preventDefault();
        ev.stopPropagation()

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    // console.log('... file[' + i + '].name = ' + file.name);
                    this.getBase64(file, file.name);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                // console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
            }
        }
        //  console.log(ev);
    }
    dragOverHandler = function (ev) {
        ev.preventDefault()
        //  console.log(ev);
    }

    sendAll() {

        for (var i = 0; i < this.state.files.length; i++) {
            var file = this.state.files[i];
            this.send(file);
        }
    }

    send = function (file) {
        try {
            const url = ajax.getUrlBase() + "modules/Upload/uploadFilesArray.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({activeNodeId:this.props.activeNodeId ,invoiceDirection: this.props.invoiceDirection, filesArray: [file]})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.setState({uploadedCount: this.state.uploadedCount + 1});
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            if (typeof response.data != 'undefined' && response.data != null) {
                                if (this.state.uploadedCount == this.state.files.length) {
                                    this.counter = 0;
                                    this.setState({files: [],uploadedCount: 0});
                                    
                                }
                            }
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    render() {
        var popupBackground = (this.props.blackTheme) ? "#313943" : "#FFFFFF";
        var displayFiles = this.displayFiles();
        var updloaded = (this.state.uploadedCount == 0 || this.state.files.length == 0) ? 0 : ((this.state.uploadedCount * 1.0) / (this.state.files.length * 1.0)) * 100.0;
        var updloadedPercent = updloaded + '%';
        
        if(this.props.activeNodeId==null){
            return "";
        }
        
        return <div>
            <div>
                <input 
                    ref={this.state.referenceFajlHelye}
                    onChange={this.updloadPDF.bind(this)} 
                    type="file"
                    style={{maxWidth: '0px', maxHeight: '0px',border:'none'}} 
                    multiple={true}
                    />
                <button className={"bookingFormButton titleSmall"} 
                        style={{marginLeft: '20px'}}
                        onClick={() => {
                                        this.state.referenceFajlHelye.current.click();
                                    }}
                        >Fájlok kiválasztása</button>
            </div>
            <div
                style={{
                                minHeight: '20px',
                                maxHeight: '20px',
                                marginLeft: '20px',
                                marginRight: '20px',
                                overflowY: 'hidden',
                                backgroundColor: popupBackground,
                                display: 'flex'
                            }}
                >
        
                <div style={{textAlign: 'center', justifyContent: 'center', marginRight: '10px'}}>{this.state.files.length}/{this.state.uploadedCount}</div>
                <div style={{width: '100%', backgroundColor: popupBackground, minHeight: '20px',display:'flex'}}>
                    <div style={{width: updloadedPercent, backgroundColor: '#9fd027', minHeight: '10px', maxHeight: '10px',marginTop:'10px'}}></div>
                    
                </div>
        
            </div>
            <div 
                style={{
                                minHeight: '260px',
                                maxHeight: '260px',
                                margin: '20px',
                                overflowY: 'auto',
                                
                                boxShadow:'0px 0px 4px #00000088',
                                backgroundImage: `url(${upload_dnd})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize:'cover'
                            }}
                onDrop={this.dropHandler.bind(this)} 
                onDragOver={this.dragOverHandler.bind(this)}
                id={"fileDropTarget"}
                >
                {displayFiles}
            </div>
            <div style={{
                            width: '100%',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center'

                        }}
                 >
        
                <button 
                    style={{ backgroundColor: "green",marginRight:10,textAlign:'center'}}
                    className={"bookingFormButton titleSmall"} 
                    onClick={() => this.sendAll()} >Rögzítés</button>
                <button 
                    className={"bookingFormButton titleSmall"} 
                    onClick={() => this.props.multiUploadFileChooserClose()} >Mégsem</button>
            </div>
        </div>
    }
}

export default MultiFileChooser;
    
