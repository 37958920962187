import React, { Component } from 'react';
import './../css/light.css';



class PopUpWindow extends React.Component {

    render() {
        
        var style={};
        if(typeof this.props.style != "undefined"  && this.props.style != null){
           style=this.props.style;
        }
        var className = (this.props.theme=="true")?"Dark":"Light";
        return  <div className={'popupFrame'+className}>
                    <div style={style}  className={'form'+className}>{this.props.children}</div>
                </div>;
    }
}

export default PopUpWindow;
