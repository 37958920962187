
import React, { Component } from 'react';
import VatTable from './VatTable.js';
import VatInsertForm from './VatInsertForm.js';
import VatUpdateForm from './VatUpdateForm.js';
import VatViewForm from './VatViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class VatContent extends Component {

    constructor(props) {
        super(props);
        this.state={theme:this.props.theme}
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }
    
    setBack  = function(id,value){
        // Nothing to do here
    }
    render() {

        var table = <VatTable 
                     setBack={this.setBack.bind(this)} 
                     additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                     formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                     theme={this.state.theme}
                    />;
                    
                    
                                
        return (
                 <div  id="VatContent">
                    <div className="tableTitle"><b>{lang.getTitle("Vat")}</b></div> 
                    {table}
                </div>
                );
    }
}

export default VatContent;
