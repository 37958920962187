import React, { Component }
from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';
import {Button}
from 'react-bootstrap';
class VATAnalytics extends Component {

    constructor(props) {
        super(props);

        this.data = this.props.data;
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    update = function (data) {
        console.log(data);
        this.data = data;
        this.forceUpdate();
    }

    getFormatted = function (val) {
        if (val == null) {
            return 0;
        }
        if (val == '') {
            return 0;
        }

        val = Math.round(val);
        return new Number(val).toLocaleString();
    }

    getInvoice = function (type, netto, VAT, sum) {
        return <table style={{width: '95%', marginTop: 20, fontFamily: 'Robo', fontSize: 12}}>
            <thead>
                <tr><th colspan={2}>{type}</th></tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{textAlign: 'left'}}>Nettó</td><td  style={{textAlign: 'right'}}>{this.getFormatted(netto)}</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left'}}>ÁFA</td><td  style={{textAlign: 'right'}}>{this.getFormatted(VAT)}</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'left'}}>Bruttó</td><td  style={{textAlign: 'right'}}>{this.getFormatted(sum)}</td>
                </tr>
            </tbody>
        </table>
    }

    getVATSummary = function (summaryVAT) {
        var rows = [];
        var sumAfa = 0.0;
        for (var i = 0; i < summaryVAT.length; i++) {
            var row = summaryVAT[i];
            if (row.hasOwnProperty('sum_afa')) {
                sumAfa = row.sum_afa;
            } else {
                rows.push(<tr><td style={{textAlign: 'left'}}>{row.VATName}</td><td style={{textAlign: 'right'}}>{this.getFormatted(row.VATValue)}</td></tr>);
            }
        }
        rows.push(<tr><td style={{textAlign: 'left'}}>Sum ÁFA</td><td style={{textAlign: 'right'}} >{this.getFormatted(sumAfa)}</td></tr>);
        return <table style={{width: '95%', marginTop: 20}}><thead><tr><th colspan={2}>Összegzés</th></tr></thead><tbody>{rows}</tbody></table>;
    }

    render() {

        if (this.data == null) {
            return "";
        }

        var data = this.data;
        var invoiceIncomingNet = data.invoiceIncomingNet;
        var invoiceIncomingVAT = data.invoiceIncomingVAT;
        var invoiceIncomingSUM = data.invoiceIncomingSUM;
        var invoiceOutgoingNet = data.invoiceOutgoingNet;
        var invoiceOutgoingVAT = data.invoiceOutgoingVAT;
        var invoiceOutgoingSUM = data.invoiceOutgoingSUM;
        var summaryVAT = data.summaryVAT;
        var incoming = this.getInvoice("Bejövő számla", invoiceIncomingNet, invoiceIncomingVAT, invoiceIncomingSUM);
        var outgoing = this.getInvoice("Kimenő számla", invoiceOutgoingNet, invoiceOutgoingVAT, invoiceOutgoingSUM);
        var summary = this.getVATSummary(summaryVAT);
        return <div style={{marginTop: 20}}>

    <div>
{incoming}
    </div>
    <div>
{outgoing}
    </div>
    <div>
{summary}
    </div>
</div>
    }
}

export default VATAnalytics;

