
import React, { Component } from 'react';

import {Button,Col,Row} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import AnalyticsTable from './AnalyticsTable';
import InvoiceFilter from './../../ComponentsSpecial/InvoiceFilter';

class AnalyticsContent extends Component {

    constructor(props) {
        super(props);
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    setBack = function (id, value) {
        // Nothing to do here
    }




    render() {
        return (<div>
           
            <div  id="Analytics">
                <div className="tableTitle"><b>{lang.getTitle("Analytics")}</b></div> 
                <div style={{backgroundColor: 'white', minHeight: '86vh'}} >
                    <AnalyticsTable  />
                </div>
            </div>
        </div>
                );
    }
}

export default AnalyticsContent;
