import React, { Component } from 'react';
import './../css/light.css';



class DateInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    onChange = function (e) {
        this.props.setBack(e.target.id, e.target.value);
        this.setState({value: e.target.value});
    }

    render() {
        
        var className = (this.props.theme=="true")?"Dark":"Light";
        
        return  <div>
            <input 
                onChange={this.onChange.bind(this)} 
                type={'date'} 
                id={this.props.id} 
                value={this.state.value} 
                ref={this.props.reference}
                readOnly={this.props.editable==false}
                className ={"component"+className}
                style={{paddingRight:0}}
                />
        </div>;
    }
}

export default DateInsertField;
