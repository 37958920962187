import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ContactPersonComboByCustomer from './ContactPersonComboByCustomer';
import CustomerAddressCombo from './../../ComponentsSpecial/CustomerAddressCombo';
import NumberInsertField from './../../components/NumberInsertField';
import DateInsertField from './../../components/DateInsertField';
import RemoteDataDisplayField from './../../components/RemoteDataDisplayField';
import MaskedInsertField from './../../components/MaskedInsertField';



class WorkNumberInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            workNumber_workNumberName: ''
            , workNumber_sequenceNumber: ''
            , workNumber_cost: '0'
            , workNumber_projectName: ''
            , workNumber_customerState_id: ''
            , workNumber_customer_id: ''
            , workNumber_workNumberSubject: ''
            , workNumber_state: ''
            , workNumber_offerRequestDate: ''
            , workNumber_projectStartDate: ''
            , workNumber_customerAddress1_id: ''
            , workNumber_taxNumber: ''
            , workNumber_companyRegistrationNumber: ''
            , workNumber_emailAddressPrefix: ''
            , workNumber_customerContactPerson_id: ''
            , workNumber_customerBankAccountNumber_id: ''
            , workNumber_agreedPriceNET: ''
            , workNumber_vat_id: ''
            , workNumber_vatName: ''
            , workNumber_vatValue: ''
            , workNumber_grossValue: ''
            , workNumber_plannedStartDate: ''
            , workNumber_plannedEndDate: ''

        }


        this.references = [];
        this.references['workNumber_workNumberName'] = React.createRef();
        this.references['workNumber_sequenceNumber'] = React.createRef();
        this.references['workNumber_cost'] = React.createRef();
        this.references['workNumber_projectName'] = React.createRef();
        this.references['workNumber_customerState_id'] = React.createRef();
        this.references['workNumber_customer_id'] = React.createRef();
        this.references['workNumber_workNumberSubject'] = React.createRef();
        this.references['workNumber_state'] = React.createRef();
        this.references['workNumber_offerRequestDate'] = React.createRef();
        this.references['workNumber_projectStartDate'] = React.createRef();
        this.references['workNumber_customerAddress1_id'] = React.createRef();
        this.references['workNumber_taxNumber'] = React.createRef();
        this.references['workNumber_companyRegistrationNumber'] = React.createRef();
        this.references['workNumber_emailAddressPrefix'] = React.createRef();
        this.references['workNumber_customerContactPerson_id'] = React.createRef();
        this.references['workNumber_customerBankAccountNumber_id'] = React.createRef();
        this.references['workNumber_agreedPriceNET'] = React.createRef();
        this.references['workNumber_vat_id'] = React.createRef();
        this.references['workNumber_vatName'] = React.createRef();
        this.references['workNumber_vatValue'] = React.createRef();
        this.references['workNumber_grossValue'] = React.createRef();
        this.references['workNumber_plannedStartDate'] = React.createRef();
        this.references['workNumber_plannedEndDate'] = React.createRef();


        this.validators = [];
        this.validators['workNumber_workNumberName'] = {fieldName: 'workNumber_workNumberName', dataType: 'VARCHAR', value: this.state.workNumber_workNumberName, mandatory: false, min: null, max: null};
        this.validators['workNumber_sequenceNumber'] = {fieldName: 'workNumber_sequenceNumber', dataType: 'VARCHAR', value: this.state.workNumber_sequenceNumber, mandatory: false, min: null, max: null};
        this.validators['workNumber_cost'] = {fieldName: 'workNumber_cost', dataType: 'TINYINT', value: this.state.workNumber_cost, mandatory: false, min: null, max: null};
        this.validators['workNumber_projectName'] = {fieldName: 'workNumber_projectName', dataType: 'VARCHAR', value: this.state.workNumber_projectName, mandatory: false, min: null, max: null};
        this.validators['workNumber_customerState_id'] = {fieldName: 'workNumber_customerState_id', dataType: 'INT', value: this.state.workNumber_customerState_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_customer_id'] = {fieldName: 'workNumber_customer_id', dataType: 'INT', value: this.state.workNumber_customer_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_workNumberSubject'] = {fieldName: 'workNumber_workNumberSubject', dataType: 'VARCHAR', value: this.state.workNumber_workNumberSubject, mandatory: false, min: null, max: null};
        this.validators['workNumber_state'] = {fieldName: 'workNumber_state', dataType: 'INT', value: this.state.workNumber_state, mandatory: true, min: null, max: null};
        this.validators['workNumber_offerRequestDate'] = {fieldName: 'workNumber_offerRequestDate', dataType: 'DATE', value: this.state.workNumber_offerRequestDate, mandatory: false, min: null, max: null};
        this.validators['workNumber_projectStartDate'] = {fieldName: 'workNumber_projectStartDate', dataType: 'DATE', value: this.state.workNumber_projectStartDate, mandatory: false, min: null, max: null};
        this.validators['workNumber_customerAddress1_id'] = {fieldName: 'workNumber_customerAddress1_id', dataType: 'INT', value: this.state.workNumber_customerAddress1_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_taxNumber'] = {fieldName: 'workNumber_taxNumber', dataType: 'VARCHAR', value: this.state.workNumber_taxNumber, mandatory: false, min: null, max: null};
        this.validators['workNumber_companyRegistrationNumber'] = {fieldName: 'workNumber_companyRegistrationNumber', dataType: 'VARCHAR', value: this.state.workNumber_companyRegistrationNumber, mandatory: false, min: null, max: null};
        this.validators['workNumber_emailAddressPrefix'] = {fieldName: 'workNumber_emailAddressPrefix', dataType: 'VARCHAR', value: this.state.workNumber_emailAddressPrefix, mandatory: false, min: null, max: null};
        this.validators['workNumber_customerContactPerson_id'] = {fieldName: 'workNumber_customerContactPerson_id', dataType: 'INT', value: this.state.workNumber_customerContactPerson_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_customerBankAccountNumber_id'] = {fieldName: 'workNumber_customerBankAccountNumber_id', dataType: 'INT', value: this.state.workNumber_customerBankAccountNumber_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_agreedPriceNET'] = {fieldName: 'workNumber_agreedPriceNET', dataType: 'DOUBLE', value: this.state.workNumber_agreedPriceNET, mandatory: false, min: null, max: null};
        this.validators['workNumber_vat_id'] = {fieldName: 'workNumber_vat_id', dataType: 'INT', value: this.state.workNumber_vat_id, mandatory: false, min: null, max: null};
        this.validators['workNumber_vatName'] = {fieldName: 'workNumber_vatName', dataType: 'VARCHAR', value: this.state.workNumber_vatName, mandatory: false, min: null, max: null};
        this.validators['workNumber_vatValue'] = {fieldName: 'workNumber_vatValue', dataType: 'DOUBLE', value: this.state.workNumber_vatValue, mandatory: false, min: null, max: null};
        this.validators['workNumber_grossValue'] = {fieldName: 'workNumber_grossValue', dataType: 'DOUBLE', value: this.state.workNumber_grossValue, mandatory: false, min: null, max: null};
        this.validators['workNumber_plannedStartDate'] = {fieldName: 'workNumber_plannedStartDate', dataType: 'DATE', value: this.state.workNumber_plannedStartDate, mandatory: false, min: null, max: null};
        this.validators['workNumber_plannedEndDate'] = {fieldName: 'workNumber_plannedEndDate', dataType: 'DATE', value: this.state.workNumber_plannedEndDate, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.driveInProgress = false;


        this.taxLoaded = false;
        this.taxIdAndValues = null;

        this.preloadVAT();


    }

    preloadVAT = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Invoice/vatPreloader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.taxLoaded = true;
                            this.taxIdAndValues = response.data;

                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    getVATValueById = function (id) {
        for (var i = 0; i < this.taxIdAndValues.length; i++) {
            var vat = this.taxIdAndValues[i];
            if (vat.id == id) {
                return vat.vatValue;
            }
        }
        return null;
    }

    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({workNumber_workNumberName: ''
            , workNumber_sequenceNumber: ''
            , workNumber_cost: '0'
            , workNumber_projectName: ''
            , workNumber_customerState_id: ''
            , workNumber_customer_id: ''
            , workNumber_workNumberSubject: ''
            , workNumber_state: ''
            , workNumber_offerRequestDate: ''
            , workNumber_projectStartDate: ''
            , workNumber_customerAddress1_id: ''
            , workNumber_taxNumber: ''
            , workNumber_companyRegistrationNumber: ''
            , workNumber_emailAddressPrefix: ''
            , workNumber_customerContactPerson_id: ''
            , workNumber_customerBankAccountNumber_id: ''
            , workNumber_agreedPriceNET: ''
            , workNumber_vat_id: ''
            , workNumber_vatName: ''
            , workNumber_vatValue: ''
            , workNumber_grossValue: ''
            , workNumber_plannedStartDate: ''
            , workNumber_plannedEndDate: ''
        });
    }

    setBack = function (id, value) {
        var blocked = false;

        if (id == 'workNumber_vat_id') {
            blocked = true;
            var vatPercentVal = this.getVATValueById(value);
            this.state.workNumber_vat_id = value;
            this.state.workNumber_vatValue = (this.state.workNumber_agreedPriceNET / 100) * vatPercentVal;
            this.state.workNumber_grossValue = this.state.workNumber_agreedPriceNET + this.state.workNumber_vatValue;
            this.setState({});

        }

        if (id == "workNumber_agreedPriceNET") {
            blocked = true;
            var vatPercentVal = this.getVATValueById(this.state.workNumber_vat_id);
            this.state.workNumber_agreedPriceNET = value;
            this.state.workNumber_vatValue = (this.state.workNumber_agreedPriceNET / 100) * vatPercentVal;
            this.state.workNumber_grossValue = this.state.workNumber_agreedPriceNET + this.state.workNumber_vatValue;
            this.setState({});
        }
        if (blocked == false) {
            this.setState({[id]: value});
        }
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{marginRight: 20}}>
                <Row>
                    <Col className="formParts">
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_state')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <ComboBoxInsertField 
                            id={'workNumber_state'} 
                            value={this.state.workNumber_state}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/stateFormComboInsertData.php'}
                            reference={this.references['workNumber_state']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_sequenceNumber')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <TextInsertField 
                            id={'workNumber_sequenceNumber'} 
                            value={this.state.workNumber_sequenceNumber}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_sequenceNumber']}
                            editable={false}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
        
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_projectName')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <TextInsertField 
                            id={'workNumber_projectName'} 
                            value={this.state.workNumber_projectName}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_projectName']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>
        
        
        
                <Row>
                    <Col className="formParts">
        
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_customer_id')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <ComboBoxInsertField 
                            id={'workNumber_customer_id'} 
                            value={this.state.workNumber_customer_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/customer_idFormComboInsertData.php'}
                            reference={this.references['workNumber_customer_id']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_customerAddress1_id')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <CustomerAddressCombo 
                            id={'workNumber_customerAddress1_id'} 
                            customer_id={this.state.workNumber_customer_id}
                            value={this.state.workNumber_customerAddress1_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/customerAddress_idFormComboInsertData.php'}
                            reference={this.references['workNumber_customerAddress1_id']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_taxNumber')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <RemoteDataDisplayField 
                            id={'workNumber_taxNumber'} 
                            value={this.state.workNumber_customer_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/remoteCostumerTaxNumberData.php'}                        
                            reference={this.references['workNumber_taxNumber']}
                            editable={false}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_companyRegistrationNumber')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <MaskedInsertField 
                            id={'workNumber_companyRegistrationNumber'} 
                            regexPattern={new RegExp(/^[0-9]{2}-[0-9]{2}-[0-9]{6}$/)}
                            placeHolder="12-12-123456"
                            value={this.state.workNumber_companyRegistrationNumber}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_companyRegistrationNumber']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_customerBankAccountNumber_id')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <RemoteDataDisplayField 
                            id={'workNumber_customerBankAccountNumber_id'} 
                            value={this.state.workNumber_customer_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/remoteCostumerBankAccountNumberData.php'}                        
                            reference={this.references['workNumber_customerBankAccountNumber_id']}
                            editable={false}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
        
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_emailAddressPrefix')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <TextInsertField 
                            id={'workNumber_emailAddressPrefix'} 
                            value={this.state.workNumber_emailAddressPrefix}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_emailAddressPrefix']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_customerContactPerson_id')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <ContactPersonComboByCustomer 
                            id={'workNumber_customerContactPerson_id'} 
                            value={this.state.workNumber_customerContactPerson_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/customerContactPerson_idFormComboInsertData.php'}
                            reference={this.references['workNumber_customerContactPerson_id']}
                            editable={true}
                            theme={this.props.theme}
                            customerId={this.state.workNumber_customer_id}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>
        
                </Col>
                <Col>
                <Row>
                    <Col className="formParts">
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_agreedPriceNET')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <NumberInsertField 
                            id={'workNumber_agreedPriceNET'} 
                            value={this.state.workNumber_agreedPriceNET}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_agreedPriceNET']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('ÁFA %')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <ComboBoxInsertField 
                            id={'workNumber_vat_id'} 
                            value={this.state.workNumber_vat_id}  
                            setBack={this.setBack.bind(this)}
                            dataSourceUrl={'modules/WorkNumber/vat_idFormComboInsertData.php'}
                            reference={this.references['workNumber_vat_id']}
                            editable={true}
                            theme={this.props.theme}
                            style={{textAlign: 'right'}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_vatValue')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <NumberInsertField 
                            id={'workNumber_vatValue'} 
                            value={this.state.workNumber_vatValue}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_vatValue']}
                            editable={false}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_grossValue')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <NumberInsertField 
                            id={'workNumber_grossValue'} 
                            value={this.state.workNumber_grossValue}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_grossValue']}
                            editable={false}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="formParts">
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_offerRequestDate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <DateInsertField 
                            id={'workNumber_offerRequestDate'} 
                            value={this.state.workNumber_offerRequestDate}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_offerRequestDate']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_plannedStartDate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <DateInsertField 
                            id={'workNumber_plannedStartDate'} 
                            value={this.state.workNumber_plannedStartDate}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_plannedStartDate']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_plannedEndDate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <DateInsertField 
                            id={'workNumber_plannedEndDate'} 
                            value={this.state.workNumber_plannedEndDate}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_plannedEndDate']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
        
        
                    <Row>
                        <Col style={{textAlign: 'left'}}>{lang.getTitle('workNumber_projectStartDate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <DateInsertField 
                            id={'workNumber_projectStartDate'} 
                            value={this.state.workNumber_projectStartDate}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['workNumber_projectStartDate']}
                            editable={true}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        
        if(this.state.workNumber_state==1 && this.state.workNumber_offerRequestDate==""){
            alert("Ajánlatnál kötelező az 'Ajánlat kérés dátum' megadása. ");
            return false;
        }
        
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/WorkNumber/workNumberFormInsert.php";
            this.driveInProgress = true;
            this.setState({});
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                this.driveInProgress = false;
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";

        var progress = "";
        if (this.driveInProgress == true) {
            const url = ajax.getUrlBase() + "img/progress.gif";
            progress = <PopUpWindow theme={this.props.theme} >
                <div>A GOOGLE DRIVE MAPPÁK LÉTREHOZÁSA FOLYAMATBAN. KÉREM VÁRJON!</div>
                <img src={url}/>
            </PopUpWindow>
        }

        return (<PopUpWindow theme={this.props.theme} >
            <div className={'popupWrapper' + className} id="WorkNumberInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('WorkNumberInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
        
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
            {progress}
        </PopUpWindow>);
    }
}

export default WorkNumberInsert;

