
import React, { Component } from 'react';
import CalendarTable from './CalendarTable.js';
import CalendarInsertForm from './CalendarInsertForm.js';
import CalendarUpdateForm from './CalendarUpdateForm.js';
import CalendarViewForm from './CalendarViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class CalendarContent extends Component {

    constructor(props) {
        super(props);
        this.state={theme:this.props.theme}
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }
    
    setBack  = function(id,value){
        // Nothing to do here
    }
    render() {

        var table = <CalendarTable 
                     setBack={this.setBack.bind(this)} 
                     additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                     formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                     theme={this.state.theme}
                    />;
                    
                      
        return (
                  <div  id="CalendarContent">
                    <div className="tableTitle"><b>{lang.getTitle("Calendar")}</b></div> 
                
                    {table}
                </div>
                );
    }
}

export default CalendarContent;
