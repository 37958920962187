import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import DateInsertField from './../../components/DateInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextInsertField from './../../components/TextInsertField';


class CalendarInsert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            calendar_employe_id: ''
            , calendar_workingDate: ''
            , calendar_workingDay: ''
            , calendar_customerAddress1_id: ''
            , calendar_workNumber1_id: ''
            , calendar_numberOfHoursSum: ''
            , calendar_otherBenefits: ''
            , calendar_remark: ''
            , calendar_calendarName: ''
            , calendar_salaryDeductionRemark: ''
            , calendar_otherBenefitsRemark: ''
            , calendar_salaryDeduction: ''
            , calendar_summary: ''

        }


        this.references = [];
        this.references['calendar_employe_id'] = React.createRef();
        this.references['calendar_workingDate'] = React.createRef();
        this.references['calendar_workingDay'] = React.createRef();
        this.references['calendar_customerAddress1_id'] = React.createRef();
        this.references['calendar_workNumber1_id'] = React.createRef();
        this.references['calendar_numberOfHoursSum'] = React.createRef();
        this.references['calendar_otherBenefits'] = React.createRef();
        this.references['calendar_remark'] = React.createRef();
        this.references['calendar_calendarName'] = React.createRef();
        this.references['calendar_salaryDeductionRemark'] = React.createRef();
        this.references['calendar_otherBenefitsRemark'] = React.createRef();
        this.references['calendar_salaryDeduction'] = React.createRef();
        this.references['calendar_summary'] = React.createRef();


        this.validators = [];
        this.validators['calendar_employe_id'] = {fieldName: 'calendar_employe_id', dataType: 'INT', value: this.state.calendar_employe_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workingDate'] = {fieldName: 'calendar_workingDate', dataType: 'DATE', value: this.state.calendar_workingDate, mandatory: false, min: null, max: null};
        this.validators['calendar_workingDay'] = {fieldName: 'calendar_workingDay', dataType: 'INT', value: this.state.calendar_workingDay, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress1_id'] = {fieldName: 'calendar_customerAddress1_id', dataType: 'INT', value: this.state.calendar_customerAddress1_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber1_id'] = {fieldName: 'calendar_workNumber1_id', dataType: 'INT', value: this.state.calendar_workNumber1_id, mandatory: false, min: null, max: null};
        this.validators['calendar_numberOfHoursSum'] = {fieldName: 'calendar_numberOfHoursSum', dataType: 'DOUBLE', value: this.state.calendar_numberOfHoursSum, mandatory: false, min: null, max: null};
        this.validators['calendar_otherBenefits'] = {fieldName: 'calendar_otherBenefits', dataType: 'DOUBLE', value: this.state.calendar_otherBenefits, mandatory: false, min: null, max: null};
        this.validators['calendar_remark'] = {fieldName: 'calendar_remark', dataType: 'VARCHAR', value: this.state.calendar_remark, mandatory: false, min: null, max: null};
        this.validators['calendar_calendarName'] = {fieldName: 'calendar_calendarName', dataType: 'VARCHAR', value: this.state.calendar_calendarName, mandatory: false, min: null, max: null};
        this.validators['calendar_salaryDeductionRemark'] = {fieldName: 'calendar_salaryDeductionRemark', dataType: 'VARCHAR', value: this.state.calendar_salaryDeductionRemark, mandatory: false, min: null, max: null};
        this.validators['calendar_otherBenefitsRemark'] = {fieldName: 'calendar_otherBenefitsRemark', dataType: 'VARCHAR', value: this.state.calendar_otherBenefitsRemark, mandatory: false, min: null, max: null};
        this.validators['calendar_salaryDeduction'] = {fieldName: 'calendar_salaryDeduction', dataType: 'DOUBLE', value: this.state.calendar_salaryDeduction, mandatory: false, min: null, max: null};
        this.validators['calendar_summary'] = {fieldName: 'calendar_summary', dataType: 'DOUBLE', value: this.state.calendar_summary, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

    }

    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({calendar_employe_id: ''
            , calendar_workingDate: ''
            , calendar_workingDay: ''
            , calendar_customerAddress1_id: ''
            , calendar_workNumber1_id: ''
            , calendar_numberOfHoursSum: ''
            , calendar_otherBenefits: ''
            , calendar_remark: ''
            , calendar_calendarName: ''
            , calendar_salaryDeductionRemark: ''
            , calendar_otherBenefitsRemark: ''
            , calendar_salaryDeduction: ''
            , calendar_summary: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <Container>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_employe_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'calendar_employe_id'} 
                    value={this.state.calendar_employe_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Calendar/employe_idFormComboInsertData.php'}
                    reference={this.references['calendar_employe_id']}
                    editable={false}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_workingDate')}</Col>
                <Col style={{textAlign: 'right'}}>
                <DateInsertField 
                    id={'calendar_workingDate'} 
                    value={this.state.calendar_workingDate}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_workingDate']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_workingDay')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'calendar_workingDay'} 
                    value={this.state.calendar_workingDay}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_workingDay']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_customerAddress1_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'calendar_customerAddress1_id'} 
                    value={this.state.calendar_customerAddress1_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Calendar/customerAddress1_idFormComboInsertData.php'}
                    reference={this.references['calendar_customerAddress1_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_workNumber1_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'calendar_workNumber1_id'} 
                    value={this.state.calendar_workNumber1_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/Calendar/workNumber1_idFormComboInsertData.php'}
                    reference={this.references['calendar_workNumber1_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_numberOfHoursSum')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'calendar_numberOfHoursSum'} 
                    value={this.state.calendar_numberOfHoursSum}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_numberOfHoursSum']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_otherBenefits')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'calendar_otherBenefits'} 
                    value={this.state.calendar_otherBenefits}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_otherBenefits']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_remark')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'calendar_remark'} 
                    value={this.state.calendar_remark}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_remark']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_calendarName')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'calendar_calendarName'} 
                    value={this.state.calendar_calendarName}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_calendarName']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_salaryDeductionRemark')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'calendar_salaryDeductionRemark'} 
                    value={this.state.calendar_salaryDeductionRemark}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_salaryDeductionRemark']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_otherBenefitsRemark')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'calendar_otherBenefitsRemark'} 
                    value={this.state.calendar_otherBenefitsRemark}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_otherBenefitsRemark']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_salaryDeduction')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'calendar_salaryDeduction'} 
                    value={this.state.calendar_salaryDeduction}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_salaryDeduction']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_summary')}</Col>
                <Col style={{textAlign: 'right'}}>
                <NumberInsertField 
                    id={'calendar_summary'} 
                    value={this.state.calendar_summary}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['calendar_summary']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                stateClone[property] = additionalParam[property];
            }
        }
        return stateClone;
    }

    insert = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        var params = this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Calendar/calendarFormInsert.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(params)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.state.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme}>
            <div className={'popupWrapper' + className} id="CalendarInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('CalendarInsert')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.insert.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        <Button className={'btn-sm'} id={'save_new'} onClick={this.insert.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default CalendarInsert;

