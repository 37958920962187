import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';
import EasyTableSummaryHorizontalDate from './../../EasyTable/EasyTableSummaryHorizontalDate';

class SummaryContentHorizontalDate extends Component {

    constructor(props) {
        super(props);
        this.state = {theme: this.props.theme}

        this.tableDescriptor = {
            tableHeader: {
                title: 'Összesítő',
                filterComboData: []
            },
            idColumn: 'id',
            titleVisible: true,
            columnDescriptors: [

            ],
            data: []
        }

        this.dataLoader();

    }

    isDate = function (value) {
        var date = new Date(value);
        return (date !== "Invalid Date" && !isNaN(date));
    }

    isDateBetween = function (columnName) {
        if (columnName.indexOf("_") == -1) {
            return false;
        }
        var first_last_date = columnName.split("_");
        if (first_last_date.length != 2) {
            return false;
        }
        if (this.isDate(first_last_date[0]) == false) {
            return false;
        }
        if (this.isDate(first_last_date[1]) == false) {
            return false;
        }
        
        var firstDate = first_last_date[0];
        var lastDate = first_last_date[1];

        var from = new Date(firstDate);
        var to = new Date(lastDate);
        var currentDate = new Date();

        return (from <= currentDate && currentDate <= to) ? true : false;
    }

    setColumns = function (columnDescriptors) {

        this.tableDescriptor.columnDescriptors = [];


        this.tableDescriptor.columnDescriptors.push({
            order: 0,
            name: 'employe',
            title: 'Nevek',
            renderer: (key, value) => {
                return <div style={{whiteSpace: 'nowrap', textAlign: 'left'}}>{value}</div>
            },
            flex: 3,
            visible: true
        });


        var order = 0;

        for (var i = 0; i < columnDescriptors.length; i++) {

            var columnDescriptor = columnDescriptors[i];
            order = order + 1;
            var columnName = columnDescriptor['name'];
            var title = columnDescriptor['title'];
            var renderer = (row, value, columnName) => {


                var numberStyle = {whiteSpace: 'nowrap', color: '#1880c9'};
                if (value == 0) {
                    numberStyle.color = '#00000044';
                }
                

                var isBetween = this.isDateBetween(columnName);
                
                if(isBetween==true){
                     numberStyle.color = '#FF4422AA';
                     numberStyle.fontWeight = 'bold';
                }



                if (row.is_summary) {

                    numberStyle = {
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: 30,
                        backgroundColor: numberStyle.color,
                        textAlign: 'center',
                        minWidth: 100

                    };
                }


                if (row.is_summary == true) {
                    var bgStyle = '#f8bc34';
                }
                var valStr = (isNaN(value)) ? '' : (new Number(value)).toLocaleString();
                if (valStr != '0') {
                    return <div style={numberStyle}>{valStr}</div>;
                } else {
                    return <div style={numberStyle}>{valStr}</div>;
                }
            };
            var flex = 1;
            var visible = true;

            this.tableDescriptor.columnDescriptors.push({
                order: order,
                name: columnName,
                title: title,
                renderer: renderer,
                flex: flex,
                visible: visible
            });
        }


        this.tableDescriptor.columnDescriptors.push({
            order: this.tableDescriptor.columnDescriptors.length,
            name: 'sum',
            title: 'Összesen',

            renderer: (row, value) => {
                var color = '#68bb69';
                if (row.is_summary == true) {
                    color = '#f65f6e';
                }

                return <div 
                    style={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            borderRadius: 30,
                                            backgroundColor: color,
                                            textAlign: 'center',
                                            minWidth: 100

                                        }}  >
                    {(new Number(value)).toLocaleString()}
                </div>
            },
            flex: 3,
            visible: true
        });


    }

    dataLoader = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Summary/summaryTableDataHorizontalDate.php";
            var self = this;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({from: this.props.from, to: this.props.to})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.tableDescriptor.data = response.data;
                        self.setColumns(response.extra);
                        self.forceUpdate();
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
            this.dataLoader();
        }
    }

    setBack = function (id, value) {
        // Nothing to do here
    }
    render() {

        if (this.props.from == null || this.props.to == null) {
            return "";
        }
        if (this.props.from == '' || this.props.to == '') {
            return "";
        }



        return (<div style={{
                        backgroundColor: 'white',
                        minWidth: '80vw',
                        maxWidth: '80vw',
                        minHeight: '75vh',
                        maxHeight: '75vh',
                        overflow: 'auto',
                        textAlign: 'center'
                         }}>
            <EasyTableSummaryHorizontalDate  tableDescriptor={this.tableDescriptor}/>
        </div>);
    }
}

export default SummaryContentHorizontalDate;
