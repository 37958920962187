import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import * as lang from './../lang.js';
import {Button} from 'react-bootstrap';

import selectedIconBlack from './../img/black/check_circle_black_24dp.svg';

class CellRendererBoolean extends Component {

    render() {
        if (typeof this.props.selected == 'undefined') {
            return <div/>;
        }
        if (this.props.selected == null) {
            return <div/>;
        }
        if (this.props.selected == '') {
            return <div/>;
        }
        if (this.props.selected == 0) {
            return <div/>;
        }

        return <div style={{textAlign: 'center', justifyContent: 'center'}}>
            <img src={{maxHeight: 16}} src={ selectedIconBlack}/>
        </div>;
    }
}

export default CellRendererBoolean;

