import React, { Component } from 'react';
import './../css/light.css';



class CheckBoxInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value == '1') ? '1' : '0'
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: (this.props.value == '1') ? '1' : '0'});
        }
    }

    onChange = function (e) {
        this.setState({value: (e.target.checked) ? '1' : '0'});
        this.props.setBack(e.target.id, (e.target.checked) ? '1' : '0');
    }

    render() {
        var className = (this.props.theme=="true")?"Dark":"Light";
        return  <div>
                <input 
                ref={this.props.reference} 
                onChange={this.onChange.bind(this)} 
                type={'checkbox'} 
                id={this.props.id} 
                checked={(this.state.value=='1')?true:false} 
                readOnly={this.props.editable==false}
                className ={"component"+className}
                />
</div>;
    }
}

export default CheckBoxInsertField;
