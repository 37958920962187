import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import ComboBoxInsertFieldCustomerAddress from './ComboBoxInsertFieldCustomerAddress';
import ComboBoxInsertFieldWorkNumber from './ComboBoxInsertFieldWorkNumber';
import DateInsertField from './../../components/DateInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import deleteIcon from './../../img/black/delete_black_24dp.svg';

class CalendarUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            calendar_id: ''
            , calendar_employe_id: ''
            , calendar_workingDate: ''
            , calendar_workingDay: ''

            , calendar_customerAddress1_id: ''
            , calendar_customerAddress2_id: ''
            , calendar_customerAddress3_id: ''
            , calendar_customerAddress4_id: ''
            , calendar_customerAddress5_id: ''
            , calendar_customerAddress6_id: ''
            , calendar_customerAddress7_id: ''
            , calendar_customerAddress8_id: ''
            , calendar_customerAddress9_id: ''
            , calendar_customerAddress10_id: ''

            , calendar_workNumber1_id: ''
            , calendar_workNumber2_id: ''
            , calendar_workNumber3_id: ''
            , calendar_workNumber4_id: ''
            , calendar_workNumber5_id: ''
            , calendar_workNumber6_id: ''
            , calendar_workNumber7_id: ''
            , calendar_workNumber8_id: ''
            , calendar_workNumber9_id: ''
            , calendar_workNumber10_id: ''

            , calendar_numberOfHours1: ''
            , calendar_numberOfHours2: ''
            , calendar_numberOfHours3: ''
            , calendar_numberOfHours4: ''
            , calendar_numberOfHours5: ''
            , calendar_numberOfHours6: ''
            , calendar_numberOfHours7: ''
            , calendar_numberOfHours8: ''
            , calendar_numberOfHours9: ''
            , calendar_numberOfHours10: ''

            , calendar_otherBenefits: ''
            , calendar_remark: ''
            , calendar_calendarName: ''
            , calendar_salaryDeductionRemark: ''
            , calendar_otherBenefitsRemark: ''
            , calendar_salaryDeduction: ''
            , calendar_summary: ''

        }


        this.references = [];
        this.references['calendar_id'] = React.createRef();
        this.references['calendar_employe_id'] = React.createRef();
        this.references['calendar_workingDate'] = React.createRef();
        this.references['calendar_workingDay'] = React.createRef();

        this.references['calendar_customerAddress1_id'] = React.createRef();
        this.references['calendar_workNumber1_id'] = React.createRef();
        this.references['calendar_numberOfHours1'] = React.createRef();

        this.references['calendar_customerAddress2_id'] = React.createRef();
        this.references['calendar_workNumber2_id'] = React.createRef();
        this.references['calendar_numberOfHours2'] = React.createRef();

        this.references['calendar_customerAddress3_id'] = React.createRef();
        this.references['calendar_workNumber3_id'] = React.createRef();
        this.references['calendar_numberOfHours3'] = React.createRef();

        this.references['calendar_customerAddress4_id'] = React.createRef();
        this.references['calendar_workNumber4_id'] = React.createRef();
        this.references['calendar_numberOfHours4'] = React.createRef();

        this.references['calendar_customerAddress5_id'] = React.createRef();
        this.references['calendar_workNumber5_id'] = React.createRef();
        this.references['calendar_numberOfHours5'] = React.createRef();

        this.references['calendar_customerAddress6_id'] = React.createRef();
        this.references['calendar_workNumber6_id'] = React.createRef();
        this.references['calendar_numberOfHours6'] = React.createRef();

        this.references['calendar_customerAddress7_id'] = React.createRef();
        this.references['calendar_workNumber7_id'] = React.createRef();
        this.references['calendar_numberOfHours7'] = React.createRef();

        this.references['calendar_customerAddress8_id'] = React.createRef();
        this.references['calendar_workNumber8_id'] = React.createRef();
        this.references['calendar_numberOfHours8'] = React.createRef();

        this.references['calendar_customerAddress9_id'] = React.createRef();
        this.references['calendar_workNumber9_id'] = React.createRef();
        this.references['calendar_numberOfHours9'] = React.createRef();

        this.references['calendar_customerAddress10_id'] = React.createRef();
        this.references['calendar_workNumber10_id'] = React.createRef();
        this.references['calendar_numberOfHours10'] = React.createRef();

        this.references['calendar_otherBenefits'] = React.createRef();
        this.references['calendar_remark'] = React.createRef();
        this.references['calendar_calendarName'] = React.createRef();
        this.references['calendar_salaryDeductionRemark'] = React.createRef();
        this.references['calendar_otherBenefitsRemark'] = React.createRef();
        this.references['calendar_salaryDeduction'] = React.createRef();

        this.references['calendar_summary'] = React.createRef();


        this.validators = [];
        this.validators['calendar_id'] = {fieldName: 'calendar_id', dataType: 'INT', value: this.state.calendar_id, mandatory: false, min: null, max: null};
        this.validators['calendar_employe_id'] = {fieldName: 'calendar_employe_id', dataType: 'INT', value: this.state.calendar_employe_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workingDate'] = {fieldName: 'calendar_workingDate', dataType: 'DATE', value: this.state.calendar_workingDate, mandatory: false, min: null, max: null};
        this.validators['calendar_workingDay'] = {fieldName: 'calendar_workingDay', dataType: 'INT', value: this.state.calendar_workingDay, mandatory: false, min: null, max: null};

        this.validators['calendar_customerAddress1_id'] = {fieldName: 'calendar_customerAddress1_id', dataType: 'INT', value: this.state.calendar_customerAddress1_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress2_id'] = {fieldName: 'calendar_customerAddress2_id', dataType: 'INT', value: this.state.calendar_customerAddress2_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress3_id'] = {fieldName: 'calendar_customerAddress3_id', dataType: 'INT', value: this.state.calendar_customerAddress3_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress4_id'] = {fieldName: 'calendar_customerAddress4_id', dataType: 'INT', value: this.state.calendar_customerAddress4_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress5_id'] = {fieldName: 'calendar_customerAddress5_id', dataType: 'INT', value: this.state.calendar_customerAddress5_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress6_id'] = {fieldName: 'calendar_customerAddress6_id', dataType: 'INT', value: this.state.calendar_customerAddress6_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress7_id'] = {fieldName: 'calendar_customerAddress7_id', dataType: 'INT', value: this.state.calendar_customerAddress7_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress8_id'] = {fieldName: 'calendar_customerAddress8_id', dataType: 'INT', value: this.state.calendar_customerAddress8_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress9_id'] = {fieldName: 'calendar_customerAddress9_id', dataType: 'INT', value: this.state.calendar_customerAddress9_id, mandatory: false, min: null, max: null};
        this.validators['calendar_customerAddress10_id'] = {fieldName: 'calendar_customerAddress10_id', dataType: 'INT', value: this.state.calendar_customerAddress10_id, mandatory: false, min: null, max: null};

        this.validators['calendar_workNumber1_id'] = {fieldName: 'calendar_workNumber1_id', dataType: 'INT', value: this.state.calendar_workNumber1_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber2_id'] = {fieldName: 'calendar_workNumber2_id', dataType: 'INT', value: this.state.calendar_workNumber2_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber3_id'] = {fieldName: 'calendar_workNumber3_id', dataType: 'INT', value: this.state.calendar_workNumber3_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber4_id'] = {fieldName: 'calendar_workNumber4_id', dataType: 'INT', value: this.state.calendar_workNumber4_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber5_id'] = {fieldName: 'calendar_workNumber5_id', dataType: 'INT', value: this.state.calendar_workNumber5_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber6_id'] = {fieldName: 'calendar_workNumber6_id', dataType: 'INT', value: this.state.calendar_workNumber6_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber7_id'] = {fieldName: 'calendar_workNumber7_id', dataType: 'INT', value: this.state.calendar_workNumber7_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber8_id'] = {fieldName: 'calendar_workNumber8_id', dataType: 'INT', value: this.state.calendar_workNumber8_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber9_id'] = {fieldName: 'calendar_workNumber9_id', dataType: 'INT', value: this.state.calendar_workNumber9_id, mandatory: false, min: null, max: null};
        this.validators['calendar_workNumber10_id'] = {fieldName: 'calendar_workNumber10_id', dataType: 'INT', value: this.state.calendar_workNumber10_id, mandatory: false, min: null, max: null};

        this.validators['calendar_numberOfHours1'] = {fieldName: 'calendar_numberOfHours1', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours1, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours2'] = {fieldName: 'calendar_numberOfHours2', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours2, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours3'] = {fieldName: 'calendar_numberOfHours3', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours3, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours4'] = {fieldName: 'calendar_numberOfHours4', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours4, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours5'] = {fieldName: 'calendar_numberOfHours5', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours5, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours6'] = {fieldName: 'calendar_numberOfHours6', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours6, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours7'] = {fieldName: 'calendar_numberOfHours7', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours7, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours8'] = {fieldName: 'calendar_numberOfHours8', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours8, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours9'] = {fieldName: 'calendar_numberOfHours9', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours9, mandatory: false, min: 0, max: 24};
        this.validators['calendar_numberOfHours10'] = {fieldName: 'calendar_numberOfHours10', dataType: 'DOUBLE', value: this.state.calendar_numberOfHours10, mandatory: false, min: 0, max: 24};



        this.validators['calendar_otherBenefits'] = {fieldName: 'calendar_otherBenefits', dataType: 'DOUBLE', value: this.state.calendar_otherBenefits, mandatory: false, min: null, max: null};
        this.validators['calendar_remark'] = {fieldName: 'calendar_remark', dataType: 'VARCHAR', value: this.state.calendar_remark, mandatory: false, min: null, max: null};
        this.validators['calendar_calendarName'] = {fieldName: 'calendar_calendarName', dataType: 'VARCHAR', value: this.state.calendar_calendarName, mandatory: false, min: null, max: null};
        this.validators['calendar_salaryDeductionRemark'] = {fieldName: 'calendar_salaryDeductionRemark', dataType: 'VARCHAR', value: this.state.calendar_salaryDeductionRemark, mandatory: false, min: null, max: null};
        this.validators['calendar_otherBenefitsRemark'] = {fieldName: 'calendar_otherBenefitsRemark', dataType: 'VARCHAR', value: this.state.calendar_otherBenefitsRemark, mandatory: false, min: null, max: null};
        this.validators['calendar_salaryDeduction'] = {fieldName: 'calendar_salaryDeduction', dataType: 'DOUBLE', value: this.state.calendar_salaryDeduction, mandatory: false, min: null, max: null};
        this.validators['calendar_summary'] = {fieldName: 'calendar_summary', dataType: 'DOUBLE', value: this.state.calendar_summary, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidMount() {
        this.setFocusedComponent();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {

        this.setState({calendar_id: ''
            , calendar_employe_id: ''
            , calendar_workingDate: ''
            , calendar_workingDay: ''

            , calendar_customerAddress1_id: ''
            , calendar_customerAddress2_id: ''
            , calendar_customerAddress3_id: ''
            , calendar_customerAddress4_id: ''
            , calendar_customerAddress5_id: ''
            , calendar_customerAddress6_id: ''
            , calendar_customerAddress7_id: ''
            , calendar_customerAddress8_id: ''
            , calendar_customerAddress9_id: ''
            , calendar_customerAddress10_id: ''

            , calendar_workNumber1_id: ''
            , calendar_workNumber2_id: ''
            , calendar_workNumber3_id: ''
            , calendar_workNumber4_id: ''
            , calendar_workNumber5_id: ''
            , calendar_workNumber6_id: ''
            , calendar_workNumber7_id: ''
            , calendar_workNumber8_id: ''
            , calendar_workNumber9_id: ''
            , calendar_workNumber10_id: ''

            , calendar_numberOfHours1: ''
            , calendar_numberOfHours2: ''
            , calendar_numberOfHours3: ''
            , calendar_numberOfHours4: ''
            , calendar_numberOfHours5: ''
            , calendar_numberOfHours6: ''
            , calendar_numberOfHours7: ''
            , calendar_numberOfHours8: ''
            , calendar_numberOfHours9: ''
            , calendar_numberOfHours10: ''


            , calendar_otherBenefits: ''
            , calendar_remark: ''
            , calendar_calendarName: ''
            , calendar_salaryDeductionRemark: ''
            , calendar_otherBenefitsRemark: ''
            , calendar_salaryDeduction: ''
            , calendar_summary: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getCustomerAddressByWorkNumber = function (blockId) {

        return <tr>
            <td>{blockId}</td>
            <td>
        <ComboBoxInsertFieldWorkNumber 
            id={'calendar_workNumber' + blockId + '_id'} 
            calendar_customerAddress_id={this.state['calendar_customerAddress' + blockId + '_id']}
            value={this.state['calendar_workNumber' + blockId + '_id']}  
            setBack={this.setBack.bind(this)}
            dataSourceUrl={'modules/Calendar/workNumberByCustomerAddress_idData.php'}
            reference={this.references['calendar_workNumber' + blockId + '_id']}
            editable={true}
            theme={this.props.theme}
            style={{minWidth:'100%'}}
            />
        </td>
        <td>
        <ComboBoxInsertFieldCustomerAddress 
            id={'calendar_customerAddress' + blockId + '_id'} 
            calendar_workNumber1_id={this.state['calendar_workNumber' + blockId + '_id']}
            value={this.state['calendar_customerAddress' + blockId + '_id']}  
            setBack={this.setBack.bind(this)}
            dataSourceUrl={'modules/Calendar/customerAddressByWorkNumber_idComboData.php'}
            reference={this.references['calendar_customerAddress' + blockId + '_id']}
            editable={true}
            theme={this.props.theme}
            style={{minWidth:'100%'}}
            />
        </td>  
        <td>
        <NumberInsertField 
            id={'calendar_numberOfHours' + blockId} 
            value={this.state['calendar_numberOfHours' + blockId]}  
            setBack={this.setBack.bind(this)}
            reference={this.references['calendar_numberOfHours' + blockId]}
            editable={true}
            theme={false}
            style={{maxWidth: 60}}
            />
        </td>
        <td>      
            <img 
                src={deleteIcon}
                title={'Törlés'}
                style={{maxHeight: 20, cursor: 'pointer'}}
                onClick={() => {
                                this.deleteBlock(blockId)
                            }} 
                />
        </td>      
        </tr>

    }

    resolveValue = function (val) {

        if (typeof val == 'undefined') {
            return 0.0;
        }

        if (val == null) {
            return 0.0;
        }

        if (val == '') {
            return 0.0;
        }

        if (Number.isNaN(val)) {
            return 0.0;
        }

        return val * 1.0;
    }

    getSumRow = function () {
        var sum = 0.0;
        sum += this.resolveValue(this.state.calendar_numberOfHours1);
        sum += this.resolveValue(this.state.calendar_numberOfHours2);
        sum += this.resolveValue(this.state.calendar_numberOfHours3);
        sum += this.resolveValue(this.state.calendar_numberOfHours4);
        sum += this.resolveValue(this.state.calendar_numberOfHours5);
        sum += this.resolveValue(this.state.calendar_numberOfHours6);
        sum += this.resolveValue(this.state.calendar_numberOfHours7);
        sum += this.resolveValue(this.state.calendar_numberOfHours8);
        sum += this.resolveValue(this.state.calendar_numberOfHours9);
        sum += this.resolveValue(this.state.calendar_numberOfHours10);

        var formated = (new Number(sum)).toLocaleString();

        return <tr>
            <td>
            </td>
            <td>
            </td>
            <td>
                Összesen:
            </td>  
            <td>
                {formated}
            </td>
            <td>      
            </td>      
        </tr>

    }

    getForm = function () {
        return <Container>
            <Row>
                <Col md={5}>
                <Row>
                    <Col style={{textAlign: 'left', minHeight: 7}}></Col>
                </Row>
        
        
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_employe_id')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <ComboBoxInsertField 
                        id={'calendar_employe_id'} 
                        value={this.state.calendar_employe_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Calendar/employe_idFormComboInsertData.php'}
                        reference={this.references['calendar_employe_id']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_workingDate')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <DateInsertField 
                        id={'calendar_workingDate'} 
                        value={this.state.calendar_workingDate}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_workingDate']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_otherBenefits')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'calendar_otherBenefits'} 
                        value={this.state.calendar_otherBenefits}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_otherBenefits']}
                        editable={true}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <TextAreaInsertField 
                        id={'calendar_otherBenefitsRemark'} 
                        value={this.state.calendar_otherBenefitsRemark}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_otherBenefitsRemark']}
                        editable={true}   
                        theme={this.props.theme}
                        style={{minWidth: '100%'}}
                        placeHolder={'Egyéb költség megnevezés'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_salaryDeduction')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <NumberInsertField 
                        id={'calendar_salaryDeduction'} 
                        value={this.state.calendar_salaryDeduction}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_salaryDeduction']}
                        editable={true}
        
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'right'}}>
                    <TextAreaInsertField 
                        id={'calendar_salaryDeductionRemark'} 
                        value={this.state.calendar_salaryDeductionRemark}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_salaryDeductionRemark']}
                        editable={true}   
                        theme={this.props.theme}
                        style={{minWidth: '100%'}}
                        placeHolder="Levonás megnevezés"
                        />
        
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitle('calendar_remark')}</Col>
                    <Col style={{textAlign: 'right'}}>
                    <TextAreaInsertField 
                        id={'calendar_remark'} 
                        value={this.state.calendar_remark}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['calendar_remark']}
                        editable={true}   
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
        
                </Col>
                <Col md={7}>
                <table style={{minWidth:'100%'}}>
                    <thead>
                        <tr>
                            <th>#</th><th>Munkaszám</th><th>Cím</th><th>Óra</th><th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getCustomerAddressByWorkNumber('1')}
                        {this.getCustomerAddressByWorkNumber('2')}
                        {this.getCustomerAddressByWorkNumber('3')}
                        {this.getCustomerAddressByWorkNumber('4')}
                        {this.getCustomerAddressByWorkNumber('5')}
                        {this.getCustomerAddressByWorkNumber('6')}
                        {this.getCustomerAddressByWorkNumber('7')}
                        {this.getCustomerAddressByWorkNumber('8')}
                        {this.getCustomerAddressByWorkNumber('9')}
                        {this.getCustomerAddressByWorkNumber('10')}
                        {this.getSumRow()}
                    </tbody>
                </table>
                </Col>
            </Row>
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitle('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitle(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var method = e.target.id;
        var self = this;

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Calendar/calendarFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(this.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            if (method == 'save') {
                                self.closeForm();
                            }
                            if (method == 'save_new') {
                                self.reset();
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    deleteBlock = function (blockId) {
        var addr = 'calendar_customerAddress' + blockId + '_id';
        var workNum = 'calendar_workNumber' + blockId + '_id';
        var numberOfHours = 'calendar_numberOfHours' + blockId;
        this.setState({[addr]: '', [workNum]: '', [numberOfHours]: ''});
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Calendar/calendarFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} style={{minWidth:1400}}>
            <div className={'popupWrapper' + className} id="CalendarUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('CalendarUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} style={{overflowY: 'unset'}} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button className={'btn-sm'} id={'save'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
        
                        </Col>
                    </Row>
        
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default CalendarUpdate;

