import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';
import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';


class MTOEmployeAddressInsertMTO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            employeAddress_employe_id: ''
            , employeAddress_city_id: ''
            , employeAddress_publicSpaceName: ''
            , employeAddress_publicSpaceType_id: ''
            , employeAddress_floor: ''
            , employeAddress_houseNumber: ''

        }

        this.references = [];
        this.references['employeAddress_employe_id'] = React.createRef();
        this.references['employeAddress_city_id'] = React.createRef();
        this.references['employeAddress_publicSpaceName'] = React.createRef();
        this.references['employeAddress_publicSpaceType_id'] = React.createRef();
        this.references['employeAddress_floor'] = React.createRef();
        this.references['employeAddress_houseNumber'] = React.createRef();

    }
    componentDidMount() {
        this.reset();
    }

    setFocusedComponent = function () {
        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    reset = function () {
        this.setFocusedComponent();
        this.setState({employeAddress_employe_id: ''
            , employeAddress_city_id: ''
            , employeAddress_publicSpaceName: ''
            , employeAddress_publicSpaceType_id: ''
            , employeAddress_floor: ''
            , employeAddress_houseNumber: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});

    }

    getForm = function () {
        return <Container  theme={this.props.theme}>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_city_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'employeAddress_city_id'} 
                    value={this.state.employeAddress_city_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOEmployeAddress/city_idFormComboInsertData.php'}
                    reference={this.references['employeAddress_city_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_publicSpaceName')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'employeAddress_publicSpaceName'} 
                    value={this.state.employeAddress_publicSpaceName}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['employeAddress_publicSpaceName']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_publicSpaceType_id')}</Col>
                <Col style={{textAlign: 'right'}}>
                <ComboBoxInsertField 
                    id={'employeAddress_publicSpaceType_id'} 
                    value={this.state.employeAddress_publicSpaceType_id}  
                    setBack={this.setBack.bind(this)}
                    dataSourceUrl={'modules/MTOEmployeAddress/publicSpaceType_idFormComboInsertData.php'}
                    reference={this.references['employeAddress_publicSpaceType_id']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_floor')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'employeAddress_floor'} 
                    value={this.state.employeAddress_floor}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['employeAddress_floor']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: 'left'}}>{lang.getTitle('employeAddress_houseNumber')}</Col>
                <Col style={{textAlign: 'right'}}>
                <TextInsertField 
                    id={'employeAddress_houseNumber'} 
                    value={this.state.employeAddress_houseNumber}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['employeAddress_houseNumber']}
                    editable={true}
                    theme={this.props.theme}
                    />
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();
    }

    addRow = function () {
        this.props.addRow(this.state, false);
        this.reset();
    }

    addRowAndClose = function () {
        this.props.addRow(this.state, true);
        this.reset();
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<PopUpWindow theme={this.props.theme} >
            <div className={'popupWrapper' + className} id="MTOEmployeAddressInsert">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col></Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitle('MTOEmployeAddressInsert')}</Col>
                        <Col style={{textAlign: 'right',marginRight:20}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <Button className={'btn-sm'} onClick={this.addRowAndClose.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('save')}</Button>&nbsp;&nbsp;&nbsp;      
                    <Button className={'btn-sm'} onClick={this.addRow.bind(this)} variant="info" style={{marginTop: '5px'}}>{lang.getTitle('new')}</Button>      
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default MTOEmployeAddressInsertMTO;


