import React, { Component } from 'react';
import * as lang from './../../lang.js';
import './../../css/easyTable.css';
class DefaultHeader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="defaultHeader"></div>
    }
}

export default DefaultHeader;

