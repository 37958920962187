import React, { Component }from 'react';
import TreeNode from './TreeNode';
import {Row, Col} from 'react-bootstrap';

class Tree extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    
    render() {
      
        return (
                <div  style={{maxHeight:'400px',overflow:'auto',paddingLeft:'10px',paddingTop:'10px',paddingRight:'10px',minWidth:'300px'}}>
                    <TreeNode
                        onNodeSelect={(e,action)=>{this.props.onNodeSelect(e,action);}} 
                        nodes={this.props.nodes} 
                        openedTreeNodes = {this.props.openedTreeNodes}    
                        parentId={null} 
                        treeActiveNodeId ={this.props.treeActiveNodeId}        
                    />
                </div>);
    }
}
export default Tree;