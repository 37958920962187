import React, { Component } from 'react';
import './../css/light.css';
class NumberInsertField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            text: this.props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({text: this.props.value});
        }
    }

    onChange = function (e) {

        var number = this.textToNumber(e.target.value);
        var text = e.target.value;
        this.setState({value: number, text: text});
        if (!isNaN(number)) {
            this.props.setBack(e.target.id, number);
        }
    }

    textToNumber(text) {

        if (typeof text == 'undefined') {
            return 0;
        }
        if (text == null) {
            return 0;
        }
        if (text == '') {
            return 0;
        }
        if (text == '-') {
            return 0;
        }


        text = text.split(' ').join("");
        text = text.split('.').join("");
        text = text.split(',').join(".");

        if (text.indexOf('-') > 0) {
            text = "-";
        }

        if (text.indexOf(-1) == '.') {
            text = text + "0";
        }

        return text * 1.0;
    }

    addThousandSeparator = function (szam, karakter) {
        // Ellenőrizd, hogy a szám érvényes-e
        if (isNaN(szam)) {
            return szam;
        }

        // Átalakítjuk a számot szöveggé
        var szamSzoveg = (typeof szam !='undefined' && szam != null )? szam.toString():'';

        // Szétválasztjuk az egész és tizedes részt
        var szamok = szamSzoveg.split(".");
        var egeszResz = szamok[0];
        var tizedesResz = szamok[1] || "";

        // Meghatározzuk az egész rész hosszát
        var egeszHossz = egeszResz.length;

        // Beszúrjuk a karaktert az egész részbe három számjegyenként
        for (var i = egeszHossz - 3; i > 0; i -= 3) {
            egeszResz = egeszResz.slice(0, i) + karakter + egeszResz.slice(i);
        }

        // Beszúrjuk a karaktert a tizedes részbe három számjegyenként
        var tizedesHossz = tizedesResz.length;
        var ujTizedesResz = "";
        for (var j = 0; j < tizedesHossz; j += 3) {
            ujTizedesResz += tizedesResz.slice(j, j + 3) + karakter;
        }
        ujTizedesResz = ujTizedesResz.slice(0, -1); // Az utolsó karakter (karakter) eltávolítása

        // Összeállítjuk az eredményt
        var eredmeny = egeszResz;
        if (ujTizedesResz !== "") {
            eredmeny += "," + ujTizedesResz;
        }
        eredmeny = eredmeny.replace('-.','-');    
        return eredmeny;
    }

    render() {
        this.state.text = this.addThousandSeparator(this.state.text,'.');
        if (typeof this.state.text == 'string' && this.state.text.indexOf('-') > 0) {
            this.state.text = '-';
        }
        var style = {textAlign: 'right'};
        if (typeof this.props.style != "undefined" && this.props.style != null) {
            style = this.props.style;
        }

        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return  <div><input 
                onChange={this.onChange.bind(this)} 
                style={style}
                type={'text'} 
                id={this.props.id} 
                value={this.state.text} 
                ref={this.props.reference}
                readOnly={this.props.editable == false}
                onFocus={(e) => this.props.reference.current.select()}
                onClick={(e) => this.props.reference.current.select()}
                className ={"component" + className}
                /></div>;
    }
}

export default NumberInsertField;
