import React, { Component }from 'react';
import * as utils from './../ajax.js';
import deleteIconBlack from './../img/black/delete_black_24dp.svg';
import deleteIconWhite from './../img/white/delete_white_24dp.svg';

import addIconBlack from './../img/black/note_add_black_24dp.svg';
import addIconWhite from './../img/white/note_add_white_24dp.svg';

import folderIconBlack from './../img/black/snippet_folder_black_24dp.svg';
import folderIconWhite from './../img/white/snippet_folder_white_24dp.svg';

import folderEmptyIconBlack from './../img/black/folder_black_24dp.svg';
import folderEmptyIconWhite from './../img/white/folder_white_24dp.svg';
import './../css/driveTree.css';



class TreeNode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            parentId: null,
            nodes: null,
            open: false,
            openedTreeNodes: []
        }
        this.state.id = this.props.id;
        this.state.parentId = this.props.parentId;
        
    }

    componentDidMount = function () {
        
        this.setState({nodes: this.props.nodes});
    }

    nodeEquals = function (obj1, obj2) {

        var idEquals = obj1.id == obj2.id;
        var parentEquals = obj1.parentId == obj2.parentId;
        var titleEquals = obj1.title = obj2.title;
        var nodeEquals = idEquals && parentEquals && titleEquals;
        return nodeEquals;
    }

    componentDidUpdate(prevProps) {

        if (prevProps.nodes.length !== this.props.nodes.length) {
            this.setState({nodes: this.props.nodes});
        } else {
            var updateNeed = false;
            for (var i = 0; i < prevProps.nodes.length; i++) {
                var nodeEquals = this.nodeEquals(prevProps.nodes[i], this.props.nodes[i]);
                if (nodeEquals == false) {
                    updateNeed = true;
                }
            }
            if (updateNeed) {
                this.setState({nodes: this.props.nodes});
            }


        }
    }

    openClose = function (e) {
        var changed = parseInt(e.target.id);
        var removed = false;
        var index = this.props.openedTreeNodes.indexOf(changed);
        if (index > -1) {
            this.props.openedTreeNodes.splice(index, 1);
            removed = true;
        }

        if (!removed) {
            this.props.openedTreeNodes.push(changed);
        }
        this.setState({});
    }

    onNodeSelect = function (e, action) {
        if (typeof e.target !== 'undefined' && typeof e.target.id !== 'undefined' && e.target.id !== null) {
            var idParts = e.target.id.split('_');
            var id = parseInt(idParts[1]);
            for (var i = 0; i < this.state.nodes.length; i++) {
                if (this.state.nodes[i].id === id) {
                    this.props.onNodeSelect(this.state.nodes[i], action);
                }
            }

        }
    }

    allowDrop = function (ev) {
        ev.preventDefault();
    }

    onDragStart = function (ev) {
        var sourcePrefixAndId = ev.target.id.split('_');
        var sourcePrefix = sourcePrefixAndId[0];
        var sourceId = sourcePrefixAndId[1];
        ev.dataTransfer.setData(sourcePrefix, sourceId);
    }

    setNodePosition = function (sourceId, targetId) {

    }

    onDrop = function (ev) {

        ev.preventDefault();
        var treeNodeSource = typeof ev.dataTransfer.getData('tree') !== 'undefined';
        treeNodeSource = treeNodeSource && ev.dataTransfer.getData('tree') !== null;
        treeNodeSource = treeNodeSource && ev.dataTransfer.getData('tree') !== '';
        var tableSource = typeof ev.dataTransfer.getData('table') !== 'undefined';
        tableSource = tableSource && ev.dataTransfer.getData('table') !== null;
        tableSource = tableSource && ev.dataTransfer.getData('table') !== '';
        if (treeNodeSource) {
            var sourceId = ev.dataTransfer.getData('tree');
            var targetId = this.removePrefix(ev.target.id);
            this.setNodePosition(sourceId, targetId);
        }

        if (tableSource) {
            var sourceId = ev.dataTransfer.getData('table');
            var targetId = this.removePrefix(ev.target.id);
        }
    }

    removePrefix = function (valueWithPrefix) {
        if (typeof valueWithPrefix === 'undefined' || valueWithPrefix === null || typeof valueWithPrefix !== 'string') {
            return null;
        }
        var prefixAndId = valueWithPrefix.split('_');
        if (prefixAndId.length !== 2) {
            return null;
        }
        return prefixAndId[1];
    }

    getChildrensCount = function (id) {
        if (this.state.nodes == null) {
            return 0;
        }
        if (this.state.nodes.length == 0) {
            return 0;
        }

        var count = 0;
        for (var i = 0; i < this.state.nodes.length; i++) {
            var node = this.state.nodes[i];
            if (node.parentId == id) {
                count++;
            }
        }

        return count;
    }

    getNodes = function (currentId) {

        var nodes = [];
        var deleteIcon = (this.props.theme == "true") ? deleteIconWhite : deleteIconBlack;
        var addIcon = (this.props.theme == "true") ? addIconWhite : addIconBlack;
        var folderIcon = (this.props.theme == "true") ? folderIconWhite : folderIconBlack;
        var folderEmptyIcon = (this.props.theme == "true") ? folderEmptyIconWhite : folderEmptyIconBlack;
        if (this.state.nodes != null) {
            for (var i = 0; i < this.state.nodes.length; i++) {
                var openChildNodes = this.props.openedTreeNodes.indexOf(this.state.nodes[i].id) > -1;
                var childrensCount = this.getChildrensCount(this.state.nodes[i].id);
                var isNode = childrensCount === 0;
                var prefix = " ";
                var childrenCount = "";
                if (!isNode) {
                    childrenCount = "(" + childrensCount + ")";
                    if (openChildNodes) {
                        prefix = <img id={this.state.nodes[i].id}  onClick={this.openClose.bind(this)}  src={folderIcon}/>;
                    } else {
                        prefix = <img id={this.state.nodes[i].id}   onClick={this.openClose.bind(this)}  src={folderIcon}/>;
                    }
                } else {
                    prefix = <img id={this.state.nodes[i].id}   onClick={this.openClose.bind(this)}  src={folderEmptyIcon}/>;
                }

                if (this.state.nodes[i].parentId === currentId) {

                    var activeNode = false;
                    if (this.props.treeActiveNodeId) {
                        if (this.state.nodes[i].id) {
                            if (this.props.treeActiveNodeId === this.state.nodes[i].id) {
                                activeNode = true;
                            }
                        }
                    }

                    var activeNodeStyle = {whiteSpace: 'nowrap'};
                    if (activeNode) {
                        activeNodeStyle = {whiteSpace: 'nowrap', color: 'black', cursor: 'pointer', fontWeight: 'bold'};
                    }

                    var deleteButton = "";
                    
                    if (this.state.nodes[i].parentId != null) {
                        deleteButton = <img 
                            id={'selected_' + this.state.nodes[i].id}  
                            onClick={(e) => {this.onNodeSelect(e, 'DELETE');}} 
                            src={deleteIcon} 
                            />
                    }
                   
                    nodes.push(
                            <div 
                                draggable="true" 
                                id={'tree_' + this.state.nodes[i].id}  
                                key={'tree_key_' + this.state.nodes[i].id}  
                                style={{paddingLeft: '35px', cursor: 'pointer'}}
                                onDrop={this.onDrop.bind(this)}
                                onDragOver={this.allowDrop.bind(this)}
                                onDragStart={this.onDragStart.bind(this)}
                                
                                >
                                <div   style={{display: 'flex', justifyContent: 'middle', alignItems: 'center'}}>
                                    <div 
                                        id={this.state.nodes[i].id}  
                                        style={{display: 'flex', width: '30px', cursor: 'pointer', fontSize: 25, textAlign: 'left', justifyContent: 'middle'}}
                                        >
                                        {prefix}
                                    </div>
                                    <a  onClick={(e) => {
                                            this.onNodeSelect(e, 'SELECT');
                                        }}
                                        id={'selected_' + this.state.nodes[i].id}  
                                        style={activeNodeStyle}>
                                        {this.state.nodes[i].title}
                                    </a>
                                    <div>
                                        {deleteButton}
                                    </div>
                            
                                </div>
                                {(openChildNodes) ?
                                                            <TreeNode
                                                                onNodeSelect={this.props.onNodeSelect.bind(this)}
                                                                nodes={this.state.nodes}  
                                                                parentId={this.state.nodes[i].id} 
                                                                openedTreeNodes={this.props.openedTreeNodes}
                                                                treeActiveNodeId={this.props.treeActiveNodeId}
                                                                theme={this.props.theme}
                                                                /> : ""}
                            </div>
                            );
                }
            }
        }
        return nodes;
    }

    render() {

        if (this.props.treeUpdateNeed === true) {
            this.props.treeUpdateNeed = false;
            this.props.parentId = null;
            this.setState({nodes: null});
        }

        var nodes = this.getNodes(this.state.parentId);
        return ((nodes != null && nodes.length !== 0) ? <div className="treeFont">{nodes}</div> : "");
    }
}
export default TreeNode;