import React, { Component } from 'react';
import MTOEmployePhoneTable from './MTOEmployePhoneTable.js';
import MTOEmployePhoneInsertForm from './MTOEmployePhoneInsertForm.js';
import MTOEmployePhoneUpdateForm from './MTOEmployePhoneUpdateForm.js';
import MTOEmployePhoneViewForm from './MTOEmployePhoneViewForm.js';
import {Button} from 'react-bootstrap';
import * as lang from './../../lang.js';
import * as utils from './../../ajax.js';

class MTOEmployePhoneContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: (typeof this.props.value != 'undefined' && this.props.value != null) ? this.props.value : [],
            theme: this.props.theme
        }

    }
    
    shouldComponentUpdate(nextProps,nextState){
        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    render() {
        var emptyFunction = () => {
        };
        var setBack = (typeof this.props.setBack != 'undefined') ? this.props.setBack.bind(this) : emptyFunction;
        
        var table = <MTOEmployePhoneTable 
                      value={this.state.value} 
                      setBack={setBack} 
                      additionalParams={(typeof this.props.additionalParams == 'undefined')?[]:this.props.additionalParams}
                      formType={(typeof this.props.formType == 'undefined')?null:this.props.formType}
                      theme={this.props.theme}
                    />;
        return (
                <div id="MTOEmployePhoneContent">
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOEmployePhone")}</div> 
                    <div className={"text-center"}><h6>{lang.getTitle("MTOEmployePhone")}</h6></div> 
                    <div className={"dropdown-divider"}>{lang.getTitle("MTOEmployePhone")}</div> 
                
                    {table}
                </div>
                );
    }
}

export default MTOEmployePhoneContent;

